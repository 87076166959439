import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import { InMemoryCache } from "@apollo/client";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import ErrorBoundary from "./ErrorBoundary";
import Fonts from "./Fonts";
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./history";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import ReactGA from "react-ga";

if (process.env.NODE_ENV === "production") {
  ReactGA.initialize("UA-120659397-3");
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV,
  });
}

// export const cache: InMemoryCache = new InMemoryCache();

const theme = extendTheme({
  components: {
    Radio: {
      parts: ["label"],
      baseStyle: {
        label: {
          textAlign: "left",
        },
      },
    },
  },
  fonts: {
    heading: "tt_norms",
    body: "tt_norms",
  },
});

const onRedirectCallback = (appState: any) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Fonts />
    <Auth0Provider
      audience="PermissionsAPI"
      cacheLocation="localstorage"
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
      domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      onRedirectCallback={onRedirectCallback}
      redirectUri={window.location.origin}
      scope={`
        openid
        read:borrower
        read:current_user
        read:current_user_metadata
        update:current_user_metadata
        write:borrower`}
      useRefreshTokens={true}
    >
      <ChakraProvider theme={theme}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </ChakraProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
