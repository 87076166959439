import * as Yup from "yup";
import {
  booleanToYesNoString,
  floatToDollarCentsString,
  floatToInterestRateString,
  floatToThousandsString,
  transformInterestRateStringToNumber,
  transformStringToNumber,
  transformYesNoToBoolean,
} from "../../utility";
import { defaultRequiredErrorMessage } from "../../utility/constants";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  annualIncome: {
    annualIncome: {
      defaultValue: "",
      name: "annualIncome",
      serverTransform: floatToDollarCentsString,
      validation: Yup.number()
        .transform(transformStringToNumber)
        .min(0, "Minimum acceptable home value is $0")
        /* .max(999999, "Maximum acceptable income is $999,999") */
        .required(defaultRequiredErrorMessage),
    },
  },
  creditCurrentCategory: {
    creditCurrentCategory: {
      defaultValue: "exceptional",
      name: "creditCurrentCategory",
      validation: Yup.string().trim().required(defaultRequiredErrorMessage),
    },
  },
  currentRate: {
    currentRate: {
      defaultValue: "",
      name: "currentRate",
      serverTransform: floatToInterestRateString,
      validation: Yup.number()
        .transform(transformInterestRateStringToNumber)
        .required(defaultRequiredErrorMessage),
    },
  },
  estimatedLoanDate: {
    estimatedLoanDateMonth: {
      defaultValue: "",
      name: "estimatedLoanDateMonth",
      placeholder: "Month",
      validation: Yup.string().trim().required(defaultRequiredErrorMessage),
    },
    estimatedLoanDateYear: {
      defaultValue: "",
      name: "estimatedLoanDateYear",
      placeholder: "Year",
      validation: Yup.string().trim().required(defaultRequiredErrorMessage),
    },
  },
  estimatedHomeValue: {
    estimatedHomeValue: {
      defaultValue: "",
      label: "Estimated home value",
      name: "estimatedHomeValue",
      serverTransform: floatToThousandsString,
      validation: Yup.number()
        .transform(transformStringToNumber)
        .min(25000, "Minimum allowable home value is $25,000")
        .max(3000000, "Maximum allowable home value is $3,000,000")
        .required(defaultRequiredErrorMessage),
    },
  },
  financialHelp: {
    financialBestRate: {
      defaultValue: false,
      label: "Get the best rate for my mortgage loan",
      name: "financialBestRate",
    },
    financialDownPayment: {
      defaultValue: false,
      label: "Save for a down payment",
      name: "financialDownPayment",
    },
    financialImproveCredit: {
      defaultValue: false,
      label: "Improve my credit score",
      name: "financialImproveCredit",
    },
    financialReduceDebt: {
      defaultValue: false,
      label: "Reduce my monthly debt",
      name: "financialReduceDebt",
    },
    financialResolveBankrupt: {
      defaultValue: false,
      label: "Resolve a bankruptcy or negative credit event",
      name: "financialResolveBankrupt",
    },
    financialTalk: {
      defaultValue: false,
      label: "Talk to someone about my mortgage options",
      name: "financialTalk",
    },
  },
  mortgageInsurance: {
    mortgageInsurance: {
      defaultValue: true,
      name: "mortgageInsurance",
      serverTransform: booleanToYesNoString,
      validation: Yup.boolean()
        .transform(transformYesNoToBoolean)
        .required(defaultRequiredErrorMessage),
    },
  },
  occupancyType: {
    occupancyType: {
      defaultValue: "residence",
      label: "Property use",
      name: "occupancyType",
      validation: Yup.string().trim().required(defaultRequiredErrorMessage),
    },
  },
  property: {
    address2: {
      defaultValue: "",
      name: "address2",
      placeholder: "Address 2 (optional)",
      validation: Yup.string().trim().max(100),
    },
    property: {
      defaultValue: "",
      name: "property",
      placeholder: "Enter address, city, state, zip code",
      validation: Yup.array()
        .of(
          Yup.object().shape({
            bbox: Yup.array().of(Yup.number()),
            center: Yup.array().of(Yup.number()),
            context: Yup.array().of(
              Yup.object().shape({
                text: Yup.string(),
                value: Yup.string(),
              })
            ),
            label: Yup.string(),
            placeType: Yup.string(),
            text: Yup.string(),
            value: Yup.string(),
          })
        )
        .required(),
    },
  },
  propertyType: {
    propertyType: {
      defaultValue: "singleFamilyResidence",
      name: "propertyType",
      validation: Yup.string().trim().required(defaultRequiredErrorMessage),
    },
  },
  refinanceAmount: {
    refinanceAmount: {
      defaultValue: "",
      name: "refinanceAmount",
      serverTransform: floatToDollarCentsString,
      validation: Yup.number()
        .transform(transformStringToNumber)
        .min(10000, "Minimum acceptable refinance amount is $10,000")
        .max(3000000, "Maximum acceptable refinance amount is $3,000,000")
        .required(defaultRequiredErrorMessage),
    },
  },
};
