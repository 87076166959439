import {
  useDisclosure,
  Box,
  HStack,
  Button,
  Collapse,
  Text,
} from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import CalendlyComponent from "../components/CalendlyComponent";
import StreamlineIcon from "../components/StreamlineIcon";
import { useGeneralStore } from "../generalStore";
import { getOneUser_getOneUser_saver_chat } from "../types/getOneUser";

const ChatDetails = ({
  chat,
  onClose,
}: {
  chat: getOneUser_getOneUser_saver_chat;
  onClose: () => void;
}) => {
  const currentUser = useGeneralStore((state) => state.currentUser);
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box>
      {/* {currentUser?.saver?.chat.map((oldChat, i) => {
        const isChatBeforeToday = moment(chat.startTime)
          .utc()
          .isBefore(moment(), "day");

        if (isChatBeforeToday) return null;
 */}

      <Box mt="1rem">
        <Box borderRadius="md" bgColor="teal.50" p="1rem 1.5rem">
          <HStack align="top">
            <StreamlineIcon
              name={"calendar-check-icon-light"}
              boxSize="2.5rem"
              mr="1rem"
              color="teal.500"
              display={{ base: "none", lg: "inline-block" }}
            />
            <Box>
              <Text fontWeight="600">You have a chat scheduled</Text>
              <Text fontWeight="600">
                {moment(chat.startTime).format("dddd, MMMM D [at] h:mma")}
              </Text>
              <Box mt="0.5rem">
                <Button
                  variant="outline"
                  colorScheme="teal"
                  mr="0.5rem"
                  as="a"
                  href={chat.location.join_url}
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(chat.location.join_url, "_blank");
                  }}
                >
                  Join video call
                </Button>
                <Button
                  variant="outline"
                  colorScheme="teal"
                  onClick={onToggle}
                  mr="0.5rem"
                >
                  Join with phone
                </Button>
                <Collapse in={isOpen} animateOpacity>
                  <Box
                    p="1rem"
                    color="white"
                    mt="4"
                    bg="teal.500"
                    rounded="md"
                    shadow="md"
                  >
                    <Text textDecoration="underline" fontWeight="bold">
                      Phone number
                    </Text>
                    <Text>(312)248-0234</Text>

                    {/* {chat?.location?.data?.settings?.global_dial_in_numbers &&
                      chat?.location?.data?.settings?.global_dial_in_numbers.map(
                        ({ city, number }, i) => {
                          return (
                            <Text key={i}>
                              {city} {number}
                            </Text>
                          );
                        }
                      )} */}
                  </Box>
                </Collapse>
              </Box>
              <Box mt="1rem">
                <Text as="span">Need to reschedule?</Text>&nbsp;
                <CalendlyComponent
                  componentText="Reschedule Chat"
                  // onClick={onClose}
                  displayComponent={
                    <Button
                      variant="link"
                      fontWeight="400"
                      colorScheme="teal"
                    />
                  }
                  url={chat.rescheduleUrl}
                />
                {/* <Button
                  variant="link"
                  fontWeight="400"
                  colorScheme="teal"
                  onClick={() => {
                    onClose();
                    openPopupWidget({
                      url: chat.rescheduleUrl,
                      prefill,
                      pageSettings: undefined,
                      utm: undefined,
                    });
                  }}
                >
                  Reschedule Chat
                </Button> */}
              </Box>
            </Box>
          </HStack>
        </Box>
      </Box>

      {/*  })} */}
      {/* {currentUser?.saver?.chat.map((chat, i) => {
        const isChatBeforeToday = moment(chat.startTime).isBefore(
          moment(),
          "day"
        );

        if (isChatBeforeToday) return null;

        return (
          <Box mt="1rem" key={i}>
            <Box borderRadius="md" bgColor="teal.50" p="1rem 1.5rem" w="90%">
              <HStack align="top">
                <StreamlineIcon
                  name={"calendar-check-icon-light"}
                  boxSize="3rem"
                  mr="1rem"
                  color="teal.500"
                />
                <Box>
                  <Text fontWeight="600">You have a chat scheduled</Text>
                  <Text fontWeight="600">
                    {moment(chat.startTime).format("dddd, MMMM D [at] H:mma")}
                  </Text>
                  <Box mt="0.5rem">
                    <Button
                      variant="outline"
                      colorScheme="teal"
                      mr="0.5rem"
                      as="a"
                      href={chat.location.join_url}
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(chat.location.join_url, "_blank");
                      }}
                    >
                      Join video call
                    </Button>
                    <Button
                      variant="outline"
                      colorScheme="teal"
                      onClick={onToggle}
                    >
                      Join with phone
                    </Button>
                    <Collapse in={isOpen} animateOpacity>
                      <Box
                        p="1rem"
                        color="white"
                        mt="4"
                        bg="teal.500"
                        rounded="md"
                        shadow="md"
                      >
                        <Text textDecoration="underline" fontWeight="bold">
                          Phone numbers
                        </Text>

                        {chat?.location?.data?.settings
                          ?.global_dial_in_numbers &&
                          chat?.location?.data?.settings?.global_dial_in_numbers.map(
                            ({ city, number }, i) => {
                              return (
                                <Text key={i}>
                                  {city} {number}
                                </Text>
                              );
                            }
                          )}
                      </Box>
                    </Collapse>
                  </Box>
                </Box>
              </HStack>
            </Box>
          </Box>
        );
      })} */}
    </Box>
  );
};

export default ChatDetails;
