import { setImmer } from "./utility";
import { APP_DEFAULTS } from "./utility/app/appDefaults";
import create from "zustand";
import produce from "immer";
import { Loan } from "./utility/loans/loan";

type LoansStoreState = {
  autopayMaxDayOfMonth: number;
  autopayMaxPayment: number;
  autopayMinDayOfMonth: number;
  autopayMinPayment: number;
  loans: Loan[] | null;
  paymentsAdvanceMin: number;
  paymentsAdvanceMax: number;
  paymentsScheduleDaysMax: number;
  paymentsPrincipalPaymentMin: number;
  paymentsPrincipalPaymentMax: number;

  setLoans: (loans: Loan[] | null) => void;
  setStoreObj: (setObj: any) => void;
  setImmerObj: (obj: any) => void;
};

const useLoansStore = create<LoansStoreState>((set) => ({
  // Key Value
  autopayMaxDayOfMonth: APP_DEFAULTS.AUTOPAY_MAX_DAY_OF_MONTH,
  autopayMaxPayment: APP_DEFAULTS.AUTOPAY_MAX_PAYMENT,
  autopayMinDayOfMonth: APP_DEFAULTS.AUTOPAY_MIN_DAY_OF_MONTH,
  autopayMinPayment: APP_DEFAULTS.AUTOPAY_MIN_PAYMENT,
  loans: null,
  paymentsAdvanceMin: APP_DEFAULTS.PAYMENTS_ADVANCE_MIN,
  paymentsAdvanceMax: APP_DEFAULTS.PAYMENTS_ADVANCE_MAX,
  paymentsScheduleDaysMax: APP_DEFAULTS.PAYMENTS_SCHEDULE_DAYS_MAX,
  paymentsPrincipalPaymentMin: APP_DEFAULTS.PAYMENTS_PRINCIPAL_PAYMENT_MIN,
  paymentsPrincipalPaymentMax: APP_DEFAULTS.PAYMENTS_PRINCIPAL_PAYMENT_MAX,

  // Functions
  setImmerObj: (obj) => set(produce(setImmer(obj))),
  setLoans: (loans) => set({ loans }),
  setStoreObj: (setObj) => set(setObj),
}));

const loansStore: LoansStoreState = useLoansStore.getState();

export { loansStore, useLoansStore };
