import React from "react";
import {
  FormControl,
  FormHelperText,
  FormErrorMessage,
  FormLabel,
  SelectProps,
} from "@chakra-ui/react";
import { Select } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";

type SelectFieldValues = {
  label: string;
  value: string;
};

interface SelectFieldProps extends SelectProps {
  disableFirstXOptions?: number;
  helperText?: string;
  isLoading?: boolean;
  isRequired?: boolean;
  label?: string;
  name: string;
  styleLabel?: any;
  values: SelectFieldValues[];
}

const SelectField: React.FC<SelectFieldProps> = (props) => {
  const {
    disableFirstXOptions,
    helperText,
    isLoading,
    isRequired,
    label,
    name,
    values,
    styleLabel,
    ...rest
  } = props;
  const {
    formState: { errors },
    register,
  } = useFormContext();

  return (
    <FormControl
      isInvalid={errors[name]}
      isDisabled={isLoading}
      isRequired={isRequired}
    >
      <FormLabel style={styleLabel ? styleLabel : undefined}>
        {label && label}
      </FormLabel>
      <Select {...register(name)} {...rest} bgColor="white">
        {values.map(({ value, label }, i) => {
          return (
            <option
              value={value}
              key={i}
              disabled={
                Number.isInteger(disableFirstXOptions) &&
                i <= disableFirstXOptions!
              }
            >
              {label}
            </option>
          );
        })}
      </Select>
      <FormErrorMessage>
        {errors[name] && errors[name].message}
      </FormErrorMessage>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default SelectField;
