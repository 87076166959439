import React from "react";
import { Flex } from "@chakra-ui/react";
import Navbar from "./Navbar";
import SideNav from "./SideNav";

const PageWrapper = ({ children }) => {
  return (
    <Flex flex="auto" flexDirection="column" minH="100vh">
      <SideNav />
      <Flex
        direction="column"
        flex="auto"
        ml={{ base: "0px", sm: "64px", md: "237px" }}
        pt={68}
        minH="100vh"
      >
        <Flex
          bg="gray.100"
          flex="auto"
          padding="0px 24px"
          flexDirection="column"
          alignItems="center"
        >
          <Flex
            flex="initial"
            flexDirection="column"
            width="100%"
            margin="0"
            padding="0"
            maxWidth={1450}
          >
            <Navbar />
            <Flex flexDirection="row" width="100%" p="24px 0 0">
              {children}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PageWrapper;
