import React from "react";
import { Global } from "@emotion/react";

const Fonts = () => {
  // TODO: Implement CDN font loading for local development. Instructions here: https://stackoverflow.com/questions/65523590/cant-add-http-localhost-to-digitalocean-spaces-cors-rules
  // https://stackoverflow.com/questions/70315252/access-control-allow-origin-header-missing-from-objects-even-after-adding-cors-s/70315253#70315253
  const cdnHost =
    process.env.NODE_ENV === "development" ? "" : "https://cdn.saferate.com";

  return (
    <Global
      styles={`
        @font-face {
            font-family: "tt_norms";
            src: url("${cdnHost}/fonts/ttnorms-regular-webfont.woff2") format("woff2"),
              url("${cdnHost}/fonts/ttnorms-regular-webfont.woff") format("woff");
            font-weight: normal;
            font-style: normal;
          }
          
          @font-face {
            font-family: "tt_norms";
            src: url("${cdnHost}/fonts/ttnorms-medium-webfont.woff2") format("woff2"),
              url("${cdnHost}/fonts/ttnorms-medium-webfont.woff") format("woff");
            font-weight: 500;
            font-style: normal;
          }
          
          @font-face {
            font-family: "tt_norms";
            src: url("${cdnHost}/fonts/ttnorms-bold-webfont.woff2") format("woff2"),
              url("${cdnHost}/fonts/ttnorms-bold-webfont.woff") format("woff");
            font-weight: bold;
            font-style: normal;
          }
          
          @font-face {
            font-family: "tt_norms";
            src: url("${cdnHost}/fonts/ttnorms-italic-webfont.woff2") format("woff2"),
              url("${cdnHost}/fonts/ttnorms-italic-webfont.woff") format("woff");
            font-weight: normal;
            font-style: italic;
          }
          
          @font-face {
            font-family: "tt_norms";
            src: url("${cdnHost}/fonts/ttnorms-light-webfont.woff2") format("woff2"),
              url("${cdnHost}/fonts/ttnorms-light-webfont.woff") format("woff");
            font-weight: 300;
            font-style: normal;
          }
          `}
    />
  );
};

export default Fonts;
