import React from "react";
import {
  Box,
  Button,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import GetDealSignupForm from "./GetDealSignupForm";
import numeral from "numeral";
import { saverJoinCredits } from "../utility/constants";

type GetDealSignupProps = {
  emailData: any;
  dealType: "rate" | "closing" | "card";
  variant?: string;
  width?: string;
  action?: string;
  colorScheme?: string;
};

const GetDealSignupModal: React.FC<GetDealSignupProps> = ({
  dealType,
  variant,
  width,
  action,
  emailData,
  colorScheme,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef();
  const finalRef = React.useRef();

  const [useLongText] = useMediaQuery(`(min-width: 920px)`);

  return (
    <Box>
      <Button
        variant={variant}
        width={width}
        colorScheme={colorScheme}
        size="md"
        onClick={onOpen}
      >
        Get the deal
      </Button>
      <Modal
        initialFocusRef={initialRef as any}
        finalFocusRef={finalRef as any}
        isOpen={isOpen}
        onClose={onClose}
        size="lg"
      >
        <ModalOverlay></ModalOverlay>
        <ModalContent>
          <ModalHeader>Get the deal</ModalHeader>
          <ModalCloseButton></ModalCloseButton>
          <ModalBody pb={6}>
            <GetDealSignupForm
              closeModal={onClose}
              dealType={dealType}
              emailData={emailData}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default GetDealSignupModal;
