import { useApolloClient } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Flex,
  Box,
  IconButton,
  useDisclosure,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Divider,
  List,
  ListItem,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import { SafeRateLogo } from "../custom-icons";
import NavItems from "./NavItems";

const NavbarMobileMenu = () => {
  const client = useApolloClient();
  const { logout } = useAuth0();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();

  return (
    <Flex
      as="nav"
      alignItems="center"
      p="16px 24px"
      borderBottomWidth={1}
      borderBottomColor="gray.200"
      position="fixed"
      left="0"
      top="0"
      right="0"
      zIndex="3"
      justifyContent="space-between"
      minHeight={68}
      maxHeight={68}
      bg="white"
    >
      <Box>
        <SafeRateLogo boxSize="4rem" />
      </Box>
      <Box>
        <IconButton
          aria-label="Mobile menu"
          icon={<HamburgerIcon />}
          variant="outline"
          ref={btnRef as any}
          onClick={onOpen}
          //mr={{ base: "3rem", sm: "0" }}
        />
        <Drawer
          isOpen={isOpen}
          placement="top"
          onClose={onClose}
          finalFocusRef={btnRef as any}
          isFullHeight
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <Box p="1rem" mt="2rem">
              <NavItems mobileMenuClose={onClose} isSideNav={false} />
              <Divider m="1rem 0" />

              <List p="0" listStyleType="none" m="18px 8px">
                <ListItem
                  display="flex"
                  alignItems="center"
                  h="48px"
                  cursor="pointer"
                  mb="22px"
                >
                  <Flex
                    as="a"
                    alignItems="center"
                    w="100%"
                    h="100%"
                    role="group"
                    _hover={{ bg: "gray.200", borderRadius: "0.5rem" }}
                    pl="0.5rem"
                    onClick={() => {
                      client.resetStore();
                      logout({ returnTo: window.location.origin });
                    }}
                  >
                    <Box
                      as="span"
                      whiteSpace="nowrap"
                      display="flex"
                      alignItems="center"
                      fontSize="lg"
                      fontWeight="600"
                      ml="16px"
                      color="teal.500"
                    >
                      Log out
                    </Box>
                  </Flex>
                </ListItem>
              </List>
            </Box>
          </DrawerContent>
        </Drawer>
      </Box>
    </Flex>
  );
};

export default NavbarMobileMenu;
