import React from "react";
import DollarInputField from "../components/form/DollarInputField";

const AnnualIncome = (props) => {
  return (
    <React.Fragment>
      <DollarInputField
        name={props.model[props.formGroup].annualIncome.name}
        autoFocus={
          props.autoFocus && props.autoFocus !== undefined
            ? props.autoFocus
            : true
        }
      />
    </React.Fragment>
  );
};

export default AnnualIncome;
