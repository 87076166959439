import { CalendarIcon } from "@chakra-ui/icons";
import { Box, Button, HStack, Link, Stack, Text } from "@chakra-ui/react";
import React from "react";
import StreamlineIcon from "../components/StreamlineIcon";
import { useGeneralStore } from "../generalStore";
import { Link as ReactRouterLink } from "react-router-dom";

import numeral from "numeral";
import { saverChatCredits } from "../utility/constants";
import moment from "moment";
import ChatDetails from "../chat/ChatDetails";
import {
  getOneUser,
  getOneUserVariables,
  getOneUser_getOneUser_saver_chat,
} from "../types/getOneUser";
import ChatDetailsModal from "../chat/ChatDetailsModal";
import CalendlyComponent from "../components/CalendlyComponent";
import { useLazyQuery } from "@apollo/client";
import { GET_ONE_USER } from "../graphql/savers/constants";

const ChatBox = () => {
  const [isChatScheduled, setIsChatScheduled] = React.useState(false);
  const currentUser = useGeneralStore((state) => state.currentUser);
  let hasChatCredit = false;

  if (currentUser?.saver?.saverCredit) {
    for (let i = 0; i < currentUser.saver.saverCredit.length; i++) {
      const { creditType } = currentUser?.saver?.saverCredit[i];

      if (creditType === "chat") {
        hasChatCredit = true;
        break;
      }
    }
  }

  let upcomingChat: getOneUser_getOneUser_saver_chat | null = null;

  if (currentUser?.saver?.chat) {
    const upcomingChats = currentUser.saver.chat
      .filter((chat) => !moment(chat.startTime).utc().isBefore(moment(), "day"))
      .sort();

    if (upcomingChats.length > 0) {
      upcomingChat = upcomingChats[0];
    }
  }

  let hasAChatScheduled = false;

  if (currentUser?.saver?.chat) {
    for (let i = 0; i < currentUser.saver.chat.length; i++) {
      const chat = currentUser.saver.chat[i];
      const isChatAfterToday = moment(chat.startTime).isAfter(moment(), "day");

      if (isChatAfterToday) {
        hasAChatScheduled = true;
        break;
      }
    }
  }

  return (
    <Box>
      <Stack
        w="100%"
        alignItems="top"
        direction={{ base: "column", lg: "row" }}
      >
        <Box w="100%" mt="0.5rem">
          {(() => {
            if (isChatScheduled) {
              return (
                <Box mt="0.5rem">
                  <Text fontWeight={600} color="purple.600">
                    Your chat is scheduled!
                  </Text>
                </Box>
              );
            } else if (upcomingChat) {
              return (
                <Box mt="0.5rem">
                  <ChatDetailsModal upcomingChat={upcomingChat} />
                </Box>
              );
            } else {
              return (
                <Box mt="0.5rem">
                  <CalendlyComponent
                    displayComponent={
                      <Button colorScheme="purple" variant="outline" />
                    }
                    componentText="Schedule a time"
                    onSuccess={() => {
                      setIsChatScheduled(true);
                      // setTimeout(() => {
                      //   getOneUser({
                      //     variables: {
                      //       id: currentUser!.id,
                      //     },
                      //   });
                      // }, 1500);
                    }}
                    url={process.env.REACT_APP_CALENDLY_URL}
                  />
                  {/* <Button
                  colorScheme="purple"
                  variant="outline"
                  onClick={() =>
                    openPopupWidget({
                      url,
                      prefill,
                      pageSettings: undefined,
                      utm: undefined,
                    })
                  }
                >
                  Schedule a time
                </Button> */}
                </Box>
              );
            }
          })()}
        </Box>
      </Stack>
    </Box>
  );
};

export default ChatBox;
