import {
  Box,
  Container,
  Link,
  Stack,
  Text,
  Divider,
  Flex,
} from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import React from "react";
import { SafeRateLogoWithText } from "../custom-icons";
import StreamlineIcon from "../components/StreamlineIcon";

const FooterHome = () => {
  return (
    <Box bg="gray.100" p="1rem" as="footer" minH="15rem">
      <Container maxW="container.xl" pt="3rem 0">
        <Flex direction={{ base: "column", lg: "row" }}>
          <Box w={{ base: "100%", lg: "20%" }}>
            <SafeRateLogoWithText boxSize="7rem" />
          </Box>
          <Box w={{ base: "100%", lg: "15%" }}>
            <Text mt={{ base: "0.5rem", lg: "2rem" }}>
              © 2022 Safe Rate Inc.
            </Text>
          </Box>
          <Box w={{ base: "100%", lg: "15%" }}>
            <Text mt={{ base: "0.5rem", lg: "2rem" }}>
              <Link as={ReactRouterLink} to="/terms-of-use">
                Terms of Use
              </Link>
            </Text>
          </Box>
          <Box w={{ base: "100%", lg: "15%" }}>
            <Text mt={{ base: "0.5rem", lg: "2rem" }}>
              <Link as={ReactRouterLink} to="/privacy-policy">
                Privacy Policy
              </Link>
            </Text>
          </Box>
          <Box w={{ base: "100%", lg: "15%" }}>
            <Text mt={{ base: "0.5rem", lg: "2rem" }}>
              <Link as={ReactRouterLink} to="/licensing-and-disclosure">
                Disclosures
              </Link>
            </Text>
          </Box>
          <Box w={{ base: "100%", lg: "15%" }}>
            <Text mt={{ base: "0.5em", lg: "2rem" }}>
              <Link href="https://learn.saferate.com/" isExternal>
                Blog
              </Link>
            </Text>
          </Box>
          <Box w={{ base: "100%", lg: "15%" }}>
            <Flex mt={{ base: "0.5rem", lg: "2rem" }}>
              <Link
                mr="0.5rem"
                href="https://www.facebook.com/saferatemortgage/"
                isExternal
              >
                <StreamlineIcon
                  name="facebook-icon"
                  boxSize="1.2rem"
                  color="#3b5998"
                />
              </Link>

              <Link
                mr="0.5rem"
                href="https://twitter.com/saferateco"
                isExternal
              >
                <StreamlineIcon
                  name="twitter-icon"
                  boxSize="1.2rem"
                  color="#1DA1F2"
                />
              </Link>
              <Link
                mr="0.5rem"
                href="https://www.instagram.com/saferate"
                isExternal
              >
                <StreamlineIcon
                  name="instagram-icon"
                  boxSize="1.2rem"
                  color="#C13584"
                />
              </Link>
              <Link
                mr="0.5rem"
                href="https://www.linkedin.com/company/saferate"
                isExternal
              >
                <StreamlineIcon
                  name="linkedin-icon"
                  boxSize="1.2rem"
                  color="#0A66C2"
                />
              </Link>
              <Link
                mr="0.5rem"
                href="https://www.youtube.com/channel/UCLfbFQpfg22THb-DcKhJLlA
"
                isExternal
              >
                <StreamlineIcon
                  name="youtube-icon"
                  boxSize="1.2rem"
                  color="#FF0000"
                />
              </Link>
              <Link mr="0.5rem" href="https://medium.com/@saferate" isExternal>
                <StreamlineIcon
                  name="medium-icon"
                  boxSize="1.2rem"
                  color="#00ab6c"
                />
              </Link>
            </Flex>
          </Box>
        </Flex>
        <Divider />
        <Box mt="1rem">
          <Text>
            © 2022 Safe Rate Inc. and/or its affiliates. Safe Rate is a family
            of companies. Safe Rate Lending provides home loans. All rights
            reserved.
          </Text>
          <Text mt="0.5rem">
            Home lending products offered by Safe Rate Lending. Safe Rate
            Lending is a direct lender. NMLS #1590949. 515 N State St, Floor 13,
            Chicago, IL 60654. Not available in all states. Equal Housing
            Lender.{" "}
            <Link
              href="https://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/1590949"
              isExternal
            >
              NMLS Consumer Access
            </Link>
          </Text>
        </Box>
      </Container>
    </Box>
  );
};

export default FooterHome;
