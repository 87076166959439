// eslint-disable-next-line import/no-anonymous-default-export
export default (initialObj: object, nestedKeysAndFields: object) => {
  const returnObj: any = {};
  const fieldsToIgnoreFromInitialObj: string[] = [];

  const initialObjKeys = Object.keys(initialObj);
  const nestedKeysAndFieldsKeys = Object.keys(nestedKeysAndFields);

  const nestedKeyAndOriginalKeyTheSame = initialObjKeys.some((r) =>
    nestedKeysAndFieldsKeys.includes(r)
  );

  if (nestedKeyAndOriginalKeyTheSame) {
    throw new Error(
      "A nested object key and original key have the same name. This won't work"
    );
  }

  nestedKeysAndFieldsKeys.forEach((key) => {
    returnObj[key] = {};
    const fieldsToNest = nestedKeysAndFields[key];
    fieldsToNest.forEach((field) => {
      if (initialObj[field] !== undefined) {
        returnObj[key][field] = initialObj[field];
      }
      fieldsToIgnoreFromInitialObj.push(field);
    });
  });

  initialObjKeys.forEach((key) => {
    if (!fieldsToIgnoreFromInitialObj.includes(key)) {
      returnObj[key] = initialObj[key];
    }
  });

  return returnObj;
};
