import React, { ReactElement, useState } from "react";
import { useGeneralStore } from "../generalStore";
import { useCalendlyEventListener, PopupModal } from "react-calendly";

const CalendlyComponent = ({
  componentText,
  displayComponent,
  onClick,
  onSuccess,
  prefill,
  url,
}: {
  componentText: string;
  displayComponent: ReactElement;
  onClick?: () => void;
  onSuccess?: () => void;
  prefill?: {
    email: string;
    firstName: string;
    lastName: string;
    name: string;
  } | null;
  url: string;
}) => {
  const currentUser = useGeneralStore((state) => state.currentUser);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  useCalendlyEventListener({
    onEventScheduled: () => {
      if (onSuccess) {
        onSuccess();
      }
    },
  });

  const clonedElement = React.cloneElement(
    displayComponent,
    {
      onClick: () => {
        if (onClick) {
          onClick();
        }

        setIsOpen(true);
      },
    },
    componentText
  );

  let prefillObj:
    | {
        email: string;
        firstName: string;
        lastName: string;
        name: string;
      }
    | undefined = undefined;

  if (prefill !== null) {
    if (
      currentUser?.email &&
      currentUser?.firstName &&
      currentUser?.lastName &&
      currentUser?.name
    ) {
      prefillObj = {
        email: currentUser.email,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        name: currentUser.name,
      };
    }
  }

  return (
    <>
      {clonedElement}
      <PopupModal
        url={url}
        pageSettings={undefined}
        utm={undefined}
        prefill={prefillObj}
        onModalClose={() => setIsOpen(false)}
        open={isOpen}
        rootElement={document.getElementById("root")!}
      />
    </>
  );
};

export default CalendlyComponent;
