export enum graphQLContext {
  SERVICING = "servicing",
}

type formModel = {
  defaultValue: any;
  helperText?: string;
  label?: string;
  name: string;
  placeholder?: string;
  serverTransform?: any;
  validation: any;
};

export interface IFormModel {
  [path: string]: formModel;
}

export type SelectOptions = {
  label: string;
  value: string;
};
