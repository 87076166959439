import {
  Box,
  Text,
  Divider,
  Heading,
  Stack,
  ListItem,
  HStack,
  UnorderedList,
} from "@chakra-ui/react";
import Card from "../components/Card";
import StreamlineIcon from "../components/StreamlineIcon";

const ApplicationTips = () => {
  return (
    <Card>
      <Heading fontSize="lg" fontWeight="500" mb="1rem">
        Tips for a successful mortgage application
      </Heading>
      <Divider />
      <Stack direction="column">
        <HStack p="0.5rem 0" mt="1.5rem" alignItems="top">
          <Box>
            <StreamlineIcon
              name="file-icon"
              boxSize="1.5rem"
              mr="1rem"
              color="purple.600"
            ></StreamlineIcon>
          </Box>
          <Box>
            <Text fontSize="lg" color="purple.500" fontWeight="500">
              Have your documents prepared
            </Text>
            <Text mt="0.5rem">Documents include:</Text>
            <UnorderedList>
              <ListItem>Government issued ID</ListItem>
              <ListItem>Most recent paystub</ListItem>
              <ListItem>Last two years of tax returns</ListItem>
              <ListItem>Last two months of bank statements</ListItem>
              <ListItem>
                Most recent mortgage statement (for a refinance)
              </ListItem>
            </UnorderedList>
          </Box>
        </HStack>
        <HStack p="0.5rem 0">
          <StreamlineIcon
            name="credit-card-dollar-icon"
            boxSize="1.5rem"
            mr="1rem"
            color="purple.600"
          ></StreamlineIcon>
          <Text fontSize="lg" color="purple.500" fontWeight="500">
            Keep your credit card balances low{" "}
          </Text>
        </HStack>
        <HStack p="0.5rem 0">
          <StreamlineIcon
            name="transfer-circle-icon"
            boxSize="1.5rem"
            mr="1rem"
            color="purple.600"
          ></StreamlineIcon>
          <Text fontSize="lg" color="purple.500" fontWeight="500">
            Avoid making large transfers out of or into your bank accounts
          </Text>
        </HStack>
        <HStack p="0.5rem 0">
          <StreamlineIcon
            name="invoice-mail-icon"
            boxSize="1.5rem"
            mr="1rem"
            color="purple.600"
          ></StreamlineIcon>
          <Text fontSize="lg" color="purple.500" fontWeight="500">
            Keep paying your bills on time
          </Text>
        </HStack>
        <HStack p="0.5rem 0">
          <StreamlineIcon
            name="user-chat-icon"
            boxSize="1.5rem"
            mr="1rem"
            color="purple.600"
          ></StreamlineIcon>
          <Text fontSize="lg" color="purple.500" fontWeight="500">
            Respond to your lender's requests in a timely manner
          </Text>
        </HStack>
      </Stack>
    </Card>
  );
};

export default ApplicationTips;
