import React from "react";
import RadioGroupField from "../components/form/RadioGroupField";
import { propertyTypes } from "../utility/constants";

const PropertyType = (props) => {
  return (
    <React.Fragment>
      <RadioGroupField
        name={props.model[props.formGroup].propertyType.name}
        values={propertyTypes}
      />
    </React.Fragment>
  );
};

export default PropertyType;
