import { get } from "lodash";
// eslint-disable-next-line import/no-anonymous-default-export
export default (model, serverObj = {}, shouldServerTransform?) => {
  if (!model) return;
  if (!serverObj) serverObj = {};
  const returnObj = {};
  const modelKeys = Object.keys(model);

  modelKeys.forEach((modelKey) => {
    //means we have data from the server we need to set as the default value
    //also need to transform the data in some way before going into the form

    let returnValue;

    if (model[modelKey].defaultValue !== undefined) {
      returnValue = model[modelKey].defaultValue;
    }

    if (serverObj[modelKey] !== undefined && serverObj[modelKey] !== null) {
      returnValue = serverObj[modelKey];
    }

    if (model[modelKey].nestedObjectKey) {
      const getValue = get(
        serverObj,
        `${model[modelKey].nestedObjectKey}.${modelKey}`
      );

      if (getValue !== undefined) {
        returnValue = getValue;
      }
    }

    if (serverObj[modelKey] !== undefined && model[modelKey].serverTransform) {
      returnValue = model[modelKey].serverTransform(returnValue);
    } else if (model[modelKey].serverTransform && shouldServerTransform) {
      returnValue = model[modelKey].serverTransform(returnValue);
    }

    returnObj[modelKey] = returnValue;
  });

  return returnObj;
};
