// eslint-disable-next-line import/no-anonymous-default-export
export default (url) => {
  const image = new Image();
  image.src = url;
  if (image.width === 0) {
    return false;
  } else {
    return true;
  }
};
