// eslint-disable-next-line import/no-anonymous-default-export
export default (textArr: string[]): string => {
  const sortedArray = textArr.sort();
  if (sortedArray.length === 1) {
    return sortedArray[0];
  } else if (sortedArray.length === 2) {
    return `${sortedArray[0]} or ${sortedArray[1]}`;
  } else {
    return sortedArray.join(", ").replace(/,(?=[^,]*$)/, ", or");
  }
};
