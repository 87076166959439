import { useLazyQuery } from "@apollo/client";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Box,
  Container,
  Flex,
  HStack,
  SimpleGrid,
  Spacer,
  Spinner,
  Text,
} from "@chakra-ui/react";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useRef } from "react";
import Card from "../components/Card";
import ComponentError from "../components/ComponentError";
import ComponentLoading from "../components/ComponentLoading";
import PageLoader from "../components/PageLoader";
import StreamlineIcon from "../components/StreamlineIcon";
import { SafeRateLogoWithText } from "../custom-icons";
import ChatBoxCard from "../dashboard/ChatBoxCard";
import WelcomeSaverBox from "../dashboard/WelcomeSaverBox";
import { useGeneralStore } from "../generalStore";
import { GET_RATE_FOR_SAVER } from "../graphql/savers/constants";
import ApplyBox from "../loans/ApplyBox";
import SaverOnboardPurchase from "../onboard/SaverOnboardPurchase";
import SaverOnboardRefinance from "../onboard/SaverOnboardRefinance";
import {
  getRateForSaver,
  getRateForSaverVariables,
  getRateForSaver_getRateForSaver,
} from "../types/getRateForSaver";
import {
  checkIfHasRequiredDataForRate,
  createOrStringFromList,
} from "../utility";
import { saverAgentCredits, saverEmployerCredits } from "../utility/constants";
import RatesTable from "./RatesTable";

const RatesSaver = () => {
  const [isOnboardModalOpen, setIsOnboardModalOpen] = React.useState(false);
  const currentUser = useGeneralStore((state) => state.currentUser);
  const [callGetRateForSaver, { data, error, loading }] = useLazyQuery<
    getRateForSaver,
    getRateForSaverVariables
  >(GET_RATE_FOR_SAVER, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (
      currentUser?.saver &&
      checkIfHasRequiredDataForRate(currentUser.saver) &&
      !isOnboardModalOpen
    ) {
      callGetRateForSaver({
        variables: {
          saverId: currentUser.saver.id,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.saver, isOnboardModalOpen]);

  if (!currentUser || !currentUser.saver) {
    return null;
  }

  if (error) {
    return <ComponentError />;
  }

  let fetchedData: null | getRateForSaver_getRateForSaver = null;

  if (data && data.getRateForSaver) {
    fetchedData = data.getRateForSaver;
  }

  return (
    <Box w="100%" mb="4rem">
      <Container maxW="container.xl">
        {!fetchedData && !loading && (
          <WelcomeSaverBox
            onOnboardModalOpen={() => setIsOnboardModalOpen(true)}
          />
        )}

        {loading ? (
          <Flex justifyContent="center" alignItems="center" minH={80}>
            <ComponentLoading />
          </Flex>
        ) : (
          <Box>
            {fetchedData ? (
              <Box>
                <Card header="Home Goal">
                  {(() => {
                    let homeGoalString = "No home goal selected";

                    if (currentUser && currentUser.saver) {
                      let loanTypeText = "";
                      let amountText = "";
                      let propertyTypeText = "";
                      let loanDateText = "";
                      let placeText = "";
                      let downpaymentText = "";

                      const {
                        loanType,
                        refinanceAmount,
                        propertyType,
                        estimatedLoanDate,
                        purchasePrice,
                        downPayment,
                        purchaseGeography,
                        property,
                      } = currentUser.saver;
                      if (loanType === "purchase") {
                        loanTypeText = "Buy";
                        if (purchasePrice) {
                          amountText = numeral(purchasePrice).format("$0,0");
                        }
                        if (downPayment) {
                          downpaymentText = `${numeral(downPayment).format(
                            "$0,0"
                          )} down payment`;
                        }
                      } else if (loanType === "refinance") {
                        loanTypeText = "Refinance";
                        if (refinanceAmount) {
                          amountText = `${numeral(refinanceAmount).format(
                            "$0,0"
                          )} mortgage on a`;
                        }
                      }

                      if (propertyType) {
                        if (propertyType === "singleFamilyResidence") {
                          propertyTypeText = "single-family home";
                        } else if (propertyType === "condoAttached") {
                          propertyTypeText = "condo";
                        } else if (propertyType === "condoDetached") {
                          propertyTypeText = "condo";
                        } else if (propertyType === "townhome") {
                          propertyTypeText = "townhome";
                        }
                      }

                      if (estimatedLoanDate) {
                        loanDateText =
                          moment(estimatedLoanDate).format("MMMM YYYY");
                      }

                      if (property && property.length === 1) {
                        placeText = `at ${property[0].text}`;
                      }

                      if (purchaseGeography) {
                        if (purchaseGeography.length === 1) {
                          placeText = `in ${purchaseGeography[0].text}`;
                        } else if (purchaseGeography.length > 1) {
                          let statesSet = new Set<string>();
                          let leastAmountOfContext =
                            purchaseGeography[0].context.length;

                          purchaseGeography.forEach(({ context }) => {
                            if (context[context.length - 2]) {
                              statesSet.add(context[context.length - 2].text);
                            }

                            if (context.length < leastAmountOfContext) {
                              leastAmountOfContext = context.length;

                              if (context[context.length - 2]) {
                                placeText = `in ${
                                  context[context.length - 2].text
                                }`;
                              } else if (context[context.length - 1]) {
                                placeText = `in ${
                                  context[context.length - 1].text
                                }`;
                              }
                            }
                          });

                          if (leastAmountOfContext <= 3 && statesSet.size > 1) {
                            const stateOrString = createOrStringFromList(
                              Array.from(statesSet)
                            );

                            placeText = `in ${stateOrString}`;
                          }
                        }
                      }

                      if (loanTypeText && amountText && propertyTypeText) {
                        if (loanType === "purchase") {
                          homeGoalString = `${loanTypeText} a ${amountText} ${propertyTypeText}${
                            placeText ? ` ${placeText} ` : ""
                          }${
                            downpaymentText ? ` with a ${downpaymentText}` : ""
                          } `;
                        }

                        if (loanType === "refinance") {
                          homeGoalString = `${loanTypeText} a ${amountText} ${propertyTypeText}`;
                        }
                      }
                    }

                    return (
                      <Flex direction={{ base: "column", lg: "row" }}>
                        <Text fontSize="md" fontWeight="400">
                          {homeGoalString}
                        </Text>
                        <Spacer />
                        {currentUser?.saver?.loanType === "purchase" ? (
                          <SaverOnboardPurchase
                            saver={currentUser.saver}
                            buttonType="updateHomeGoal"
                            onModalOpen={() => setIsOnboardModalOpen(true)}
                          />
                        ) : currentUser?.saver?.loanType === "refinance" ? (
                          <SaverOnboardRefinance
                            saver={currentUser.saver}
                            buttonType="updateHomeGoal"
                            onModalOpen={() => setIsOnboardModalOpen(true)}
                          />
                        ) : null}
                      </Flex>
                    );
                  })()}
                </Card>
                <Card header="Your deals">
                  <RatesTable
                    currentDealTypeForSaver={
                      currentUser?.saver?.dealType
                        ? currentUser.saver.dealType
                        : undefined
                    }
                    data={fetchedData}
                  />
                </Card>
                <SimpleGrid columns={[1, 1, 1, 1, 2]} spacingX="1rem">
                  <ApplyBox saver={currentUser.saver} />
                  <ChatBoxCard headerText={"Have questions? Schedule a chat"} />
                </SimpleGrid>
              </Box>
            ) : (
              <Card header="How it works">
                <HStack p="0.5rem 0" align="top">
                  <Box>
                    {/*  <ArrowForwardIcon color="purple.500" /> */}
                    <StreamlineIcon
                      name="arrow-right-circle-icon"
                      boxSize="1.5rem"
                      color="purple.500"
                    />
                  </Box>
                  <Box>
                    <Text fontWeight="400">Set your home goals</Text>
                  </Box>
                </HStack>
                <HStack p="0.5rem 0" align="top">
                  <Box>
                    <StreamlineIcon
                      name="arrow-right-circle-icon"
                      boxSize="1.5rem"
                      color="purple.500"
                    />
                  </Box>
                  <Box>
                    <Text fontWeight="400">
                      Select which deal to apply your credits towards
                    </Text>
                  </Box>
                </HStack>
                <HStack p="0.5rem 0" align="top">
                  <Box>
                    <StreamlineIcon
                      name="arrow-right-circle-icon"
                      boxSize="1.5rem"
                      color="purple.500"
                    />
                  </Box>
                  <Box>
                    <Text fontWeight="400">
                      Invite your real estate agent to earn an additional{" "}
                      {numeral(saverAgentCredits).format("$0,0")} in credits
                      (for purchases)
                    </Text>
                  </Box>
                </HStack>
                <HStack p="0.25rem 0" align="top">
                  <Box>
                    <StreamlineIcon
                      name="arrow-right-circle-icon"
                      boxSize="1.5rem"
                      color="purple.500"
                    />
                  </Box>
                  <Box>
                    <Text fontWeight="400">
                      Invite your employer to earn an additional{" "}
                      {numeral(saverEmployerCredits).format("$0,0")} in credits
                    </Text>
                  </Box>
                </HStack>
                <HStack p="1rem 0rem" align="top">
                  <Text>
                    Your credits will be applied once you have completed an
                    application and have been approved for a mortgage loan by
                    Safe Rate.
                  </Text>
                </HStack>
              </Card>
            )}
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default RatesSaver;
