import { useApolloClient } from "@apollo/client";
import {
  Flex,
  Box,
  Button,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ButtonGroup,
  Link,
  useBreakpointValue,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useReducer, useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { UPDATE_ONE_SAVER } from "../graphql/savers/constants";
import OnboardFormButtons from "./OnboardFormButtons";
import OnboardWrapper from "../onboard/OnboardWrapper";
import convertEstimatedLoanDateToEstimatedMonthYear from "../settings/utility/convertEstimatedLoanDateToEstimatedMonthYear";
import { getOneUser_getOneUser_saver } from "../types/getOneUser";
import {
  updateOneSaver,
  updateOneSaverVariables,
} from "../types/updateOneSaver";
import {
  buildValidationSchemaObj,
  getDefaultFormValues,
  removeObjKeys,
} from "../utility";
import helper from "./utility/refinanceHelper";
import model from "./utility/refinanceModel";
import { ArrowForwardIcon } from "@chakra-ui/icons";

type initialStateType = {
  activePage: number;
  formStarted: boolean;
};

const initialState: initialStateType = {
  activePage: 0,
  formStarted: false,
};

const finalTransformFormGroup = {
  estimatedLoanDate: ({ estimatedLoanDateMonth, estimatedLoanDateYear }) => {
    return {
      estimatedLoanDate: new Date(
        `${estimatedLoanDateYear}-${estimatedLoanDateMonth}-01`
      ).toISOString(),
    };
  },
  // refinanceProperty: ({ property }) => ({ property }),
};

function reducer(state, action) {
  switch (action.type) {
    case "resetForm":
      return { ...initialState };
    case "updateForm":
      return { ...state, ...action.payload };
    default:
      throw new Error("This action does not exist");
  }
}

const pages = [
  "estimatedLoanDate",
  "property",
  "propertyType",
  "occupancyType",
  "refinanceAmount",
  "estimatedHomeValue",
  "currentRate",
  "mortgageInsurance",
  "annualIncome",
  "creditCurrentCategory",
  // "financialHelp",
  "saverFinish",
];

const getDefaultValues = (formGroup, model, saver): any => {
  let defaultValues: any = {};

  Object.keys(model).forEach((modelKey) => {
    defaultValues = {
      ...defaultValues,
      ...getDefaultFormValues(model[modelKey], saver),
    };
  });

  if (formGroup === "estimatedLoanDate") {
    if (saver.estimatedLoanDate) {
      defaultValues = {
        ...defaultValues,
        ...convertEstimatedLoanDateToEstimatedMonthYear(
          saver.estimatedLoanDate
        ),
      };
    }
  }

  // if (formGroup === "refinanceProperty") {
  //   if (saver.property) {
  //     defaultValues = {
  //       ...defaultValues,
  //       refinanceProperty: saver.property,
  //     };
  //   }
  // }

  // console.log("defaultValues", defaultValues);

  return defaultValues;
};

// buttonType,
// saver,
// }: {
// buttonType: "updateHomeGoal" | "getStarted";

const SaverOnboardRefinance = ({
  buttonType,
  onModalOpen,
  saver,
}: {
  buttonType: "updateHomeGoal" | "getStarted";
  onModalOpen?: () => void;
  saver: getOneUser_getOneUser_saver;
}) => {
  const modalSize = useBreakpointValue({ base: "full", md: "lg" });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const client = useApolloClient();
  const [state, dispatch] = useReducer(reducer, initialState);

  const { activePage } = state;
  let formGroup, stepButtonNextText, stepButtonBackText;

  // //get the validation schema for this active page
  if (
    pages[activePage] &&
    helper[pages[activePage]] &&
    helper[pages[activePage]].formGroup
  ) {
    formGroup = helper[pages[activePage]].formGroup;
  }

  const isFinalPage = pages[activePage] === "saverFinish";
  //get the text for the next button for this active page
  if (
    pages[activePage] &&
    helper[pages[activePage]] &&
    helper[pages[activePage]].stepButtonNextText
  ) {
    stepButtonNextText = helper[pages[activePage]].stepButtonNextText;
  }

  //get the text for the back button for this active page
  if (
    pages[activePage] &&
    helper[pages[activePage]] &&
    helper[pages[activePage]].stepButtonBackText
  ) {
    stepButtonBackText = helper[pages[activePage]].stepButtonBackText;
  }

  const validationSchema = model[formGroup]
    ? buildValidationSchemaObj(model[formGroup])
    : undefined;

  const resolver = validationSchema ? yupResolver(validationSchema) : undefined;

  const yupResolverRef = useRef(resolver);

  const methods = useForm({
    defaultValues: getDefaultValues(formGroup, model, saver),
    mode: "onSubmit",
    resolver: yupResolverRef.current,
    reValidateMode: "onChange",
  });

  const {
    getValues,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods;

  useEffect(() => {
    reset(getDefaultValues(formGroup, model, saver));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formGroup, model, saver]);

  const values = getValues();

  const getVariables = (onSubmitData: any) => {
    if (
      pages[activePage] &&
      helper[pages[activePage]] &&
      helper[pages[activePage]].formGroup
    ) {
      let input = {
        id: saver.id,
      };

      const { formGroup } = helper[pages[activePage]];

      if (finalTransformFormGroup[formGroup]) {
        const processedSubmitData =
          finalTransformFormGroup[formGroup](onSubmitData);
        input = {
          ...input,
          ...processedSubmitData,
        };
      } else {
        const keysToKeep = Object.keys(model[formGroup]);
        const processedSubmitData = removeObjKeys(onSubmitData, keysToKeep);
        input = {
          ...input,
          ...processedSubmitData,
        };
      }

      return input;
    }
  };

  const onSubmit = async (onSubmitData) => {
    if (
      pages[activePage] &&
      helper[pages[activePage]] &&
      helper[pages[activePage]].formGroup
    ) {
      try {
        const input = getVariables(onSubmitData);
        if (!input) return;

        console.log("input", input);

        await client.mutate<updateOneSaver, updateOneSaverVariables>({
          mutation: UPDATE_ONE_SAVER,
          variables: {
            input: {
              ...input,
            },
          },
        });
      } catch (error) {
        console.error(error);
        return;
      }
    }
    const newActivePage = activePage + 1;
    const payload: any = { activePage: newActivePage };
    if (
      pages[newActivePage] &&
      helper[pages[newActivePage]] &&
      helper[pages[newActivePage]].formGroup
    ) {
      const { formGroup } = helper[pages[newActivePage]];
      const validationSchema = model[formGroup]
        ? buildValidationSchemaObj(model[formGroup])
        : undefined;
      yupResolverRef.current = yupResolver(validationSchema!);
    }
    dispatch({
      type: "updateForm",
      payload,
    });
  };

  let clonedElement: any = null;

  if (
    pages[activePage] &&
    helper[pages[activePage]] &&
    helper[pages[activePage]].component
  ) {
    clonedElement = React.cloneElement(
      helper[pages[activePage]].component,
      { formGroup, model, state, values },
      null
    );
  }

  const handleBack = () => {
    const newActivePage = activePage - 1;
    const payload: any = {};

    if (activePage === 0) {
      payload.formStarted = false;
    } else {
      payload.activePage = newActivePage;
      if (
        pages[newActivePage] &&
        helper[pages[newActivePage]] &&
        helper[pages[newActivePage]].formGroup
      ) {
        const { formGroup } = helper[pages[newActivePage]];

        const validationSchema = model[formGroup]
          ? buildValidationSchemaObj(model[formGroup])
          : undefined;

        yupResolverRef.current = yupResolver(validationSchema!);
      }
    }

    dispatch({
      type: "updateForm",
      payload,
    });
  };

  return (
    <>
      {(() => {
        switch (buttonType) {
          case "updateHomeGoal": {
            return (
              <Link
                mt="0.5rem"
                fontSize="sm"
                color="purple.600"
                mr="1rem"
                onClick={() => {
                  if (onModalOpen) {
                    onModalOpen();
                  }

                  onOpen();
                }}
              >
                Update home goal
              </Link>
            );
          }
          case "getStarted": {
            return (
              <Button
                colorScheme="purple"
                onClick={() => {
                  if (onModalOpen) {
                    onModalOpen();
                  }

                  onOpen();
                }}
              >
                See my personalized rate
              </Button>
            );
          }
          default:
            const exhaustiveCheck: never = buttonType;
            throw new Error(exhaustiveCheck);
        }
      })()}

      <Modal
        isOpen={isOpen}
        onClose={() => {
          dispatch({ type: "resetForm" });

          onClose();
        }}
        isCentered
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton
            onClose={() => {
              dispatch({ type: "resetForm" });

              onClose();
            }}
          />
          <ModalBody>
            <Flex justifyContent="center" alignItems="center">
              {state.formStarted ? (
                <FormProvider {...methods}>
                  <form onSubmit={handleSubmit(onSubmit)} id="onboard-form">
                    <OnboardWrapper {...helper[pages[activePage]]}>
                      {clonedElement ? clonedElement : null}
                      {!isFinalPage && (
                        <OnboardFormButtons
                          handleBack={handleBack}
                          isSubmitting={isSubmitting}
                          stepButtonBackText={stepButtonBackText}
                          stepButtonNextText={stepButtonNextText}
                        />
                      )}
                    </OnboardWrapper>
                  </form>
                </FormProvider>
              ) : (
                <OnboardWrapper
                  icon={"rating-like"}
                  text={"Your home goals"}
                  subtextComponent={
                    <Box mt="1rem">
                      <Box fontSize="lg" textAlign="center">
                        Let's set up your home goals to see your personalized
                        deals.
                      </Box>
                    </Box>
                  }
                >
                  <ButtonGroup>
                    {/* <Button
                      variant={"outline"}
                      colorScheme="purple"
                      onClick={() => onClose()}
                    >
                      I'll do it later
                    </Button> */}

                    <Button
                      colorScheme="purple"
                      onClick={() => {
                        dispatch({
                          type: "updateForm",
                          payload: { formStarted: true },
                        });
                      }}
                    >
                      Start
                    </Button>
                  </ButtonGroup>
                </OnboardWrapper>
              )}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SaverOnboardRefinance;
