export const APP_DEFAULTS = {
  INTEREST_RATE_PRECISION: 3,
  PAYMENTS_ADVANCE_MIN: 1,
  PAYMENTS_ADVANCE_MAX: 6,
  PAYMENTS_SCHEDULE_DAYS_MAX: 30,
  PAYMENTS_PRINCIPAL_PAYMENT_MIN: 100,
  PAYMENTS_PRINCIPAL_PAYMENT_MAX: 5000,
  PAYMENTS_PAYOFF_MIN: 0.0,
  PAYMENTS_PAYOFF_MAX: 5000,
  AUTOPAY_MAX_DAY_OF_MONTH: 15,
  AUTOPAY_MIN_DAY_OF_MONTH: 1,
  AUTOPAY_MIN_PAYMENT: 1,
  AUTOPAY_MAX_PAYMENT: 5000,
};
