import { Box, Text } from "@chakra-ui/react";
import AnnualIncome from "../../onboard/AnnualIncome";
import CreditScoreCategory from "../../onboard/CreditScoreCategory";
import CurrentRate from "../../onboard/CurrentRate";
import DownPayment from "../../onboard/DownPayment";
import EstimatedLoanDate from "../../onboard/EstimatedLoanDate";
import FinancialHelp from "../../onboard/FinancialHelp";
import FirstTimeBuyer from "../../onboard/FirstTimeBuyer";
import MortgageInsurance from "../../onboard/MortgageInsurance";
import PropertyType from "../../onboard/PropertyType";
import PurchaseGeography from "../../onboard/PurchaseGeography";
import PurchasePrice from "../../onboard/PurchasePrice";
import RefinanceAmount from "../../onboard/RefinanceAmount";
import RefinanceProperty from "../../onboard/RefinanceProperty";
import SaverFinish from "../../onboard/SaverFinish";
import EstimatedHomeValue from "../EstimatedHomeValue";
import OccupancyType from "../OccupanyType";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  annualIncome: {
    component: <AnnualIncome />,
    formGroup: "annualIncome",
    icon: "bank-notes",
    text: "What is your annual income?",
    subtextComponent: (
      <Box mt="1rem">
        <Text color="gray.500" textAlign="center">
          This will help us pick the best loan for you.
        </Text>
      </Box>
    ),
  },
  creditCurrentCategory: {
    component: <CreditScoreCategory />,
    formGroup: "creditCurrentCategory",
    icon: "color-wheel",
    text: "What is your credit score?",
    /* subtextComponent: (
      <Box mt="1rem">
        <Text color="gray.500" textAlign="center">
          Having a good credit score makes buying a home easier, but don't worry
          if yours is less than perfect. We'll improve your score together and
          get you the right loan.
        </Text>
      </Box>
    ), */
  },
  currentRate: {
    component: <CurrentRate />,
    formGroup: "currentRate",
    icon: "dollar-coin",
    text: "What is your current interest rate?",
  },
  estimatedHomeValue: {
    component: <EstimatedHomeValue />,
    formGroup: "estimatedHomeValue",
    icon: "bank-notes",
    text: "What is the estimated value of the home?",
  },
  estimatedLoanDate: {
    component: <EstimatedLoanDate />,
    formGroup: "estimatedLoanDate",
    icon: "work-calendar",
    text: "When would you like to complete your refinance?",
  },
  financialHelp: {
    component: <FinancialHelp />,
    formGroup: "financialHelp",
    icon: "money-plant",
    stepButtonNextText: "Finish",
    text: "Are there any financial goals you'd like to accomplish? (Select all that apply)",
  },
  mortgageInsurance: {
    component: <MortgageInsurance />,
    formGroup: "mortgageInsurance",
    icon: "leaked-money-bag",
    text: "Are you currently paying mortgage insurance?",
  },
  occupancyType: {
    component: <OccupancyType />,
    formGroup: "occupancyType",
    icon: "house",
    text: "What is the occupancy of the home?",
  },
  property: {
    component: <RefinanceProperty />,
    formGroup: "property",
    icon: "pin-on-map",
    text: "What is the address of the property?",
  },
  propertyType: {
    component: <PropertyType />,
    formGroup: "propertyType",
    icon: "find-property",
    text: "What type of home are you looking for?",
  },
  refinanceAmount: {
    component: <RefinanceAmount />,
    formGroup: "refinanceAmount",
    icon: "carry-money-bag",
    text: "What is your current mortgage balance?",
    subtextComponent: (
      <Box mt="1rem">
        <Text color="gray.500" textAlign="center">
          An estimate is OK. You can also find the amount on your most recent
          mortgage statement.
        </Text>
      </Box>
    ),
  },
  saverFinish: {
    component: <SaverFinish routeToGoTo="deals" />,
    formGroup: "saverFinish",
    icon: "celebration-confetti",
    text: "Your personalized deals are ready!",
    subtextComponent: (
      <Box mt="1rem">
        <Text color="gray.500" textAlign="center">
          See your rates and how much you'll save on mortgage costs.
        </Text>
      </Box>
    ),
  },
};
