import { Box, Button, ButtonGroup, Flex, Spacer, Text } from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";
import Card from "../components/Card";
import { useGeneralStore } from "../generalStore";
import SaverOnboardPurchase from "../onboard/SaverOnboardPurchase";
import SaverOnboardRefinance from "../onboard/SaverOnboardRefinance";
import DashboardTour from "./DashboardTour";

const WelcomeSaverBox = ({
  onOnboardModalOpen,
}: {
  onOnboardModalOpen?: () => void;
}) => {
  const currentUser = useGeneralStore((state) => state.currentUser);

  if (!currentUser || !currentUser.saver) {
    return null;
  }

  const { loanType } = currentUser.saver;

  return (
    <Card bgColor="orange.100" borderColor="orange.200">
      <Flex direction={{ base: "column", lg: "row" }}>
        <Box>
          <Text fontWeight="500" color="teal.600" fontSize="xl">
            Hello, {currentUser.firstName}!
          </Text>
          <Text fontWeight="500" fontSize="lg">
            Welcome to Safe Rate. Share your home goals to see your mortgage
            deals.
          </Text>
        </Box>
        <Spacer />
        <Box mt="0.5rem">
          {loanType === "purchase" ? (
            <SaverOnboardPurchase
              saver={currentUser.saver}
              buttonType="getStarted"
              onModalOpen={onOnboardModalOpen}
            />
          ) : loanType === "refinance" ? (
            <SaverOnboardRefinance
              saver={currentUser.saver}
              buttonType="getStarted"
              onModalOpen={onOnboardModalOpen}
            />
          ) : null}
        </Box>
      </Flex>
    </Card>
  );
};

export default WelcomeSaverBox;
