import { Box, Heading, Divider } from "@chakra-ui/react";
import React from "react";

type CardProps = {
  header?: string;
  bgColor?: string;
  borderColor?: string;
};

const Card: React.FC<CardProps> = ({
  children,
  header,
  bgColor,
  borderColor,
}) => {
  return (
    <Box
      bgColor={bgColor ? bgColor : "white"}
      border="1px"
      borderColor={borderColor ? borderColor : "gray.200"}
      m="0.5rem 0"
      p="1rem 1.5rem"
    >
      {header && (
        <Box mb="1rem">
          <Heading fontSize={["lg", "lg", "xl", "xl", "xl"]} mb="1rem">
            {header}
          </Heading>
          <Divider />
        </Box>
      )}
      <Box>{children}</Box>
    </Box>
  );
};

export default Card;
