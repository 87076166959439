import React, { useEffect } from "react";
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  InputGroup,
  Input,
  FormLabel,
  InputLeftElement,
  InputRightElement,
  Button,
  IconButton,
} from "@chakra-ui/react";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { SmallCloseIcon } from "@chakra-ui/icons";
import numeral from "numeral";

const defaultMaskOptions = {
  allowDecimal: false,
  allowNegative: false,
  allowLeadingZeroes: false,
  includeThousandsSeparator: true,
  integerLimit: 7, // limit length of integer numbers
  prefix: "",
  suffix: "",
  thousandsSeparatorSymbol: ",",
  // decimalSymbol: ".",
  // decimalLimit: 2, // how many digits allowed after the decimal
};

export default function DollarInputField(props) {
  const { helperText, label, max, name, ...rest } = props;
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();
  const currencyMask = createNumberMask(defaultMaskOptions);
  const dollarValue = useWatch({ name });

  useEffect(() => {
    if (max) {
      if (numeral(dollarValue).value()! > max) {
        setValue(name, numeral(max).format("$0,0"));
      }
    }
  }, [dollarValue, max, name, setValue]);

  return (
    <FormControl isInvalid={errors[name]}>
      <FormLabel>{label && label}</FormLabel>
      <InputGroup>
        <InputLeftElement pointerEvents="none" color="gray.400" children="$" />
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <MaskedInput
              {...field}
              mask={currencyMask}
              type="text"
              {...rest}
              render={(ref, props) => (
                <Input
                  ref={ref}
                  {...props}
                  paddingInlineEnd={"var(--chakra-space-7)"}
                  paddingInlineStart={"var(--chakra-space-7)"}
                  bgColor="white"
                />
              )}
            />
          )}
        />
        <InputRightElement width="3.0rem">
          <IconButton
            aria-label="Clear"
            icon={<SmallCloseIcon />}
            onClick={() => {
              setValue(name, "", { shouldDirty: true });
            }}
            size={"xs"}
          />
        </InputRightElement>
      </InputGroup>
      <FormErrorMessage>
        {errors[name] && errors[name].message}
      </FormErrorMessage>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}
