import React from "react";
import { Flex, Box, Button } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

const PageError = () => {
  const history = useHistory();
  return (
    <Flex
      minH="100vh"
      minWidth="100vw"
      alignItems="center"
      justifyContent="center"
    >
      <Box textAlign="center">
        <Flex fontSize="3xl" mt="1rem" fontWeight="bold" flexDirection="column">
          <Box>Ut oh! There was a problem fetching this data.</Box>
          <Box>
            <Button
              colorScheme="red"
              onClick={() => history.push("/")}
              mt="1rem"
            >
              Go to Home
            </Button>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};
export default PageError;
