import { Box, Flex, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import { SafeRateLogo, SafeRateLogoWithText } from "../custom-icons";
import { mediumWidth } from "../utility/constants";
import NavItems from "./NavItems";

const SideNav = () => {
  const [isLargerThanMedium] = useMediaQuery(`(min-width: ${mediumWidth})`);

  let boxSize = "3.5em";

  if (isLargerThanMedium) {
    boxSize = "7em";
  }

  return (
    <Box
      as="nav"
      pos="fixed"
      top="0"
      left="0"
      minHeight="100vh"
      zIndex="1"
      overflow="auto"
      width={{ base: "0px", sm: "64px", md: "237px" }}
      borderRightWidth={1}
      borderRightColor="gray.200"
      display={{ base: "none", sm: "block" }}
    >
      {isLargerThanMedium ? (
        <Box w="100%">
          <SafeRateLogoWithText boxSize={boxSize} />
        </Box>
      ) : (
        <Flex justifyContent="center" w="100%">
          <SafeRateLogoWithText boxSize={boxSize} />
        </Flex>
      )}

      <NavItems isSideNav={true} />
    </Box>
  );
};

export default SideNav;
