// eslint-disable-next-line import/no-anonymous-default-export
export default (initialObj: object, keysToKeep: string[]) => {
  const cloneObj = { ...initialObj };
  const keys = Object.keys(cloneObj);
  keys.forEach((key) => {
    if (!keysToKeep.includes(key)) {
      delete cloneObj[key];
    }
  });

  return cloneObj;
};
