import React from "react";
import RadioGroupField from "../components/form/RadioGroupField";

const MortgageInsurance = (props) => {
  return (
    <React.Fragment>
      <RadioGroupField
        name={props.model[props.formGroup].mortgageInsurance.name}
        values={[
          { value: "yes", label: "Yes" },
          { value: "no", label: "No" },
        ]}
      />
    </React.Fragment>
  );
};

export default MortgageInsurance;
