import React, { useEffect, useState } from "react";
import { HStack } from "@chakra-ui/react";
import { useFormContext, useWatch } from "react-hook-form";
import SelectField from "./SelectField";
import { SelectOptions } from "../../types/types";

function getMonths(isCurrentYearSelected = false): SelectOptions[] {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return monthNames.map((monthName, i) => ({
    label: monthName,
    value: (i + 1).toString(),
  }));
}

function getYears(endYear): SelectOptions[] {
  let startYear = new Date().getFullYear();
  const endDate = endYear;
  let years: number[] = [];
  while (startYear <= endDate) {
    years.push(startYear);
    startYear++;
  }
  return years.map((year) => ({
    value: year.toString(),
    label: year.toString(),
  }));
}

export default function MonthYearField(props) {
  const { autoFocusMonth, monthName, yearName } = props;
  const monthValue = useWatch({ name: monthName });
  const yearValue = useWatch({ name: yearName });
  const { setValue } = useFormContext();

  useEffect(() => {
    if (yearValue === new Date().getFullYear().toString()) {
      const currentMonth = new Date().getMonth() + 1;
      if (Number(monthValue) < currentMonth) {
        setValue(monthName, "");
      }
    }
  }, [monthName, monthValue, setValue, yearValue]);

  const yearValues = getYears(2050);

  let disableFirstXOptions;

  if (yearValue === new Date().getFullYear().toString()) {
    const currentMonth = new Date().getMonth();

    if (currentMonth !== 0) {
      disableFirstXOptions = currentMonth - 1;
    }
  }

  return (
    <HStack spacing="24px" w="100%">
      <SelectField
        autoFocus={autoFocusMonth}
        name={yearName}
        placeholder="Year"
        values={yearValues}
      />
      <SelectField
        disableFirstXOptions={disableFirstXOptions}
        name={monthName}
        placeholder="Month"
        values={getMonths()}
      />
    </HStack>
  );
}
