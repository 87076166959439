import { getOneUser_getOneUser_saver } from "../types/getOneUser";

// eslint-disable-next-line import/no-anonymous-default-export
export default (saver: getOneUser_getOneUser_saver | null | undefined) => {
  if (saver) {
    if (saver.loanType) {
      if (saver.loanType === "purchase") {
        if (saver.purchasePrice) {
          if (saver.downPayment) {
            return true;
          }
        }
      } else if (saver.loanType === "refinance") {
        if (saver.refinanceAmount) {
          if (saver.estimatedHomeValue) {
            return true;
          }
        }
      }
    }
  }

  return false;
};
