import { Box, Text } from "@chakra-ui/react";
import AnnualIncome from "../../onboard/AnnualIncome";
import CreditScoreCategory from "../../onboard/CreditScoreCategory";
import DownPayment from "../../onboard/DownPayment";
import EstimatedLoanDate from "../../onboard/EstimatedLoanDate";
import FinancialHelp from "../../onboard/FinancialHelp";
import FirstTimeBuyer from "../../onboard/FirstTimeBuyer";
import PropertyType from "../../onboard/PropertyType";
import PurchaseGeography from "../../onboard/PurchaseGeography";
import PurchasePrice from "../../onboard/PurchasePrice";
import SaverFinish from "../../onboard/SaverFinish";
import OccupancyType from "../OccupanyType";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  annualIncome: {
    component: <AnnualIncome />,
    formGroup: "annualIncome",
    icon: "bank-notes",
    text: "What is your annual income?",
    subtextComponent: (
      <Box mt="1rem">
        <Text color="gray.500" textAlign="center">
          This will help us pick the best loan for you.
        </Text>
      </Box>
    ),
  },
  creditCurrentCategory: {
    component: <CreditScoreCategory />,
    formGroup: "creditCurrentCategory",
    icon: "color-wheel",
    text: "What is your credit score?",
    /* subtextComponent: (
      <Box mt="1rem">
        <Text color="gray.500" textAlign="center">
          Having a good credit score makes buying a home easier, but don't worry
          if yours is less than perfect. We'll improve your score together and
          get you the right loan.
        </Text>
      </Box>
    ), */
  },
  downPayment: {
    component: <DownPayment />,
    formGroup: "downPayment",
    icon: "bank-notes",
    text: "How much down payment will you put towards your purchase?",
    subtextComponent: (
      <Box mt="1rem">
        <Text color="gray.500" textAlign="center">
          Minimum allowable down payment for our loan programs is 5%
        </Text>
      </Box>
    ),
  },
  estimatedLoanDate: {
    component: <EstimatedLoanDate />,
    formGroup: "estimatedLoanDate",
    icon: "work-calendar",
    text: "When would you like to complete your purchase?",
  },
  financialHelp: {
    component: <FinancialHelp />,
    formGroup: "financialHelp",
    icon: "money-plant",
    stepButtonNextText: "Finish",
    text: "Are there any financial goals you'd like to accomplish? (Select all that apply)",
  },
  firstTimeBuyer: {
    component: <FirstTimeBuyer />,
    formGroup: "firstTimeBuyer",
    icon: "star-rating-1",
    text: "Are you a first time buyer?",
  },
  occupancyType: {
    component: <OccupancyType />,
    formGroup: "occupancyType",
    icon: "house",
    text: "What will the home be used for?",
  },
  propertyType: {
    component: <PropertyType />,
    formGroup: "propertyType",
    icon: "find-property",
    text: "What type of home are you looking for?",
  },
  purchaseGeography: {
    component: <PurchaseGeography />,
    formGroup: "purchaseGeography",
    icon: "pin-on-map",
    text: "Where are you looking to buy?",
    subtextComponent: (
      <Box mt="1rem">
        <Text color="gray.500" textAlign="center">
          Enter a city, zip code, neighborhood, county or state. You can enter
          multiple locations.
        </Text>
      </Box>
    ),
  },
  purchasePrice: {
    component: <PurchasePrice />,
    formGroup: "purchasePrice",
    icon: "tags-dollar",
    text: "What is the average price of homes you're considering?",
  },
  saverFinish: {
    component: <SaverFinish routeToGoTo="deals" />,
    formGroup: "saverFinish",
    icon: "celebration-confetti",
    text: "Your personalized deals are ready!",
    subtextComponent: (
      <Box mt="1rem">
        <Text color="gray.500" textAlign="center">
          See your rates and how much you'll save on mortgage costs.
        </Text>
      </Box>
    ),
  },
};
