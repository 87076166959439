import React, { useState } from "react";
import { Flex, Image, Spinner, ImageProps, Box } from "@chakra-ui/react";
import { saferateCdn } from "../utility/constants";

interface SafeRateImageProps extends ImageProps {
  filename?: string; //example: picture.jpg
  folder?: string;
  fallbackFilename?: string;
  fallbackFolder?: string;
  align?: string;
  verticalAlign?: string;
}

const SafeRateImage: React.FC<SafeRateImageProps> = (props) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);
  const {
    fallbackFilename,
    fallbackFolder,
    folder,
    filename,
    align,
    verticalAlign,
    ...rest
  } = props;

  return (
    <Flex
      w="100%"
      h="100%"
      alignItems={props.align ? props.align : "center"}
      justifyContent={props.align ? props.align : "center"}
      verticalAlign={props.verticalAlign ? props.verticalAlign : "center"}
      minHeight={props.boxSize ? props.boxSize : undefined}
    >
      {!imageLoaded && !imageError && (
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      )}
      <Image
        {...rest}
        display={imageLoaded ? undefined : "none"}
        src={
          props.src
            ? props.src
            : `${saferateCdn}/${props.folder ? props.folder : "images"}/${
                props.filename
              }`
        }
        onLoad={() => setImageLoaded(true)}
        onError={() => setImageLoaded(true)}
        fallbackSrc={
          fallbackFilename
            ? `${saferateCdn}/${
                props.fallbackFolder ? props.fallbackFolder : "images"
              }/${props.fallbackFilename}`
            : `https://via.placeholder.com/${props.boxSize}/CBD5E0/FFFFFF?text=No+image`
        }
      />
    </Flex>
  );
};

export default SafeRateImage;
