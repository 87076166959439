import {
  Box,
  Text,
  Spacer,
  Button,
  Link,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Flex,
} from "@chakra-ui/react";
import StreamlineIcon from "../components/StreamlineIcon";
import { useGeneralStore } from "../generalStore";
import numeral from "numeral";

import React from "react";
import { saverChatCredits } from "../utility/constants";

import ChatBox from "./ChatBox";
import Card from "../components/Card";

const ChatBoxCard = ({ headerText = "Have questions? Schedule a chat." }) => {
  const currentUser = useGeneralStore((state) => state.currentUser);
  return (
    <Card>
      <Flex>
        <Box>
          <StreamlineIcon
            name="video-conference-imac"
            boxSize={{ base: "4rem", lg: "6rem" }}
            mr="1rem"
          />
        </Box>
        <Box>
          <Text fontSize={{ base: "md", lg: "lg" }} fontWeight="500" as="span">
            {headerText}
          </Text>

          <ChatBox />
        </Box>
      </Flex>
    </Card>
  );
};

export default ChatBoxCard;
