import numeral from "numeral";

// eslint-disable-next-line import/no-anonymous-default-export
export default (v: number | null | undefined | string): string => {
  if (v === null || v === undefined || v === "") {
    return "";
  }

  const returnValue = numeral(v).format("$0,0");

  return returnValue;
};
