import React from "react";
import GeographySearchField from "../components/form/GeographySearchField";
import TextareaField from "../components/form/TextareaField";

const PurchaseGeography = (props) => {
  return (
    <React.Fragment>
      <GeographySearchField
        allowMultiple
        name={props.model[props.formGroup].purchaseGeography.name}
        placeholder={props.model[props.formGroup].purchaseGeography.placeholder}
        searchAddress={false}
        searchCity
        searchCounty
        searchNeighborhood
        searchPostcode
        searchState
      />
    </React.Fragment>
  );
};

export default PurchaseGeography;
