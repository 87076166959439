import React from "react";
import { HStack } from "@chakra-ui/react";
import MonthYearField from "../components/form/MonthYearField";

const EstimatedLoanDate = (props) => {
  return (
    <React.Fragment>
      <HStack>
        <MonthYearField
          autoFocusMonth={
            props.autoFocus !== undefined ? props.autoFocus : true
          }
          monthName={props.model[props.formGroup].estimatedLoanDateMonth.name}
          yearName={props.model[props.formGroup].estimatedLoanDateYear.name}
        />
      </HStack>
    </React.Fragment>
  );
};

export default EstimatedLoanDate;
