import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Container,
  HStack,
  Link,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useQuery } from "@apollo/client";
import React from "react";
import { useParams } from "react-router";
import Card from "../components/Card";
import PageError from "../components/PageError";
import PageLoader from "../components/PageLoader";
import SafeRateImage from "../components/SafeRateImage";
import StreamlineIcon from "../components/StreamlineIcon";
import { SafeRateLogo } from "../custom-icons";
import { GET_SAVER_BY_REFERRER_CODE } from "../graphql/savers/constants";
import {
  getSaverByReferrerCode,
  getSaverByReferrerCodeVariables,
} from "../types/getSaverByReferrerCode";
import { saverJoinCredits, saverMaxCredits } from "../utility/constants";
import numeral from "numeral";

const SaverCobrand = () => {
  const { loginWithRedirect } = useAuth0();
  const { referrerCode } = useParams<{ referrerCode: string }>();
  const { data, error, loading } = useQuery<
    getSaverByReferrerCode,
    getSaverByReferrerCodeVariables
  >(GET_SAVER_BY_REFERRER_CODE, {
    variables: {
      referrerCode,
    },
  });

  if (loading) {
    return <PageLoader />;
  }

  if (error) {
    return <PageError />;
  }

  if (!data || !data.getSaverByReferrerCode) {
    return <Box>Saver does not exist</Box>;
  }

  const saverData = data.getSaverByReferrerCode;

  return (
    <Box w="100%" bgColor="white" h="100vh">
      <Container maxW="container.lg" pt="4rem">
        <Card>
          <SimpleGrid columns={[1, 1, 1, 2, 2]} p="1rem">
            <HStack>
              <Box mr="1rem">
                {saverData.user && saverData.user.picture ? (
                  <SafeRateImage
                    mb="1rem"
                    boxSize={"7rem"}
                    borderRadius="full"
                    objectFit="cover"
                    src={saverData.user?.picture}
                    alt={
                      saverData.user?.firstName && saverData.user?.lastName
                        ? `${saverData.user.firstName} ${saverData.user.lastName}`
                        : "User Profile Pic"
                    }
                  />
                ) : (
                  <StreamlineIcon
                    name="user-icon-single-neutral"
                    color="gray.300"
                    boxSize={"7rem"}
                    mb="1rem"
                    objectFit="cover"
                    borderRadius="full"
                  />
                )}
              </Box>
              <Box>
                {saverData?.user?.name && (
                  <Text fontWeight="bold">{saverData.user?.name}</Text>
                )}

                <Text fontSize="sm" color="gray.600">
                  Safe Rate Member
                </Text>
              </Box>
            </HStack>
            <HStack>
              <Box>
                <SafeRateLogo boxSize={"10rem"} />
              </Box>
              <Box>
                <Text fontWeight="bold">Safe Rate Lending</Text>
                <Text fontSize="sm" color="gray.600">
                  NMLS #1590949
                </Text>
                <Text fontSize="sm" color="gray.600">
                  515 N State St, 13th Floor
                  <br /> Chicago, IL 60654
                </Text>
                <Button
                  fontSize="sm"
                  colorScheme="purple"
                  variant="link"
                  fontWeight="400"
                  as="a"
                  href={`mailto:team@saferate.com`}
                >
                  team@saferate.com
                </Button>
                <Text fontSize="sm" color="gray.600">
                  (312) 248 - 0234
                </Text>
              </Box>
            </HStack>
          </SimpleGrid>
          <Box p="1rem">
            <Text fontSize="lg" mb="1rem">
              {saverData?.user?.name} is a current Safe Rate member and wants to
              share the benefits of being a member with you.
            </Text>
            <Text fontSize="lg" mb="1rem">
              By joining Safe Rate you will receive{" "}
              <b>{numeral(saverJoinCredits).format("$0,0")}</b> to lower your
              mortgage costs now with a chance to earn even more.
            </Text>
            <Text fontSize="lg">Benefits of being a member include:</Text>

            <Stack mt="1rem">
              <HStack p="0.5rem">
                <StreamlineIcon
                  name="money-bag-dollar-icon-light"
                  boxSize="2rem"
                  mr="1rem"
                  color="purple.700"
                />
                <Text fontSize="lg">
                  Up to {numeral(saverMaxCredits).format("$0,0")} in credits to
                  lower your interest rate, use towards closing costs or redeem
                  for gift cards if you choose Safe Rate as your lender
                </Text>
              </HStack>
              <HStack p="0.5rem">
                <StreamlineIcon
                  name="man-headphones-icon-light"
                  boxSize="2rem"
                  mr="1rem"
                  color="purple.700"
                />

                <Text fontSize="lg">
                  Free, one-on-one mortgage counseling sessions to ask your
                  mortgage-related questions
                </Text>
              </HStack>
              <HStack p="0.5rem">
                <StreamlineIcon
                  name="videos-icon-light"
                  boxSize="2rem"
                  mr="1rem"
                  color="purple.700"
                />
                <Text fontSize="lg">
                  Educational videos about home financing and home ownership
                </Text>
              </HStack>
              <HStack p="0.5rem">
                <StreamlineIcon
                  name="improve-score-icon"
                  boxSize="2rem"
                  mr="1rem"
                  color="purple.700"
                />
                <Text fontSize="lg">
                  Personalized purchase readiness analysis using your credit
                  score, personal savings and debt
                </Text>
              </HStack>
              <HStack p="0.55rem">
                <StreamlineIcon
                  name="add-square-icon-light"
                  boxSize="2rem"
                  mr="1rem"
                  color="purple.700"
                />
                <Text fontSize="lg">
                  Additional $500 in credits for inviting your real estate agent
                  and employer to join the Safe Rate community
                </Text>
              </HStack>

              <Box p="0.5rem" fontSize="lg">
                <Text fontSize="lg">
                  You can take advantage of these benefits at <b>no cost</b> and
                  are under no obligation to use Safe Rate as your lender.
                </Text>
                <Box mt="1rem">
                  <Text as="span">Visit</Text>&nbsp;
                  <Link
                    href="https://saferate.com"
                    target="_blank"
                    color="purple.600"
                    fontWeight="bold"
                  >
                    Safe Rate
                  </Link>
                  &nbsp;
                  <Text as="span">to learn more.</Text>
                </Box>
              </Box>
            </Stack>
          </Box>
          <Box p="0.5rem" mt="1rem" mb="4rem">
            <Button
              colorScheme="purple"
              size="lg"
              onClick={() => {
                loginWithRedirect({
                  appState: {
                    targetUrl: `/saver-referral/${saverData.user.referrerCode}`,
                  },
                  screen_hint: "signUp",
                });
              }}
            >
              Join Safe Rate now and get{" "}
              {numeral(saverJoinCredits).format("$0,0")} towards your mortgage
              costs
            </Button>
          </Box>
        </Card>
      </Container>
    </Box>
  );
};

export default SaverCobrand;
