import * as Yup from "yup";
import {
  capitalize,
  floatToDollarCentsString,
  floatToInterestRateString,
  floatToThousandsString,
  floatToWholePercentString,
  transformStringToNumber,
} from "../../utility";
import percentWholeStringToFloat from "../../utility/percentWholeStringToFloat";

const required = "Required";

// eslint-disable-next-line import/no-anonymous-default-export
export const getRatePublicModel = {
  annualIncome: {
    defaultValue: undefined,
    label: "Annual income",
    name: "annualIncome",
    serverTransform: floatToThousandsString,
    validation: Yup.number().transform(transformStringToNumber),
  },
  creditCurrentCategory: {
    defaultValue: "exceptional",
    label: "Credit score",
    name: "creditCurrentCategory",
    validation: Yup.string().trim().required(required),
  },
  downPayment: {
    defaultValue: 80000,
    label: "Down payment",
    name: "downPayment",
    serverTransform: floatToThousandsString,
    validation: Yup.number()
      .transform(transformStringToNumber)
      .required(required),
  },
  downPaymentPercent: {
    defaultValue: 0.2,
    name: "downPaymentPercent",
    serverTransform: floatToWholePercentString,
    validation: Yup.number()
      .transform(percentWholeStringToFloat)
      .min(0.03, "3 Minimum%")
      .max(1, "Maximum allowable down payment is 100%")
      .required(required),
  },
  estimatedHomeValue: {
    defaultValue: 400000,
    label: "Estimated home value",
    name: "estimatedHomeValue",
    serverTransform: floatToThousandsString,
    validation: Yup.number()
      .transform(transformStringToNumber)
      .min(25000, "Min value is $25k")
      .max(3000000, "Max value is $3mil")
      .required(required),
  },
  loanType: {
    defaultValue: "purchase",
    label: "Mortgage type",
    name: "loanType",
    validation: Yup.string().trim().required(required),
  },
  occupancyType: {
    defaultValue: "residence",
    label: "Property use",
    name: "occupancyType",
    validation: Yup.string().trim().required(required),
  },
  propertyType: {
    defaultValue: "singleFamilyResidence",
    label: "Property type",
    name: "propertyType",
    validation: Yup.string().trim().required(required),
  },
  purchasePrice: {
    defaultValue: 400000,
    label: "Purchase price",
    name: "purchasePrice",
    serverTransform: floatToThousandsString,
    validation: Yup.number()
      .transform(transformStringToNumber)
      .min(10000, "Minimum acceptable home value is $10,000")
      .max(3000000, "Maximum acceptable home value is $3,000,000")
      .required(required),
  },
  rateType: {
    defaultValue: "Fixed30Year",
    label: "Loan type",
    name: "rateType",
    validation: Yup.string().trim().required(required),
  },
  refinanceAmount: {
    defaultValue: 200000,
    label: "Mortgage balance",
    name: "refinanceAmount",
    serverTransform: floatToThousandsString,
    validation: Yup.number()
      .transform(transformStringToNumber)
      .min(20000, "Min value is $20k")
      .max(1500000, "Max value is $1.5mil")
      .required(required),
  },
};

export const getDealSignupModel = {
  email: {
    defaultValue: "",
    label: "E-mail",
    name: "email",
    validation: Yup.string()
      .trim()
      .email("Must be a valid e-mail address")
      .required("E-mail is required"),
  },
  firstName: {
    defaultValue: "",
    label: "First name",
    name: "firstName",
    validation: Yup.string()
      .trim()
      .transform(capitalize)
      .required("First name is required"),
  },
  lastName: {
    defaultValue: "",
    label: "Last name",
    name: "lastName",
    validation: Yup.string()
      .trim()
      .transform(capitalize)
      .required("Last name is required"),
  },
};
