import { Box, Button } from "@chakra-ui/react";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

const SaverFinish = ({
  routeToGoTo,
}: {
  routeToGoTo: "deals" | "dashboard";
}) => {
  const location = useLocation();
  const history = useHistory();
  return (
    <Box mt="2rem">
      <Button
        colorScheme="purple"
        onClick={() => {
          history.push(`/${routeToGoTo}`);
          if (location.pathname.includes(routeToGoTo)) {
            history.go(0);
          }
        }}
      >
        {(() => {
          if (routeToGoTo === "deals") {
            return "See my deals";
          } else if (routeToGoTo === "dashboard") {
            return "Go to dashboard";
          }
        })()}
      </Button>
    </Box>
  );
};

export default SaverFinish;
