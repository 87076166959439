import React from "react";
import GeographySearchField from "../components/form/GeographySearchField";
import InputField from "../components/form/InputField";

const RefinanceProperty = (props) => {
  return (
    <React.Fragment>
      <GeographySearchField
        maxSelect={1}
        name={props.model[props.formGroup].property.name}
        placeholder={props.model[props.formGroup].property.placeholder}
        searchCity={false}
        searchCounty={false}
        searchNeighborhood={false}
        searchPostcode={false}
        searchState={false}
      />
      <InputField
        mt="0.5rem"
        name={props.model[props.formGroup].address2.name}
        placeholder={props.model[props.formGroup].address2.placeholder}
      />
    </React.Fragment>
  );
};

export default RefinanceProperty;
