import { getOneUser_getOneUser_saver_saverCredit } from "../types/getOneUser";
import { SaverCreditFull } from "../types/SaverCreditFull";

// eslint-disable-next-line import/no-anonymous-default-export
export default (saverCredit: any): boolean => {
  const { closed, creditType, verified } = saverCredit;

  if (creditType === "join") {
    return true;
  } else if (creditType === "video") {
    return true;
  } else if (creditType === "agent" && verified) {
    return true;
  } else if (creditType === "employer") {
    return true;
  } else if (creditType === "referral" && closed) {
    return true;
  } else if (creditType === "chat" && verified) {
    return true;
  }

  return false;
};
