// eslint-disable-next-line import/no-anonymous-default-export
export default function (uri, options) {
  return new Promise((resolve, reject) => {
    fetch(uri, options).then((response) => {
      if (response.status === 200) {
        return resolve(response);
      } else {
        return reject(response.status);
      }
    });
  });
}
