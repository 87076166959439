import _ from "lodash";

// eslint-disable-next-line import/no-anonymous-default-export
export default (obj: any, arr: string[]) => {
  if (!_.isObject(obj) || arr.length < 1) {
    return false;
  }

  let hasAllValues = false;

  for (let i = 0; i < arr.length; i++) {
    const key = arr[i];

    if (obj[key]) {
      if (_.isArray(obj[key])) {
        if (!obj[key].length) {
          hasAllValues = false;
          break;
        }
      }
    } else {
      break;
    }

    if (i + 1 === arr.length) {
      hasAllValues = true;
    }
  }

  return hasAllValues;
};
