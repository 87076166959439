import React from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useMediaQuery,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { SafeRateLogo, SafeRateLogoWithText } from "../custom-icons";
import { useAuth0 } from "@auth0/auth0-react";
import { NavbarHomeMobileMenu } from "./NavbarHomeMobileMenu";
import { ChevronDownIcon } from "@chakra-ui/icons";

const NavbarHome = () => {
  const history = useHistory();
  const { loginWithRedirect } = useAuth0();
  const [isSmallerThan920px] = useMediaQuery(`(max-width: 920px)`);

  return (
    <Box mb={{ base: "0rem", lg: "6rem" }}>
      <Box
        as="nav"
        h={20}
        position={isSmallerThan920px ? undefined : "fixed"}
        top={isSmallerThan920px ? undefined : 0}
        width="100%"
        bgColor={"white"}
        zIndex={isSmallerThan920px ? undefined : "sticky"}
      >
        <Container maxW="container.xl">
          <Flex justifyContent="space-between">
            <Box
              cursor="pointer"
              onClick={() => {
                history.push("/");
              }}
            >
              <Box>
                <SafeRateLogoWithText boxSize="10rem" />
              </Box>
            </Box>
            {isSmallerThan920px ? (
              <NavbarHomeMobileMenu />
            ) : (
              <Flex alignItems="center">
                <ButtonGroup spacing="10">
                  <Button
                    variant="link"
                    fontWeight="500"
                    color="black"
                    size="lg"
                    onClick={() => {
                      history.push("/rates");
                    }}
                  >
                    Rates
                  </Button>
                  <Button
                    variant="link"
                    fontWeight="500"
                    color="black"
                    size="lg"
                    onClick={() => {
                      history.push("/learn");
                    }}
                  >
                    Learn
                  </Button>

                  <Button
                    variant="link"
                    fontWeight="500"
                    color="black"
                    size="lg"
                    onClick={() => {
                      history.push("/about");
                    }}
                  >
                    About
                  </Button>
                  <Button
                    variant="link"
                    fontWeight="500"
                    color="black"
                    size="lg"
                    onClick={() => {
                      window.open("https://learn.saferate.com/");
                    }}
                  >
                    Blog
                  </Button>
                  <Menu>
                    <MenuButton
                      as={Button}
                      variant="link"
                      fontWeight="500"
                      color="black"
                      size="lg"
                      rightIcon={<ChevronDownIcon />}
                    >
                      Agents & Employers
                    </MenuButton>
                    <MenuList>
                      <MenuItem
                        onClick={() => {
                          history.push("/agents");
                        }}
                      >
                        Real estate agents
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          history.push("/employers");
                        }}
                      >
                        Employers
                      </MenuItem>
                    </MenuList>
                  </Menu>

                  <ButtonGroup>
                    <Button
                      colorScheme="teal"
                      onClick={() =>
                        loginWithRedirect({ screen_hint: "signUp" })
                      }
                    >
                      Sign up
                    </Button>
                    <Button
                      variant="outline"
                      colorScheme="teal"
                      onClick={() => loginWithRedirect()}
                    >
                      Log in
                    </Button>
                  </ButtonGroup>
                </ButtonGroup>
              </Flex>
            )}
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};

export default NavbarHome;
