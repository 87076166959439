import React from "react";
import {
  FormControl,
  Input,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";

export default function InputField(props) {
  const { helperText, isRequired, label, name, ...rest } = props;
  const {
    formState: { errors },
    register,
  } = useFormContext();

  return (
    <FormControl isInvalid={errors[name]} isRequired={isRequired}>
      {label && <FormLabel>{label}</FormLabel>}
      <Input type="text" {...register(name)} {...rest} />
      <FormErrorMessage>
        {errors[name] && errors[name].message}
      </FormErrorMessage>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}
