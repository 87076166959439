import { useLazyQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Container } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { GET_BORROWER_ACCOUNT_SUMMARY } from "../graphql/servicing/constants";
import LoansNewBorrower from "../loans/LoansNewBorrower";
import { useLoansStore } from "../loansStore";
import { graphQLContext } from "../types/types";
import { Loan } from "../utility/loans/loan";
import PageError from "./PageError";
import PageLoader from "./PageLoader";

interface IServicingRouteOptions {
  component: React.FC;
  path: string;
  exact: boolean;
}

type ServicingRouteProps = IServicingRouteOptions;

const ServicingRoute = ({
  component,
  path,
  exact,
  ...rest
}: ServicingRouteProps) => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const [getBorrowerAccountSummary, { loading, data, error }] = useLazyQuery(
    GET_BORROWER_ACCOUNT_SUMMARY,
    {
      context: { clientName: graphQLContext.SERVICING },
    }
  );
  const setLoans = useLoansStore((state) => state.setLoans);
  const loans = useLoansStore((state) => state.loans);

  useEffect(() => {
    if (isAuthenticated && !loans) {
      getBorrowerAccountSummary();
    }
  }, [isAuthenticated, getBorrowerAccountSummary, loans]);

  useEffect(() => {
    if (
      data &&
      data.getBorrowerAccountSummary &&
      data.getBorrowerAccountSummary.loans
    ) {
      setLoans(data.getBorrowerAccountSummary.loans as Loan[]);
    }
  }, [data, setLoans]);

  useEffect(() => {
    const callAsync = async () => {
      if (isLoading || !loginWithRedirect) {
        return;
      }

      if (!isAuthenticated) {
        let otherParams: any = rest;
        let targetUrl = path;
        if (otherParams) {
          if (otherParams.computedMatch) {
            if (otherParams.computedMatch.url) {
              targetUrl = otherParams.computedMatch.url;
            }
          } else if (otherParams.location) {
            if (otherParams.location.pathname) {
              targetUrl = otherParams.location.pathname;
            }
          }
        }

        await loginWithRedirect({
          redirect_uri: "",
          appState: { targetUrl },
        });
      }
    };

    callAsync();
  }, [isAuthenticated, isLoading, loginWithRedirect, path, rest]);

  if (isLoading || loading) {
    return <PageLoader />;
  }

  if (!isAuthenticated) return null;

  if (!loans || loans.length === 0) {
    return (
      <Box w="100%">
        <Container maxW="container.xl">
          <LoansNewBorrower />
        </Container>
      </Box>
    );
  }

  if (error) {
    console.error(error);
    return <PageError />;
  }

  return <Route path={path} component={component} {...rest} />;
};

export default ServicingRoute;
