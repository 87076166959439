import { Box, Flex, Heading } from "@chakra-ui/react";
import React from "react";
import StreamlineIcon from "../components/StreamlineIcon";

const OnboardWrapper = ({ children, icon, subtextComponent, text }) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      p="2rem 2rem"
      minW={"20rem"}
    >
      {icon && (
        <Box mb="1rem">
          <StreamlineIcon name={icon} boxSize="140px" />
        </Box>
      )}
      {text && (
        <Heading as={"h1"} fontSize="xl" fontWeight="500">
          {text}
        </Heading>
      )}
      {subtextComponent ? subtextComponent : null}
      <Box mt="1rem" align="center" w="100%">
        {children}
      </Box>
    </Flex>
  );
};

export default OnboardWrapper;
