import React from "react";
import {
  FormControl,
  Input,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Switch,
  SwitchProps,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";

interface SwitchFieldProps extends SwitchProps {
  helperText?: string;
  isRequired?: boolean;
  label?: string;
  name: string;
  formControlProps?: any;
  formLabelProps?: any;
}

const SwitchField: React.FC<SwitchFieldProps> = (props) => {
  const {
    helperText,
    isRequired,
    label,
    name,
    formControlProps,
    formLabelProps,
    ...rest
  } = props;

  const {
    formState: { errors },
    register,
  } = useFormContext();

  return (
    <FormControl
      alignItems={"center"}
      display={"flex"}
      isInvalid={errors[name]}
      isRequired={isRequired}
      p="0.25rem 0"
      {...formControlProps}
    >
      <Switch {...register(name)} {...rest} mr="1rem" />
      {label && <FormLabel {...formLabelProps}>{label}</FormLabel>}
      <FormErrorMessage>
        {errors[name] && errors[name].message}
      </FormErrorMessage>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default SwitchField;
