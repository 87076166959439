import moment from "moment";

// eslint-disable-next-line import/no-anonymous-default-export
export default (dateToPretty, format = "MMM D, YYYY") => {
  return (
    moment(dateToPretty, "YYYY-MM-DD HH:mm")
      // .utc()
      .format(format)
  );
};
