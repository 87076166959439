import React from "react";
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  RadioGroup,
  Stack,
  Radio,
  FormLabel,
} from "@chakra-ui/react";
import { useFormContext, useWatch } from "react-hook-form";

export default function RadioGroupField(props) {
  const {
    helperText,
    isRequired,
    label,
    name,
    stackDirection = "column",
    values,
    ...rest
  } = props;
  const {
    formState: { errors },
    getValues,
    register,
  } = useFormContext();
  const currentValue = useWatch({ name });

  return (
    <FormControl isInvalid={errors[name]} isRequired={isRequired}>
      <FormLabel>{label && label}</FormLabel>
      <RadioGroup defaultValue={getValues(name)} {...rest}>
        <Stack direction={stackDirection}>
          {values.map(({ value, label, descriptorText }, i) => {
            return (
              <Radio
                value={value}
                key={i}
                {...register(name)}
                autoFocus={i === 0}
                style={{ textAlign: "left" }}
              >
                {label}
                {currentValue === value && descriptorText
                  ? ` - ${descriptorText}`
                  : ""}
              </Radio>
            );
          })}
        </Stack>
      </RadioGroup>
      <FormErrorMessage>
        {errors[name] && errors[name].message}
      </FormErrorMessage>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}
