import {
  createStandaloneToast,
  theme,
  UseToastOptions,
} from "@chakra-ui/react";

// eslint-disable-next-line import/no-anonymous-default-export
export default (options: UseToastOptions | undefined) => {
  const toast = createStandaloneToast({
    theme: {
      ...theme,
      fonts: {
        body: "tt_norms",
      },
    },
  });

  toast(options);
};
