import React, { Fragment } from "react";
import ApplicationTipsBox from "./ApplicationTipsBox";
import ApplyBox from "./ApplyBox";
import PreapprovalBox from "./PreapprovalBox";

const LoansNewBorrower = () => {
  return (
    <Fragment>
      <ApplyBox />

      <ApplicationTipsBox />
    </Fragment>
  );
};

export default LoansNewBorrower;
