import React, { useEffect, useState } from "react";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import getLinks from "./apollo/getLinks";
import PageLoader from "./components/PageLoader";
import Routes from "./Routes";
import { graphQLContext } from "./types/types";
import FontFaceObserver from "fontfaceobserver";
import ErrorBoundaryPage from "./components/ErrorBoundaryPage";

export const cache = new InMemoryCache({
  typePolicies: {
    Geography: {
      keyFields: ["value"],
    },
    Saver: {
      fields: {
        property: {
          merge: false,
        },
        purchaseGeography: {
          merge: false,
        },
      },
    },
  },
});

export let client: ApolloClient<NormalizedCacheObject> | null = null;

function App() {
  const [isFontsLoadComplete, setIsFontsLoadComplete] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [idToken, setIdToken] = useState("");
  const {
    getAccessTokenSilently,
    getIdTokenClaims,
    isAuthenticated,
    isLoading,
    user,
  } = useAuth0();

  useEffect(() => {
    const callAsync = async () => {
      const font = new FontFaceObserver("tt_norms");
      try {
        await font.load();
      } catch (error) {
        console.error(error);
      }
      setIsFontsLoadComplete(true);
    };

    callAsync();
  }, []);

  useEffect(() => {
    if (!isAuthenticated) return;
    const callAsync = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        // alert("Access Token in Console");
        setAccessToken(accessToken);
      } catch (error: any) {
        throw new Error(error);
      }
      try {
        const claims = await getIdTokenClaims();

        const idToken = claims.__raw;
        setIdToken(idToken);
      } catch (error: any) {
        throw new Error(error);
      }
    };

    callAsync();
  }, [
    getAccessTokenSilently,
    getIdTokenClaims,
    isAuthenticated,
    setAccessToken,
    setIdToken,
  ]);

  if (isLoading || !isFontsLoadComplete) {
    return <PageLoader />;
  }

  let link: ApolloLink | undefined;

  const { servicingLink, saversLink } = getLinks(accessToken, idToken);

  if (saversLink && servicingLink) {
    link = ApolloLink.split(
      (operation) =>
        operation.getContext().clientName === graphQLContext.SERVICING,
      servicingLink,
      saversLink
    );
  } else if (saversLink) {
    link = saversLink;
  }

  client = new ApolloClient({
    cache,
    defaultOptions: {
      // mutate: {
      //   errorPolicy: "all",
      // },
      // query: {
      //   errorPolicy: "all",
      // },
      // watchQuery: {
      //   errorPolicy: "all",
      //   // fetchPolicy: "cache-and-network",
      // },
    },
    link,
    connectToDevTools: true,
  });

  if (!client) return null;

  return (
    <ApolloProvider client={client}>
      <Routes userHasFullyLoggedIn={accessToken && idToken ? true : false} />
    </ApolloProvider>
  );
}

export default App;
