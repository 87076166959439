import React from "react";
import DollarInputField from "../components/form/DollarInputField";

const PurchasePrice = (props) => {
  return (
    <React.Fragment>
      <DollarInputField
        name={props.model[props.formGroup].purchasePrice.name}
        autoFocus={props.autoFocus !== undefined ? props.autoFocus : true}
      />
    </React.Fragment>
  );
};

export default PurchasePrice;
