import {
  Box,
  Text,
  Spacer,
  Button,
  Link,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  Flex,
} from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import StreamlineIcon from "../components/StreamlineIcon";
import Card from "../components/Card";
import { getOneUser_getOneUser_saver } from "../types/getOneUser";

const getHeaderText = (
  status: string | null | undefined,
  loanType: string | null | undefined
) => {
  if (status && loanType) {
    if (loanType === "purchase") {
      if (status === "qualifying") {
        return "Ready for a mortgage or pre-approval?";
      } else if (status === "preapproved") {
        return "Update your application";
      } else if (status === "application") {
        return "Update your application";
      } else if (status === "underwriting") {
        return "Add your supporting documents";
      } else {
        return "Ready for a mortgage or pre-approval?";
      }
    } else if (loanType === "refinance") {
      if (status === "qualifying") {
        return "Ready for a mortgage?";
      } else if (status === "application") {
        return "Update your application";
      } else if (status === "underwriting") {
        return "Add your supporting documents";
      } else {
        return "Ready for a mortgage or pre-approval?";
      }
    } else return "Ready for a mortgage or pre-approval?";
  }
  return "Ready for a mortgage or pre-approval?";
};

const getButtonText = (
  status: string | null | undefined,
  loanType: string | null | undefined
) => {
  if (status && loanType) {
    if (loanType === "purchase") {
      if (status === "qualifying") {
        return "Apply now";
      } else if (status === "preapproved") {
        return "Go to application";
      } else if (status === "application") {
        return "Go to application";
      } else if (status === "underwriting") {
        return "Go to application";
      }
    } else if (loanType === "refinance") {
      if (status === "qualifying") {
        return "Apply now";
      } else if (status === "application") {
        return "Go to application";
      } else if (status === "underwriting") {
        return "Go to application";
      }
    }
  }
  return "Apply now";
};

const getModalText = (status: string | null | undefined) => {
  if (status === "qualifying") {
    return (
      <Text>
        You will now be redirected to Safe Rate's secure application website.
        Please note that <b>you will create a separate account</b> for the
        application website in order to best protect your sensitive information.
      </Text>
    );
  } else if (status === "application" || status === "underwriting") {
    return (
      <Text>
        You will now be redirected to Safe Rate's secure application website in
        order to best protect your sensitive information.
      </Text>
    );
  }

  return (
    <Text>
      You will now be redirected to Safe Rate's secure application website.
      Please note that if you don't already have an account, you will create a
      separate account for the application website in order to best protect your
      sensitive information.
    </Text>
  );
};

const ApplyBox = ({
  saver,
}: {
  saver?: getOneUser_getOneUser_saver | null;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Card>
      <Flex alignItems="top">
        <Box>
          <StreamlineIcon
            name="house-slanted-roof"
            boxSize={{ base: "4rem", lg: "6rem" }}
            mr="1rem"
          />
        </Box>
        <Box>
          <Box fontSize={{ base: "md", lg: "lg" }} fontWeight="500">
            <Text>{getHeaderText(saver?.status, saver?.loanType)}</Text>
          </Box>
          <Button onClick={onOpen} colorScheme="purple" mt="1rem">
            {getButtonText(saver?.status, saver?.loanType)}
          </Button>
        </Box>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Head to the application website?</ModalHeader>
            <ModalCloseButton />
            <ModalBody>{getModalText(saver?.status)}</ModalBody>
            <ModalFooter>
              <Link href="http://saferate.my1003app.com" ml="1rem" isExternal>
                <Button colorScheme="purple" mr={3} onClick={onClose}>
                  Continue
                </Button>
              </Link>
              <Button variant="ghost" onClick={onClose}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    </Card>
  );
};

export default ApplyBox;
