import React from "react";
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Image,
  Flex,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { useGeneralStore } from "../generalStore";
import { saferateCdn } from "../utility/constants";
import { checkIfImageExists } from "../utility";

const NavbarUserDropdown = () => {
  const currentUser = useGeneralStore((state) => state.currentUser);
  const client = useApolloClient();
  const history = useHistory();
  const { logout, user } = useAuth0();

  const isEmployer = Boolean(currentUser?.employer);
  const isSaver = Boolean(currentUser?.saver);

  let imgSrc = currentUser?.picture;

  if (
    isEmployer &&
    currentUser &&
    currentUser.employer &&
    currentUser.employer.id
  ) {
    const employerPhotoUrl = `${saferateCdn}/employer/${currentUser.employer.id}.jpg`;

    const employerPhotoExists = checkIfImageExists(employerPhotoUrl);

    if (employerPhotoExists) {
      imgSrc = employerPhotoUrl;
    } else {
      imgSrc = undefined;
    }
  }

  // if (!currentUser) return null;

  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon />}
        // isDisabled={!currentUser}
        // isLoading={!currentUser}
      >
        <Flex justifyContent="center" alignItems="center">
          {imgSrc && (
            <Image
              src={imgSrc}
              borderRadius="full"
              boxSize="25px"
              mr="0.25rem"
            />
          )}
          <Text as="span" display={{ base: "none", lg: "inline-block" }}>
            {(() => {
              if (currentUser?.name) {
                return currentUser.name;
              } else if (currentUser?.email) {
                return currentUser.email;
              } else if (currentUser?.firstName && currentUser.lastName) {
                return `${currentUser.firstName} ${currentUser.lastName}`;
              } else if (user?.name) {
                return user.name;
              } else {
                return "";
              }
            })()}
          </Text>
        </Flex>
      </MenuButton>
      <MenuList>
        {isSaver && (
          <MenuItem onClick={() => history.push("/settings")}>
            Settings
          </MenuItem>
        )}
        <MenuItem
          onClick={async () => {
            client.resetStore();
            logout({ returnTo: window.location.origin });
          }}
        >
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default NavbarUserDropdown;
