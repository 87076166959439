import { Flex, Box, Button, Link, Text } from "@chakra-ui/react";
import React from "react";
import { SafeRateLogo } from "../custom-icons";

const ErrorBoundaryPage = () => {
  return (
    <Flex
      minH="100vh"
      minWidth="100vw"
      alignItems="center"
      justifyContent="center"
      bg="var(--chakra-colors-gray-100)"
    >
      <Box textAlign="center">
        <SafeRateLogo boxSize="14rem" />
        <Flex flexDirection="column">
          <Box fontSize="3xl" fontWeight="bold">
            Sorry! There was a problem with our application
          </Box>
          <Text>
            E-mail us at{" "}
            <Link
              color="purple.500"
              fontWeight="600"
              as="a"
              href={`mailto:team@saferate.com`}
            >
              team@saferate.com
            </Link>{" "}
            for assistance
          </Text>
          {/* <Box>



            <Box as="span">
              <Link
                chakra
                color="purple.500"
                fontWeight="600"
                as="a"
                href={`mailto:team@saferate.com`}
                display="block"
              >
                team@saferate.com
              </Link>{" "}
              for assistance.
            </Box>
          </Box> */}
          <Box>
            <Button
              colorScheme="purple"
              onClick={() => (window.location.href = "/")}
              mt="1rem"
            >
              Go to Home Page
            </Button>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

export default ErrorBoundaryPage;
