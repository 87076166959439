import React from "react";
import { Text, Link } from "@chakra-ui/react";
import { Link as ReactRouterLink, useLocation } from "react-router-dom";
import { useGeneralStore } from "../generalStore";

const PageTitle = () => {
  const currentUser = useGeneralStore((state) => state.currentUser);
  const isAgent = currentUser?.agent;

  const { pathname } = useLocation();

  const paths = pathname.split("/");

  if (paths[1].toLowerCase() === "loans") {
    let links: any[] = [];
    const length = paths.length;

    links.push({
      to: "/loans",
      display: "Loans",
    });

    if (length > 3) {
      if (paths[3].toLowerCase() === "statements") {
        links.push({
          to: `/loans/${paths[2]}/statements`,
          display: "Statements",
        });
      } else if (paths[3].toLowerCase() === "payments") {
        links.push({
          to: `/loans/${paths[2]}/payments`,
          display: "Payments",
        });
      }
    }

    return (
      <Text fontSize="xl" fontWeight="bold">
        {links.map((link, l) => {
          return (
            <React.Fragment key={l}>
              {l === 0 ? "" : "   >   "}
              <Link as={ReactRouterLink} to={link.to} key={l}>
                {link.display}
              </Link>
            </React.Fragment>
          );
        })}
      </Text>
    );
  } else {
    return (
      <Text fontSize="xl" fontWeight="bold">
        {pathname && getTitle({ pathname: pathname.split("/")[1], isAgent })}
      </Text>
    );
  }
};

export default PageTitle;

const getTitle = ({ pathname, isAgent }) => {
  const title = pathname.replace(/([A-Z])/g, " $1").replace(/^./, (str) => {
    return str.toUpperCase();
  });

  if (title === "Agent" && isAgent) {
    return "Profile";
  }

  if (title === "Dashboard") {
    return "Home";
  }

  return title;
};
