import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { isAdminCheck } from "../utility";
import PageLoader from "./PageLoader";

interface IAdminRouteOptions {
  component: any;
  path: string;
}

type AdminRouteProps = IAdminRouteOptions;

const AdminRoute = ({ component, path, ...rest }: AdminRouteProps) => {
  const { isLoading, isAuthenticated, loginWithRedirect, user } = useAuth0();
  const isAdmin = isAdminCheck(user);

  useEffect(() => {
    const callAsync = async () => {
      if (isLoading || !loginWithRedirect) {
        return;
      }

      if (!isAuthenticated) {
        await loginWithRedirect({
          redirect_uri: "",
          appState: { targetUrl: path },
        });
      }
    };

    callAsync();
  }, [isAuthenticated, isLoading, loginWithRedirect, path]);

  if (isLoading) {
    return <PageLoader />;
  }

  if (!isAuthenticated || !isAdmin) return null;

  return <Route path={path} component={component} {...rest} />;
};

export default AdminRoute;
