import moment from "moment";
import { getLanguageOrNull, LanguageOrString } from "../languages/language";
import {
  NumberOrNull,
  StringOrNull,
  StringOrUndefined,
} from "../scalars/scalar";

export enum DateFormat {
  YYYY = "YYYY",
  YYYY_MM_DD = "YYYY-MM-DD",
  ISO = "YYYY-MM-DDTHH:mm:ss.SSSZ",
  JS_DATE = "JS_DATE",
}

export enum DateTimeUnit {
  YEAR = "y",
  QUARTER = "Q",
  MONTH = "M",
  WEEK = "w",
  DAY = "d",
  HOUR = "h",
  MINUTE = "m",
  SECOND = "s",
  MILLISECOND = "ms",
}

export enum TimeZone {
  UTC = "UTC",
}

export type DateOrNull = Date | null;
export type DateOrString = Date | string;
export type DateOrStringOrNull = Date | string | null;
export type DateOrStringOrNullOrUndefined = Date | string | null | undefined;

export type TimeZoneOrNull = TimeZone | null;
export type TimeZoneOrString = TimeZone | string;
export type TimeZoneOrStringOrUndefined = TimeZone | string | undefined;
export type TimeZoneOrStringOrNull = TimeZone | string | null;
export type TimeZoneOrStringOrNullOrUndefined =
  | TimeZone
  | string
  | null
  | undefined;

export function getTimeZoneOrNull(
  timeZone: TimeZoneOrStringOrNullOrUndefined
): TimeZoneOrNull {
  let returnValue: TimeZoneOrNull = null;
  if (timeZone === TimeZone.UTC) {
    returnValue = timeZone;
  }

  return returnValue;
}

export function addDate(
  dateString: StringOrNull,
  dateFormat: StringOrNull,
  addNumber: NumberOrNull,
  addUnit: DateTimeUnit
): StringOrNull {
  let returnValue: StringOrNull = null;
  if (dateString && dateFormat) {
    let workingDate = moment.utc(dateString, dateFormat as any);
    if (addNumber && addUnit) {
      workingDate.add(addNumber, addUnit);
      returnValue = workingDate.format(dateFormat);
    }
  }

  return returnValue;
}

export function getDateStringAsDate(
  dateString: StringOrNull,
  dateFormat: DateFormat
): DateOrNull {
  let returnValue: DateOrNull = null;

  if (dateString) {
    const workingDate = moment
      .utc(dateString, dateFormat as any)
      .set("hour", 12);
    const minuteOffset = moment().utcOffset().toLocaleString();
    workingDate.subtract(minuteOffset, DateTimeUnit.MINUTE);

    returnValue = workingDate.toDate();
  }

  return returnValue;
}

export function getDateAsDateString(
  date: DateOrNull,
  dateFormat: DateFormat
): StringOrNull {
  let returnValue: StringOrNull = null;

  if (date) {
    const workingDate = moment.utc(date);
    const minuteOffset = moment().utcOffset();
    workingDate.add(minuteOffset, DateTimeUnit.MINUTE);
    returnValue = workingDate.format(dateFormat);
  }

  return returnValue;
}

export function getFormattedDateValue(
  date: DateOrNull,
  language: LanguageOrString,
  yearFormat: StringOrUndefined,
  monthFormat: StringOrUndefined,
  dayFormat: StringOrUndefined,
  hourFormat?: StringOrUndefined,
  minuteFormat?: StringOrUndefined,
  secondFormat?: StringOrUndefined,
  weekdayFormat?: StringOrUndefined,
  hour12?: StringOrUndefined,
  timeZone?: TimeZoneOrStringOrUndefined
): StringOrNull {
  let returnValue: StringOrNull = null;

  if (date) {
    date = new Date(moment.utc(date).format());
    let workingLanguage = getLanguageOrNull(language);
    let workingTimeZone = getTimeZoneOrNull(timeZone);

    if (workingLanguage) {
      let options = {
        year: yearFormat,
        month: monthFormat,
        day: dayFormat,
        hour: hourFormat,
        minute: minuteFormat,
        second: secondFormat,
        weekday: weekdayFormat,
        hour12: hour12,
        timeZone: workingTimeZone ? workingTimeZone : undefined,
      };

      returnValue = new Intl.DateTimeFormat(language, options as any).format(
        date
      );
    }
  }

  return returnValue;
}

export const getYearForDateString = function (
  dateString: StringOrNull,
  dateFormat: DateFormat
): NumberOrNull {
  let result: NumberOrNull = null;

  if (dateString && dateFormat) {
    result = moment.utc(dateString, dateFormat).year();
  }

  return result;
};

export const getNowUTCISO = function (): string {
  return moment.utc().toISOString();
};

export const getNowUTCDate = function (format: DateFormat): string {
  return moment.utc().format(format);
};

export const getNumberOfUnitsBetween = function (
  startDate: StringOrNull,
  endDate: StringOrNull,
  dateFormat: DateFormat,
  dateTimeUnit: DateTimeUnit
): NumberOrNull {
  let numberOfUnits: NumberOrNull = null;
  if (startDate && endDate && dateFormat && dateTimeUnit) {
    let workingStartDate = moment.utc(startDate, dateFormat);
    let workingEndDate = moment.utc(endDate, dateFormat);

    numberOfUnits = Math.ceil(
      workingEndDate.diff(workingStartDate, dateTimeUnit, true)
    );
  }

  return numberOfUnits;
};

export const getStartOf = function (
  dateString: StringOrNull,
  dateTimeUnit: DateTimeUnit,
  parseFormat: DateFormat,
  returnFormat: DateFormat
): StringOrNull {
  return moment
    .utc(dateString, parseFormat)
    .startOf(dateTimeUnit)
    .format(returnFormat);
};

export const getEndOf = function (
  dateString: StringOrNull,
  dateTimeUnit: DateTimeUnit,
  parseFormat: DateFormat,
  returnFormat: DateFormat
): StringOrNull {
  return moment
    .utc(dateString, parseFormat)
    .endOf(dateTimeUnit)
    .format(returnFormat);
};

export const sortListOfObjectsByDateTimeMember = function (
  list: any[],
  member: string,
  dateTimeFormat: DateFormat,
  descending: boolean
): any[] {
  let sortedList: any[] = [...list];

  if (list && dateTimeFormat) {
    sortedList = sortedList.sort(
      (a, b) =>
        moment(a[member], dateTimeFormat).valueOf() -
        moment(b[member], dateTimeFormat).valueOf()
    );
  }

  if (descending) {
    sortedList = sortedList.reverse();
  }

  return sortedList;
};
