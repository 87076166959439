import React from "react";
import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Table,
  Tbody,
  Td,
  Tr,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import NavbarUserDropdown from "./NavbarUserDropdown";
import PageTitle from "./PageTitle";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { mediumWidth, smallWidth } from "../utility/constants";
import NavbarMobileMenu from "./NavbarMobileMenu";
import { useGeneralStore } from "../generalStore";
import numeral from "numeral";
import moment from "moment";
import { checkIfFinalizedSaverCredit } from "../utility";

const Navbar = () => {
  const currentUser = useGeneralStore((state) => state.currentUser);
  const [isSmallerThanMedium] = useMediaQuery(`(max-width: ${mediumWidth})`);
  const [isSmallerThanSmall] = useMediaQuery(`(max-width: ${smallWidth})`);

  if (isSmallerThanMedium) {
    return <NavbarMobileMenu />;
  } else {
    return (
      <Flex
        as="nav"
        alignItems="center"
        p="16px 24px"
        borderBottomWidth={1}
        borderBottomColor="gray.200"
        position="fixed"
        left="0"
        top="0"
        right="0"
        zIndex="3"
        ml={237}
        justifyContent="space-between"
        minHeight={68}
        maxHeight={68}
        bg="white"
      >
        <Box>
          <PageTitle />
        </Box>
        <Box>
          {currentUser && currentUser.saver && (
            <Popover>
              <PopoverTrigger>
                <Button
                  mr="1rem"
                  rightIcon={<ChevronDownIcon />}
                  variant="outline"
                  borderRadius="md"
                  size="md"
                  fontWeight="400"
                  bgColor="purple.50"
                  color="purple.600"
                  borderColor="purple.50"
                  _hover={{ bg: "purple.100" }}
                >
                  <b>
                    {numeral(
                      currentUser?.saver?.saverCredit.reduce((prev, curr) => {
                        if (checkIfFinalizedSaverCredit(curr)) {
                          return prev + curr.amount;
                        } else {
                          return prev;
                        }
                      }, 0)
                    ).format("$0,0")}
                  </b>
                  &nbsp;Safe Rate Credits
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />

                <PopoverBody mt="1rem" maxH="300px" overflowY="auto">
                  <Table variant="unstyled" size="sm">
                    <Tbody>
                      <Tr borderBottom="1px solid #eee">
                        <Td
                          color="gray.500"
                          textAlign="left"
                          p="0.4rem 0.25rem"
                          fontSize="xs"
                        >
                          Credits
                        </Td>
                      </Tr>

                      {currentUser?.saver?.saverCredit
                        .sort((a, b) => {
                          if (a.createdAt < b.createdAt) return 1;
                          if (a.createdAt > b.createdAt) return -1;
                          return 0;
                        })
                        .map((saverCredit, i) => {
                          const isAFinalizedSaverCredit =
                            checkIfFinalizedSaverCredit(saverCredit);

                          if (!isAFinalizedSaverCredit) return null;

                          return (
                            <Tr key={i}>
                              <Td
                                color="purple.700"
                                fontWeight="bold"
                                p="0.25rem 1rem"
                              >
                                +{numeral(saverCredit.amount).format("$0,0")}
                              </Td>
                              <Td p="0.25rem 0.5rem">
                                {(() => {
                                  const { closed, creditType, verified } =
                                    saverCredit;

                                  if (creditType === "join") {
                                    return "Joined the Safe Rate Savers Club";
                                  } else if (creditType === "video") {
                                    return "Completed a quiz";
                                  } else if (
                                    creditType === "agent" &&
                                    verified
                                  ) {
                                    return "Linked to an agent";
                                  } else if (creditType === "employer") {
                                    return "Linked to an employer";
                                  } else if (
                                    creditType === "referral" &&
                                    closed
                                  ) {
                                    return "Referred another saver that closed their loan";
                                  } else if (
                                    creditType === "chat" &&
                                    verified
                                  ) {
                                    return "Scheduled a mortgage coffee chat";
                                  } else {
                                    return "";
                                  }
                                })()}
                              </Td>
                              <Td p="0.25rem 0.5rem">
                                {moment(saverCredit.createdAt).format(
                                  "MM/DD/YYYY"
                                )}
                              </Td>
                            </Tr>
                          );
                        })}
                    </Tbody>
                  </Table>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          )}
          <NavbarUserDropdown />
        </Box>
      </Flex>
    );
  }
};

export default Navbar;
