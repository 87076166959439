import { IFormModel } from "../types/types";
import * as Yup from "yup";

// eslint-disable-next-line import/no-anonymous-default-export
export default (model: IFormModel) => {
  const buildObj = {};

  const keys = Object.keys(model);

  keys.forEach((key) => {
    buildObj[model[key].name] = model[key].validation;
  });

  return Yup.object().shape(buildObj);
};
