import {
  Box,
  Container,
  Heading,
  Table,
  TableContainer,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Link,
  Button,
  Flex,
  Divider,
  Spacer,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { access } from "fs";
import { current } from "immer";
import numeral from "numeral";
import React from "react";
import StreamlineIcon from "../components/StreamlineIcon";
import { useGeneralStore } from "../generalStore";
import { getRateForSaver_getRateForSaver } from "../types/getRateForSaver";
import { getRatePublic_getRatePublic } from "../types/getRatePublic";
import { DealTypes } from "../types/globalTypes";
import { saverJoinCredits } from "../utility/constants";
import GetDealSignupModal from "./GetDealSignupModal";
import GetDealUpdateModal from "./GetDealUpdateModal";

const RatesTable = ({
  currentDealTypeForSaver,
  data,
  input,
}: {
  currentDealTypeForSaver?: DealTypes;
  data: getRatePublic_getRatePublic | getRateForSaver_getRateForSaver;
  input?: any;
}) => {
  const currentUser = useGeneralStore((state) => state.currentUser);
  const isSaver = Boolean(currentUser);

  let credits = saverJoinCredits;

  if (isSaver) {
    let saverCredits = currentUser?.saver?.saverCredit?.reduce(
      (acc, saverCredit) => (acc += saverCredit.amount),
      0
    );
    if (saverCredits && saverCredits > credits) {
      credits = saverCredits;
    }
  }

  return (
    <Box mt="2rem">
      <Container maxW="container.lg">
        <Box>
          <Flex direction={{ base: "column", lg: "row" }}>
            <Box
              textAlign="center"
              border="1px solid #B2F5EA"
              w={{ base: "100%", lg: "31%" }}
              m="1rem 0rem"
            >
              <Box bgColor="teal.100" p="1rem 1.5rem">
                <Box
                  borderRadius="full"
                  bgColor="teal.300"
                  p="0.75rem 0.5rem"
                  w="4rem"
                  h="4rem"
                  margin="0 auto"
                  mt="-2.5rem"
                  mb="0.25rem"
                >
                  <StreamlineIcon
                    name="house-percent-icon-light"
                    color="white"
                    boxSize={"2.5rem"}
                  />
                </Box>
                <Text
                  textTransform="uppercase"
                  letterSpacing={"0.5px"}
                  color="gray.600"
                  fontWeight="600"
                  fontSize="md"
                >
                  Lower your rate
                </Text>
                <Text fontSize="md" color="gray.600">
                  Save thousands by buying down your rate
                </Text>
              </Box>
              <Box p="1rem 1.5rem" borderBottom="1px solid #F7FAFC">
                <Text fontSize="1.5rem" color="teal.400" fontWeight="600">
                  {numeral(data.lowestRate.lifetimeSavings).format("$0,0")}
                </Text>
                <Text color="gray.500" fontSize="md">
                  saved over life of loan
                </Text>
              </Box>
              <Box p="0.5rem 1.5rem" borderBottom="1px solid #F7FAFC">
                <Text fontSize="md" as="span" fontWeight="500">
                  {numeral(data.lowestRate.monthlyPayment).format("$0,0")}
                </Text>
                <Text fontSize="sm" color="gray.500">
                  {" "}
                  per month
                </Text>
              </Box>
              <Box p="0.5rem 1.5rem" borderBottom="1px solid #F7FAFC">
                <Text fontSize="md" as="span" fontWeight="500">
                  {numeral(data.lowestRate.interestRate).format("0.000%")}
                </Text>{" "}
                <Text fontSize="sm" color="gray.500">
                  interest rate
                </Text>
              </Box>
              <Box p="0.5rem 1.5rem" borderBottom="1px solid #F7FAFC">
                <Text fontSize="md" as="span" fontWeight="500">
                  {numeral(data.lowestRate.apr).format("0.000%")}
                </Text>

                <Text fontSize="sm" color="gray.500">
                  APR
                </Text>
              </Box>
              {/* <Box p="0.5rem 1.5rem">
                <Text fontSize="md" as="span" fontWeight="500">
                  {numeral(data.lowestRate.estimatedClosingCosts).format(
                    "$0,0"
                  )}
                </Text>
                <Text fontSize="sm" color="gray.500">
                  {" "}
                  est. closing costs
                </Text>
              </Box> */}
              <Box p="1rem 1.5rem" bgColor="gray.50">
                {isSaver ? (
                  <GetDealUpdateModal
                    colorScheme="teal"
                    dealType="rate"
                    isActive={currentDealTypeForSaver === "rate"}
                  />
                ) : (
                  <GetDealSignupModal
                    dealType="rate"
                    colorScheme="teal"
                    emailData={{
                      apr: data.lowestRate.apr,
                      estimatedClosingCosts:
                        data.lowestRate.estimatedClosingCosts,
                      interestRate: data.lowestRate.interestRate,
                      lifetimeSavings: data.lowestRate.lifetimeSavings,
                      monthlyPayment: data.lowestRate.monthlyPayment,
                      ...input,
                    }}
                  />
                )}
                <LowerRateExplanationModal />
              </Box>
            </Box>
            <Spacer />
            <Box
              textAlign="center"
              border="1px solid #E9D8FD"
              w={{ base: "100%", lg: "31%" }}
              m="1rem 0rem"
            >
              <Box bgColor="purple.100" p="1rem 1.5rem">
                <Box
                  borderRadius="full"
                  bgColor="purple.300"
                  p="0.75rem 0.5rem"
                  w="4rem"
                  h="4rem"
                  margin="0 auto"
                  mt="-2.5rem"
                  mb="0.25rem"
                >
                  <StreamlineIcon
                    name="bill-stack-icon-light"
                    color="white"
                    boxSize={"2.5rem"}
                  />
                </Box>
                <Text
                  textTransform="uppercase"
                  letterSpacing={"0.5px"}
                  color="gray.600"
                  fontWeight="600"
                  fontSize="md"
                >
                  Lower your upfront cost
                </Text>
                <Text fontSize="md" color="gray.600">
                  Pay for third party fees and save some cash
                </Text>
              </Box>
              <Box p="1rem 1.5rem" borderBottom="1px solid #F7FAFC">
                <Text fontSize="1.5rem" color="purple.400" fontWeight="600">
                  {numeral(credits).format("$0,0")}
                </Text>
                <Text fontSize="sm" color="gray.500">
                  {" "}
                  saved upfront
                </Text>
              </Box>
              <Box p="0.5rem 1.5rem" borderBottom="1px solid #F7FAFC">
                <Text as="span" fontWeight="500">
                  {numeral(data.lowestOutOfPocket.monthlyPayment).format(
                    "$0,0"
                  )}
                </Text>
                <Text fontSize="sm" color="gray.500">
                  {" "}
                  per month
                </Text>
              </Box>
              <Box p="0.5rem 1.5rem" borderBottom="1px solid #F7FAFC">
                <Text as="span" fontWeight="500">
                  {numeral(data.lowestOutOfPocket.interestRate).format(
                    "0.000%"
                  )}
                </Text>{" "}
                <Text fontSize="sm" color="gray.500">
                  interest rate
                </Text>
              </Box>
              <Box p="0.5rem 1.5rem" borderBottom="1px solid #F7FAFC">
                <Text as="span" fontWeight="500">
                  {numeral(data.lowestOutOfPocket.apr).format("0.000%")}
                </Text>

                <Text fontSize="sm" color="gray.500">
                  APR
                </Text>
              </Box>
              {/* <Box p="0.5rem 1.5rem">
                <Text as="span" fontWeight="500">
                  {numeral(data.lowestOutOfPocket.estimatedClosingCosts).format(
                    "$0,0"
                  )}
                </Text>
                <Text fontSize="sm" color="gray.500">
                  {" "}
                  est. closing costs
                </Text>
              </Box> */}
              <Box p="1rem 1.5rem" bgColor="gray.50">
                {isSaver ? (
                  <GetDealUpdateModal
                    colorScheme="purple"
                    dealType="closing"
                    isActive={currentDealTypeForSaver === "closing"}
                  />
                ) : (
                  <GetDealSignupModal
                    dealType="closing"
                    colorScheme="purple"
                    emailData={{
                      apr: data.lowestOutOfPocket.apr,
                      estimatedClosingCosts:
                        data.lowestOutOfPocket.estimatedClosingCosts,
                      interestRate: data.lowestOutOfPocket.interestRate,
                      lifetimeSavings: credits,
                      monthlyPayment: data.lowestOutOfPocket.monthlyPayment,
                      ...input,
                    }}
                  />
                )}
                <LowerCostExplanationModal />
              </Box>
            </Box>
            <Spacer />
            <Box
              textAlign="center"
              border="1px solid #FAF089"
              w={{ base: "100%", lg: "31%" }}
              m="1rem 0rem"
            >
              <Box bgColor="yellow.200" p="1rem 1.5rem">
                <Box
                  borderRadius="full"
                  bgColor="yellow.300"
                  p="0.75rem 0.5rem"
                  w="4rem"
                  h="4rem"
                  margin="0 auto"
                  mt="-2.5rem"
                  mb="0.25rem"
                >
                  <StreamlineIcon
                    name="credit-card-dollar-icon-light"
                    color="white"
                    boxSize={"2.5rem"}
                  />
                </Box>
                <Text
                  textTransform="uppercase"
                  letterSpacing={"0.5px"}
                  color="gray.600"
                  fontWeight="600"
                  fontSize="md"
                >
                  Get Gift cards
                </Text>
                <Text fontSize="md" color="gray.600">
                  Update your home with a gift card from Amazon or Home Depot
                </Text>
              </Box>
              <Box p="1rem 1.5rem" borderBottom="1px solid #F7FAFC">
                <Text fontSize="1.5rem" color="yellow.400" fontWeight="600">
                  {numeral(credits).format("$0,0")}
                </Text>
                <Text fontSize="sm" color="gray.500">
                  {" "}
                  in gift cards
                </Text>
              </Box>
              <Box p="0.5rem 1.5rem" borderBottom="1px solid #F7FAFC">
                <Text as="span" fontWeight="500">
                  {numeral(data.giftCard.monthlyPayment).format("$0,0")}
                </Text>
                <Text fontSize="sm" color="gray.500">
                  {" "}
                  per month
                </Text>
              </Box>
              <Box p="0.5rem 1.5rem" borderBottom="1px solid #F7FAFC">
                <Text as="span" fontWeight="500">
                  {numeral(data.giftCard.interestRate).format("0.000%")}
                </Text>{" "}
                <Text fontSize="sm" color="gray.500">
                  interest rate
                </Text>
              </Box>
              <Box p="0.5rem 1.5rem" borderBottom="1px solid #F7FAFC">
                <Text as="span" fontWeight="500">
                  {numeral(data.giftCard.apr).format("0.000%")}
                </Text>

                <Text fontSize="sm" color="gray.500">
                  APR
                </Text>
              </Box>
              {/* <Box p="0.5rem 1.5rem">
                <Text as="span" fontWeight="500">
                  {numeral(data.giftCard.estimatedClosingCosts).format("$0,0")}
                </Text>
                <Text fontSize="sm" color="gray.500">
                  {" "}
                  est. closing costs
                </Text>
              </Box> */}
              <Box p="1rem 1.5rem" bgColor="gray.50">
                {isSaver ? (
                  <GetDealUpdateModal
                    colorScheme="yellow"
                    dealType="card"
                    isActive={currentDealTypeForSaver === "card"}
                  />
                ) : (
                  <GetDealSignupModal
                    dealType="card"
                    colorScheme="yellow"
                    emailData={{
                      apr: data.giftCard.apr,
                      estimatedClosingCosts:
                        data.giftCard.estimatedClosingCosts,
                      interestRate: data.giftCard.interestRate,
                      lifetimeSavings: credits,
                      monthlyPayment: data.giftCard.monthlyPayment,
                      ...input,
                    }}
                  />
                )}
                <GiftCardExplanationModal credits={credits} />
              </Box>
            </Box>
          </Flex>
        </Box>
      </Container>
    </Box>
  );
};

export default RatesTable;

const LowerRateExplanationModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        onClick={onOpen}
        variant="link"
        colorScheme={"teal"}
        size="sm"
        fontWeight="400"
        mt="0.5rem"
      >
        Learn more
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Lower Your Rate</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box p="1rem">
              <Text fontSize="0.9rem">
                Lowering your rate is a great option if you plan on staying your
                home for a long time. The longer you plan on staying in your
                home, the more you will save with this option.
              </Text>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const LowerCostExplanationModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        onClick={onOpen}
        variant="link"
        colorScheme={"purple"}
        size="sm"
        fontWeight="400"
        mt="0.5rem"
      >
        Learn more
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Lower Your Upfront Costs</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box p="1rem">
              <Text fontSize="0.9rem">
                All mortgage transactions have upfront fees, called closing
                costs, that are required in order to complete the transaction.
                Some of these costs include homeowners insurance, appraisal, and
                title insurance, and they vary in each state. Closing costs can
                be anywhere between 2 - 5% of the loan amount. Although you may
                be able to roll a portion of closing costs into your loan
                amount, you will likely have to pay a portion of these fees out
                of pocket. If you would like to reduce the amount of cash to
                close on your home, this is a great option.
              </Text>
              <Text mt="1rem" fontSize="0.9rem">
                We will apply your credits once you have completed an
                application and have provided the necessary documents to be
                approved for a mortgage loan. You will see the credits reflected
                as "Lender Credits" on your closing documents which will reduce
                the cash required to close on your home. If your credits are
                greater than your closing costs, you can use your remaining
                credits to lower your rate, if it's allowable on your loan
                product, or redeem for gift cards.
              </Text>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const GiftCardExplanationModal = ({ credits }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        onClick={onOpen}
        variant="link"
        colorScheme={"yellow"}
        size="sm"
        fontWeight="400"
        mt="0.5rem"
      >
        Learn more
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Get Gift Cards</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box p="1rem">
              <Text fontSize="0.9rem">
                Yay, you're a homeowner! But sometimes your new place still
                needs a bit of extra love to make it truly feel like a home. If
                your home needs some work, this is a great option.
              </Text>
              <Text mt="1rem" fontSize="0.9rem">
                Once you close on your home, tell us which retailers you'd like
                gift cards from, and we'll email you the e-gift cards. You can
                mix and match from our partner retailers, currently Amazon and
                Home Depot. So you can get a {numeral(credits).format("$0,0")}{" "}
                gift card from Home Depot or two{" "}
                {numeral(credits / 2).format("$0,0")} gift cards from each
                retailer.
              </Text>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
