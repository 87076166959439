import React from "react";
import { Box, ButtonGroup, Button, Divider } from "@chakra-ui/react";
import { ArrowForwardIcon, ArrowBackIcon } from "@chakra-ui/icons";

type OnboardFormButtonsProps = {
  activePage?: number;
  handleBack: () => void;
  isSubmitting: boolean;
  stepButtonBackText?: string;
  stepButtonNextText?: string;
};

const OnboardFormButtons: React.FC<OnboardFormButtonsProps> = ({
  activePage,
  isSubmitting,
  handleBack,
  stepButtonNextText,
  stepButtonBackText,
}) => {
  return (
    <Box mt="2rem">
      <ButtonGroup spacing="6">
        {activePage !== undefined && activePage !== 1 && (
          <Button
            isDisabled={isSubmitting}
            onClick={handleBack}
            colorScheme="purple"
            leftIcon={<ArrowBackIcon />}
            variant="ghost"
          >
            {stepButtonBackText ? stepButtonBackText : "Go Back"}
          </Button>
        )}
        <Button
          colorScheme="purple"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
          type="submit"
          rightIcon={<ArrowForwardIcon />}
        >
          {stepButtonNextText ? stepButtonNextText : "Next"}
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export default OnboardFormButtons;
