import numeral from "numeral";

// eslint-disable-next-line import/no-anonymous-default-export
export default (v: number | null | undefined): string => {
  if (v === null || v === undefined) {
    return "";
  }

  const returnValue = numeral(v * 100).format("0");

  return returnValue;
};
