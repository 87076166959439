import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { useGeneralStore } from "../generalStore";
import PageLoader from "./PageLoader";

interface IPrivateRouteOptions {
  component: React.FC;
  path: string;
  exact?: boolean;
}

type PrivateRouteProps = IPrivateRouteOptions;

const PrivateRoute = ({
  component,
  path,
  exact,
  ...rest
}: PrivateRouteProps) => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const currentUser = useGeneralStore((state) => state.currentUser);

  useEffect(() => {
    const callAsync = async () => {
      if (isLoading || !loginWithRedirect) {
        return;
      }

      if (!isAuthenticated && !currentUser) {
        let targetUrl = path;
        let otherParams: any = rest;
        if (otherParams) {
          if (otherParams.computedMatch) {
            if (otherParams.computedMatch.url) {
              targetUrl = otherParams.computedMatch.url;
            }
          } else if (otherParams.location) {
            if (otherParams.location.pathname) {
              targetUrl = otherParams.location.pathname;
            }
          }
        }

        await loginWithRedirect({
          redirect_uri: "",
          appState: { targetUrl },
        });
      }
    };

    callAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, isAuthenticated, isLoading, loginWithRedirect, path]);

  if (isLoading) {
    return <PageLoader />;
  }

  if (!isAuthenticated || !currentUser) return null;

  return <Route path={path} component={component} {...rest} />;
};

export default PrivateRoute;
