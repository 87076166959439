import React, { Suspense, lazy, useEffect, useState } from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import PageLoader from "./components/PageLoader";
import PageWrapper from "./components/PageWrapper";
import ScrollToTop from "./components/ScrollToTop";
import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@chakra-ui/react";
import FooterHome from "./home/FooterHome";
import NavbarHome from "./home/NavbarHome";
import PrivateRoute from "./components/PrivateRoute";
import AdminRoute from "./components/AdminRoute";
import ServicingRoute from "./components/ServicingRoute";
import ReactGA from "react-ga";
import { useApolloClient, useLazyQuery } from "@apollo/client";
import { GET_ONE_USER } from "./graphql/savers/constants";
import { useGeneralStore } from "./generalStore";
import { getOneUser, getOneUserVariables } from "./types/getOneUser";
import _ from "lodash";
import SaverCobrand from "./savers/SaverCobrand";
import RatesSaver from "./rates/RatesSaver";
import { GET_BORROWER_ACCOUNT_SUMMARY } from "./graphql/servicing/constants";
import { graphQLContext } from "./types/types";

const Admin = lazy(() => import("./admin/Admin"));
const About = lazy(() => import("./home/About"));
const Account = lazy(() => import("./settings/Account"));
const Agent = lazy(() => import("./agents/Agent"));
const AgentCobrand = lazy(() => import("./agents/AgentCobrand"));
const AgentReferral = lazy(() => import("./agents/AgentReferral"));
const EmployerCobrand = lazy(() => import("./employers/EmployerCobrand"));
const EmployerReferral = lazy(() => import("./employers/EmployerReferral"));
const AgentTransactions = lazy(() => import("./agents/AgentTransactions"));
const Borrowers = lazy(() => import("./home/Borrowers"));
const Charity = lazy(() => import("./charities/Charity"));
const ChatPublic = lazy(() => import("./home/Chat"));
const Chat = lazy(() => import("./chat/Chat"));
const CommunityImpact = lazy(() => import("./dashboard/CommunityImpact"));
const DashboardInterface = lazy(() => import("./dashboard/DashboardWrapper"));
const PublicEmployers = lazy(() => import("./home/Employers"));
const GeographySearch = lazy(() => import("./components/GeographySearch"));
const Home = lazy(() => import("./home/Home"));
const Insurance = lazy(() => import("./insurance/Insurance"));
const Invest = lazy(() => import("./invest/Invest"));
const Learn = lazy(() => import("./home/Learn"));
const LearnSaver = lazy(() => import("./dashboard/LearnSaver"));
const LetterOfExplanation = lazy(
  () => import("./letter-of-explanation/LetterOfExplanation")
);
const LicensingAndDisclosure = lazy(
  () => import("./home/LicensingAndDisclosure")
);
const LoanWelcome = lazy(() => import("./loans/LoanWelcome"));
const Leads = lazy(() => import("./agents/AgentLeads"));
const Loans = lazy(() => import("./loans/Loans"));
const Login = lazy(() => import("./login/Login"));
const MortgageAmortization = lazy(
  () => import("./mortgage-calculator/MortgageAmortization")
);
const MortgageCalculator = lazy(
  () => import("./mortgage-calculator/MortgageCalculator")
);
const Onboard = lazy(() => import("./onboard/Onboard"));
const PageNotFound = lazy(() => import("./components/PageNotFound"));
const Payments = lazy(() => import("./loans/payments/Payments"));
const Rates = lazy(() => import("./rates/Rates"));
//New
const Preapproval = lazy(() => import("./preapproval/Preapproval"));
const PrivacyPolicy = lazy(() => import("./home/PrivacyPolicy"));
const Products = lazy(() => import("./home/Products"));
const PurchaseContract = lazy(
  () => import("./purchase-contract/PurchaseContract")
);
const PurchaseContractNew = lazy(
  () => import("./purchase-contract/PurchaseContractNew")
);
const PurchaseContractAddenda = lazy(
  () => import("./purchase-contract/PurchaseContractAddenda")
);
const Profile = lazy(() => import("./agents/Agent"));
const QuizRewards = lazy(() => import("./learn/QuizRewards"));
const RealEstateAgents = lazy(() => import("./home/RealEstateAgents"));
const SafeQuote = lazy(() => import("./safe-quote/SafeQuote"));
const SaverCo = lazy(() => import("./savers/SaverCobrand"));
const SaverReferral = lazy(() => import("./savers/SaverReferral"));
const SearchCharities = lazy(() => import("./charities/SearchCharities"));
const Settings = lazy(() => import("./settings/Settings"));
const Shop = lazy(() => import("./shop/Shop"));
const Statements = lazy(() => import("./loans/statements/Statements"));
const StatementPage = lazy(() => import("./loans/statements/StatementPage"));
const Save = lazy(() => import("./home/Save"));
const SearchAgents = lazy(() => import("./agents/SearchAgents"));
const SearchEmployers = lazy(() => import("./employers/SearchEmployers"));
const Signup = lazy(() => import("./signup/Signup"));
const TermsOfUse = lazy(() => import("./home/TermsOfUse"));
const Video = lazy(() => import("./learn/Learn"));
const Test = lazy(() => import("./test/Test"));
const ViewPolicy = lazy(() => import("./insurance/ViewPolicy"));

type RoutesProps = {
  userHasFullyLoggedIn: boolean;
};

const Routes: React.FC<RoutesProps> = ({ userHasFullyLoggedIn }) => {
  const currentUser = useGeneralStore((state) => state.currentUser);
  const { isAuthenticated, isLoading, user } = useAuth0();
  const isAgent = currentUser?.agent;
  const isEmployer = currentUser?.employer;
  const isSaver = currentUser?.saver;

  let isRequiringOnboarding = false;

  if (!isSaver && !isAgent && !isEmployer) {
    isRequiringOnboarding = true;
  }

  if (isSaver && !currentUser?.saver?.loanType) {
    isRequiringOnboarding = true;
  }

  return (
    <BrowserRouter>
      <RouteWrapper
        isLoading={isLoading}
        user={user}
        userHasFullyLoggedIn={userHasFullyLoggedIn}
      >
        <Suspense fallback={<PageLoader />}>
          <Switch>
            {isAuthenticated ? (
              <Route exact path={["/", "/home"]}>
                <Redirect to="/dashboard" />
              </Route>
            ) : (
              <Route exact path="/" component={Home} />
            )}
            {currentUser && isRequiringOnboarding && (
              <Route exact path={["/dashboard"]}>
                <Redirect to="/onboard" />
              </Route>
            )}
            <Route exact path="/about" component={About} />
            <AdminRoute path="/admin" component={Admin} />
            {/* <ServicingRoute exact path="/account" component={Account} /> */}
            <Route path="/agent/:username" component={Agent} />
            {isAuthenticated ? (
              <Route path="/agents" component={SearchAgents} />
            ) : (
              <Route path="/agents" component={RealEstateAgents} />
            )}

            <Route path="/borrowers" component={Borrowers} />
            {isAuthenticated ? (
              <PrivateRoute path="/chat" component={Chat} />
            ) : (
              <Route path="/chat" component={ChatPublic} />
            )}
            {/* <Route path="/charities" component={Charities} /> */}
            <Route path="/charity/:slug" component={Charity} />
            <Route path="/charities" component={SearchCharities} />
            <Route path="/agent-cobrand/:username" component={AgentCobrand} />
            <Route path="/agent-referral/:username" component={AgentReferral} />
            <Route path="/employer-cobrand/:slug" component={EmployerCobrand} />
            <Route
              path="/employer-referral/:slug"
              component={EmployerReferral}
            />
            <Route
              path="/letter-of-explanation"
              component={LetterOfExplanation}
            />
            <Route path="/purchase-contract" component={PurchaseContract} />
            <Route
              path="/purchase-contract-new"
              component={PurchaseContractNew}
            />
            <Route
              path="/purchase-contract-addenda"
              component={PurchaseContractAddenda}
            />
            <Route
              path="/mortgage-amortization"
              component={MortgageAmortization}
            />
            <Route path="/mortgage-calculator" component={MortgageCalculator} />
            <Route
              path="/saver-cobrand/:referrerCode"
              component={SaverCobrand}
            />
            <Route
              path="/saver-referral/:referrerCode"
              component={SaverReferral}
            />
            <PrivateRoute path="/dashboard" component={DashboardInterface} />

            {isAuthenticated ? (
              <Route path="/employers" component={SearchEmployers} />
            ) : (
              <Route path="/employers" component={PublicEmployers} />
            )}

            {/* <Route path="/communityimpact" component={CommunityImpact} /> */}
            <PrivateRoute exact path="/insurance" component={Insurance} />
            <PrivateRoute path="/invest" component={Invest} />
            <PrivateRoute exact path="/leads" component={Leads} />
            {isSaver ? (
              <PrivateRoute exact path="/learn" component={LearnSaver} />
            ) : (
              <Route exact path="/learn" component={Learn} />
            )}
            <PrivateRoute exact path="/deals" component={RatesSaver} />
            <Route exact path="/rates" component={Rates} />
            <Route path="/learn/:slug" component={Video} />
            <Route
              path="/licensing-and-disclosure"
              component={LicensingAndDisclosure}
            />
            <PrivateRoute
              exact
              path="/transactions"
              component={AgentTransactions}
            />
            <ServicingRoute exact path="/loans" component={Loans} />
            <Route path="/agents" component={SearchAgents} />
            <PrivateRoute path="/onboard" component={Onboard} />
            <ServicingRoute
              exact
              path="/loans/:loanId/payments"
              component={Payments}
            />
            <ServicingRoute
              exact
              path="/loans/:loanId/statements"
              component={Statements}
            />
            <ServicingRoute
              exact
              path="/loans/:loanId/statements/:statementId"
              component={StatementPage}
            />
            <Route exact path="/login" component={Login} />
            <AdminRoute path="/mapbox" component={GeographySearch} />
            <Route path="/preapproval/:preapprovalId" component={Preapproval} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/products" component={Products} />
            {isAgent && (
              <PrivateRoute exact path="/profile" component={Profile} />
            )}
            <PrivateRoute
              path="/quiz-rewards/:quizId"
              component={QuizRewards}
            />
            {/* <Route path="/save" component={Save} /> */}
            <Route exact path="/safe-quote" component={SafeQuote} />
            <PrivateRoute path="/settings" component={Settings} />
            <PrivateRoute exact path="/shop" component={Shop} />
            <Route path="/signup" component={Signup} />
            <Route path="/terms-of-use" component={TermsOfUse} />
            <AdminRoute path="/test" component={Test} />
            <PrivateRoute
              exact
              path="/insurance/policy"
              component={ViewPolicy}
            />
            <Route path="*">
              <PageNotFound />
            </Route>
          </Switch>
        </Suspense>
      </RouteWrapper>
    </BrowserRouter>
  );
};

export default Routes;

function usePageViews() {
  const location = useLocation();
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.pageview(location.pathname);
    }
  }, [location]);
}

const isRouteToRemoveAllWrappers = (pathname) => {
  const excludeWrapperRoutes = [
    "admin",
    "agent-cobrand",
    "agent-referral",
    "employer-cobrand",
    "employer-referral",
    "onboard",
    "preapproval",
    "saver-cobrand",
    "saver-referral",
  ];
  let isThisAnExcludeRoute = false;

  for (let i = 0; i < excludeWrapperRoutes.length; i++) {
    const excludeRoute = excludeWrapperRoutes[i];

    if (pathname.includes(excludeRoute)) {
      isThisAnExcludeRoute = true;
      break;
    }
  }

  if (isThisAnExcludeRoute) {
    return true;
  } else {
    return false;
  }
};

const RouteWrapper = ({ children, isLoading, user, userHasFullyLoggedIn }) => {
  usePageViews();
  const location = useLocation();
  const [currentUserFetchLoading, setCurrentUserFetchLoading] = useState(false);
  const client = useApolloClient();
  const setStoreObj = useGeneralStore((state) => state.setStoreObj);
  const [getBorrowerAccountSummary] = useLazyQuery(
    GET_BORROWER_ACCOUNT_SUMMARY,
    {
      context: { clientName: graphQLContext.SERVICING },
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (
          data &&
          data.getBorrowerAccountSummary &&
          data.getBorrowerAccountSummary.borrower &&
          data.getBorrowerAccountSummary.borrower.id
        ) {
          setStoreObj({
            borrowerId: data.getBorrowerAccountSummary.borrower.id,
          });
        }
      },
    }
  );

  useEffect(() => {
    const callAsync = async () => {
      setCurrentUserFetchLoading(true);
      try {
        if (!userHasFullyLoggedIn) return;

        const id = user!["https://saferate.com/safe_rate_id"];

        const userWatchQuery = await client.watchQuery<
          getOneUser,
          getOneUserVariables
        >({
          query: GET_ONE_USER,
          variables: {
            id,
          },
        });

        userWatchQuery.subscribe(({ data }) => {
          if (data && data.getOneUser) {
            const currentUser = _.cloneDeep(data.getOneUser);

            //merging all saver credits to the saver object client side, some come in just under user. this moves them all to user.saver.saverCredit
            if (
              currentUser &&
              currentUser.saver &&
              currentUser.saver.saverCredit &&
              currentUser.saverCredit
            ) {
              currentUser.saver.saverCredit = [
                ...currentUser.saver.saverCredit,
                ...currentUser.saverCredit,
              ];
            }

            setStoreObj({ currentUser });
          }
        });
        setCurrentUserFetchLoading(false);
      } catch (error) {}
    };

    if (user && user["https://saferate.com/safe_rate_id"]) {
      callAsync();
    }
  }, [client, setStoreObj, user, userHasFullyLoggedIn]);

  useEffect(() => {
    const callAsync = async () => {
      if (userHasFullyLoggedIn) {
        getBorrowerAccountSummary();
      }
    };

    callAsync();
  }, [getBorrowerAccountSummary, setStoreObj, userHasFullyLoggedIn]);

  if (currentUserFetchLoading || isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      {(() => {
        if (isRouteToRemoveAllWrappers(location.pathname)) {
          return <ScrollToTop>{children}</ScrollToTop>;
        } else if (user) {
          return (
            <ScrollToTop>
              <PageWrapper>{children}</PageWrapper>
            </ScrollToTop>
          );
        } else {
          return (
            <>
              <NavbarHome />
              <Box minH="calc(100vh - 7rem - 15rem)">
                <ScrollToTop>{children}</ScrollToTop>
              </Box>
              <FooterHome />
            </>
          );
        }
      })()}
    </>
  );
};
