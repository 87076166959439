import { useMutation } from "@apollo/client";
import { StarIcon } from "@chakra-ui/icons";
import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useGeneralStore } from "../generalStore";
import { UPDATE_ONE_SAVER } from "../graphql/savers/constants";
import {
  updateOneSaver,
  updateOneSaverVariables,
} from "../types/updateOneSaver";

type GetDealUpdateProps = {
  colorScheme?: string;
  dealType: "rate" | "closing" | "card";
  isActive: boolean;
  variant?: string;
  width?: string;
};

const GetDealUpdateModal = ({
  variant,
  dealType,
  colorScheme,
  width,
  isActive,
}: GetDealUpdateProps) => {
  const currentUser = useGeneralStore((state) => state.currentUser);

  const [callUpdateOneSaver, { data, loading, error }] = useMutation<
    updateOneSaver,
    updateOneSaverVariables
  >(UPDATE_ONE_SAVER, {
    variables: {
      input: {
        dealType,
        id: currentUser!.saver!.id,
      },
    },
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef();
  const finalRef = React.useRef();

  return (
    <Box>
      <Button
        variant={variant}
        width={width}
        colorScheme={colorScheme}
        size="md"
        onClick={isActive ? () => {} : onOpen}
      >
        {isActive && <StarIcon mr={2} />}{" "}
        {isActive ? "My current deal" : "Get the deal"}
      </Button>
      <Modal
        initialFocusRef={initialRef as any}
        finalFocusRef={finalRef as any}
        isOpen={isOpen}
        onClose={onClose}
        size="lg"
      >
        <ModalOverlay></ModalOverlay>
        <ModalContent>
          <ModalHeader>Get the deal</ModalHeader>
          <ModalCloseButton></ModalCloseButton>
          <ModalBody pb={6}>
            <Box textAlign="left">
              <Text fontWeight="500" fontSize="lg">
                Are you ready to get this deal?
              </Text>
              <Text mt="1rem">
                Please note, interest rates change each day. We will be able to
                lock your rate once you have completed an application and we
                have verified the information.
              </Text>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="purple"
              mr={1}
              onClick={async () => {
                callUpdateOneSaver();
              }}
              isLoading={loading}
              isDisabled={loading}
            >
              Yes! Give me this deal
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default GetDealUpdateModal;
