// These are default Chakra values as outlined here: https://chakra-ui.com/docs/features/responsive-styles

export const saverAgentCredits = 250;
export const saverEmployerCredits = 250;
export const saverReferralCredits = 0;
export const saverMaxCredits = 2500;
export const saverMaxVideoCredits = 0;
export const saverJoinCredits = 2000;
export const saverChatCredits = 0;
export const saverVideoCredits = 0;
export const saverDefaultDealSavings = 2000;

export const purchasePriceMax = 3000000;

export const agentOneLinerMaxCharacterCount = 150;

export const smallWidth = "30em";
export const mediumWidth = "48em";
export const largeWidth = "62em";
export const extraLargeWidth = "80em";

export const settingsHomeGoalsTabIndex = 2;

export const defaultRequiredErrorMessage = "This field is required";
export const defaultUrlErrorMessage = "This field must be a valid URL";
export const defaultEmailErrorMessage = "E-mail address is not valid";
export const defaultPhoneErrorMessage = "Phone number is not valid";
export const phoneNumberValidation =
  /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

export const videoProviders = [{ label: "YouTube", value: "youtube" }];

export const creditScoreRanges = [
  { label: "Exceptional: 800+", value: "exceptional" },
  { label: "Very Good: 740-799", value: "verygood" },
  { label: "Good: 670-739", value: "good" },
  { label: "Fair: 580-669", value: "fair" },
  { label: "Poor: 300-579", value: "poor" },
];

export const creditScoreRangesNumbers = [
  { label: "800+", value: "exceptional" },
  { label: "740-799", value: "verygood" },
  { label: "670-739", value: "good" },
  { label: "580-669", value: "fair" },
  { label: "300-579", value: "poor" },
];

export const occupancyTypes = [
  { label: "Primary residence", value: "residence" },
  { label: "Second home", value: "secondHome" },
  { label: "Investment", value: "investment" },
];

export const propertyTypes = [
  {
    value: "singleFamilyResidence",
    label: "Single Family",
    descriptorText: "a home on its own plot with no shared walls",
  },
  {
    value: "condoAttached",
    label: "Condominium Attached",
    descriptorText: "privately-owned individual unit within a condo building",
  },
  {
    value: "condoDetached",
    label: "Condominium Detached",
    descriptorText:
      "just like a condo, but has no attached walls with another unit",
  },
  {
    value: "townhome",
    label: "Townhome",
    descriptorText:
      "shares walls with the houses next door, but none above or below",
  },
];

export const rateType = [
  { label: "30-yr fixed rate", value: "Fixed30Year" },
  { label: "15-yr fixed rate", value: "Fixed15Year" },
];

export const saferateCdn = "https://cdn.saferate.com";

export const agentLeadStatus = {
  invited: { color: "yellow", label: "Invited" },
  joined: { color: "blue", label: "Joined" },
  eligible: { color: "purple", label: "Eligible" },
  preapproved: { color: "teal", label: "Pre-Approved" },
};

export const agentLeadReasons = [
  { label: "Needs a lender", value: "current" },
  {
    label: "Needs help improving financial situation",
    value: "financial",
  },
];

export const agentLoanStatus = {
  contacted: { color: "yellow", label: "Contacted" },
  application: { color: "blue", label: "Application" },
  attorneyReview: { color: "purple", label: "Attorney Review" },
  inspection: { color: "gray", label: "Inspection" },
  appraisal: { color: "pink", label: "Appraisal" },
  cleartoclose: { color: "teal", label: "Clear to Close" },
};

export const defaultUnsavedDataMessage =
  "You have unsaved data. Are you sure you would like to continue without saving?";

export const stateAbbreviations = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

export const states = [
  "Alaska",
  "Alabama",
  "Arkansas",
  "Arizona",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Iowa",
  "Idaho",
  "Illinois",
  "Indiana",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Massachusetts",
  "Maryland",
  "Maine",
  "Michigan",
  "Minnesota",
  "Missouri",
  "Mississippi",
  "Montana",
  "North Carolina",
  "North Dakota",
  "Nebraska",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "Nevada",
  "New York",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Virginia",
  "Vermont",
  "Washington",
  "Wisconsin",
  "West Virginia",
  "Wyoming",
];

export const charityAreasOfFocus = [
  "Career development",
  "Foreclosure prevention",
];

export const agentAreasOfInterest = [
  "Buyer's agent",
  "Condominiums",
  "First-time homebuyers",
  "Investment properties",
  "Listing agent",
  "Negotiation",
  "Property Management",
];

export const agentDesignations = [
  "Accredited Buyer's Representative (ABR)",
  "Accredited Residential Manager (ARM)",
  "At Home with Diversity (AHWD)",
  "Certified Property Manager (CPM)",
  "Certified Real Estate Team Specialist (C-RETS)",
  "Certified Real Estate Brokerage Manager (CRB)",
  "Certified Residential Specialist (CRS)",
  "Luxury Home Certification (LHC)",
  "Military Relocation Professional (MRP)",
  "NAR's Green Designation",
  "Resort and Second-Home Property Specialist (RSPS)",
  "Seller Representative Specialize (SRS)",
  "Senior Real Estate Specialist (SRES)",
];

export const focusGeographies = ["Chicago", "Irving Park", "Lincoln Park"];

export const languages = [
  "English",
  "Arabic",
  "Bengali",
  "Cantonese",
  "Croatian",
  "Farsi",
  "French",
  "German",
  "Gujurati",
  "Hindi",
  "Indonesian",
  "Italian",
  "Japanese",
  "Korean",
  "Mandarin",
  "Marathi",
  "Polish",
  "Portuguese",
  "Punjabi",
  "Russian",
  "Serbian",
  "Spanish",
  "Tagalog",
  "Tamil",
  "Telugu",
  "Turkish",
  "Ukranian",
  "Urdu",
  "Vietnamese",
];

export const numberOfEmployees = [
  "0 - 10",
  "10 - 50",
  "50 - 100",
  "100 - 500",
  "500+",
];
