import { Stack } from "@chakra-ui/react";
import React from "react";
import SwitchField from "../components/form/SwitchField";

const FinancialHelp = (props) => {
  return (
    <React.Fragment>
      <Stack>
        <SwitchField
          label={props.model[props.formGroup].financialBestRate.label}
          name={props.model[props.formGroup].financialBestRate.name}
          colorScheme="teal"
          size="lg"
          mr="1rem"
          formControlProps={{ p: "0.25rem 0" }}
          formLabelProps={{
            mb: "0",
            fontSize: "lg",
            fontWeight: "400",
          }}
        />
        <SwitchField
          label={props.model[props.formGroup].financialTalk.label}
          name={props.model[props.formGroup].financialTalk.name}
          colorScheme="teal"
          size="lg"
          mr="1rem"
          formControlProps={{ p: "0.25rem 0" }}
          formLabelProps={{
            mb: "0",
            fontSize: "lg",
            fontWeight: "400",
          }}
        />
        <SwitchField
          label={props.model[props.formGroup].financialImproveCredit.label}
          name={props.model[props.formGroup].financialImproveCredit.name}
          colorScheme="teal"
          size="lg"
          mr="1rem"
          formControlProps={{ p: "0.25rem 0" }}
          formLabelProps={{
            mb: "0",
            fontSize: "lg",
            fontWeight: "400",
          }}
        />
        <SwitchField
          label={props.model[props.formGroup].financialDownPayment.label}
          name={props.model[props.formGroup].financialDownPayment.name}
          colorScheme="teal"
          size="lg"
          mr="1rem"
          formControlProps={{ p: "0.25rem 0" }}
          formLabelProps={{
            mb: "0",
            fontSize: "lg",
            fontWeight: "400",
          }}
        />
        <SwitchField
          label={props.model[props.formGroup].financialReduceDebt.label}
          name={props.model[props.formGroup].financialReduceDebt.name}
          colorScheme="teal"
          size="lg"
          mr="1rem"
          formControlProps={{ p: "0.25rem 0" }}
          formLabelProps={{
            mb: "0",
            fontSize: "lg",
            fontWeight: "400",
          }}
        />
        <SwitchField
          label={props.model[props.formGroup].financialResolveBankrupt.label}
          name={props.model[props.formGroup].financialResolveBankrupt.name}
          colorScheme="teal"
          size="lg"
          mr="1rem"
          formControlProps={{ p: "0.25rem 0" }}
          formLabelProps={{
            mb: "0",
            fontSize: "lg",
            fontWeight: "400",
          }}
        />
      </Stack>
    </React.Fragment>
  );
};

export default FinancialHelp;
