import { useAuth0 } from "@auth0/auth0-react";
import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Flex,
  Box,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Divider,
  List,
  ListItem,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
// import NavItems from "../components/NavItems";

const navItems = {
  rates: {
    text: "Rates",
  },
  learn: {
    text: "Learn",
  },
  about: {
    text: "About",
  },
  agents: {
    text: "Real Estate Agents",
  },
  employers: {
    text: "Employers",
  },
};

export const NavbarHomeMobileMenu = () => {
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  const { loginWithRedirect } = useAuth0();

  return (
    <Box>
      <Box>
        <IconButton
          aria-label="Mobile menu"
          icon={<HamburgerIcon />}
          variant="outline"
          ref={btnRef as any}
          onClick={onOpen}
          mt={"1rem"}
        />
        <Drawer
          isOpen={isOpen}
          placement="top"
          onClose={onClose}
          finalFocusRef={btnRef as any}
          isFullHeight
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <Box p="1rem" mt="2rem">
              {/* <NavItems /> */}

              <List p="0" listStyleType="none" m="18px 8px">
                <ListItem
                  display="flex"
                  alignItems="center"
                  h="48px"
                  cursor="pointer"
                  mb="22px"
                >
                  <Flex
                    as="a"
                    alignItems="center"
                    w="100%"
                    h="100%"
                    role="group"
                    _hover={{ bg: "gray.200", borderRadius: "0.5rem" }}
                    pl="0.5rem"
                    onClick={() => {
                      loginWithRedirect({ screen_hint: "signUp" });
                      onClose();
                    }}
                  >
                    <Box
                      as="span"
                      whiteSpace="nowrap"
                      display="flex"
                      alignItems="center"
                      ml="16px"
                      color="teal.600"
                      fontSize="xl"
                      fontWeight="500"
                    >
                      Sign up
                    </Box>
                  </Flex>
                </ListItem>
                <ListItem
                  display="flex"
                  alignItems="center"
                  h="48px"
                  cursor="pointer"
                  mb="22px"
                >
                  <Flex
                    as="a"
                    alignItems="center"
                    w="100%"
                    h="100%"
                    role="group"
                    _hover={{ bg: "gray.200", borderRadius: "0.5rem" }}
                    pl="0.5rem"
                    onClick={() => {
                      loginWithRedirect();
                      onClose();
                    }}
                  >
                    <Box
                      as="span"
                      whiteSpace="nowrap"
                      display="flex"
                      alignItems="center"
                      ml="16px"
                      fontSize="xl"
                      fontWeight="500"
                      color="teal.600"
                    >
                      Log in
                    </Box>
                  </Flex>
                </ListItem>
              </List>
              <Divider m="1rem 0" />
              <List p="0" listStyleType="none" m="18px 8px">
                {Object.keys(navItems).map((field, i) => {
                  return (
                    <ListItem key={i} h="48px" cursor="pointer" mb="22px">
                      <Flex
                        as="a"
                        alignItems="center"
                        w="100%"
                        h="100%"
                        role="group"
                        _hover={{ bg: "gray.200", borderRadius: "0.5rem" }}
                        pl="0.5rem"
                        color="teal.600"
                        fontSize="xl"
                        fontWeight="500"
                        ml="16px"
                        // color={active ? "blue.600" : undefined}
                        onClick={() => {
                          history.push(`/${field}`);
                          onClose();
                        }}
                      >
                        {navItems[field].text}
                      </Flex>

                      {/* <ListItemButton
              active={activeSideNav === field}
              field={field}
              icon={navItems[field].icon}
              text={navItems[field].text}
            /> */}
                    </ListItem>
                  );
                })}
              </List>

              <Divider m="1rem 0" />

              <List p="0" listStyleType="none" m="18px 8px">
                <ListItem
                  display="flex"
                  alignItems="center"
                  h="48px"
                  cursor="pointer"
                  mb="22px"
                >
                  <Flex
                    as="a"
                    alignItems="center"
                    w="100%"
                    h="100%"
                    role="group"
                    _hover={{ bg: "gray.200", borderRadius: "0.5rem" }}
                    pl="0.5rem"
                    onClick={() => {
                      window.open("https://learn.saferate.com/");
                      onClose();
                    }}
                  >
                    <Box
                      as="span"
                      whiteSpace="nowrap"
                      display="flex"
                      alignItems="center"
                      ml="16px"
                      color="teal.600"
                      fontSize="xl"
                      fontWeight="500"
                    >
                      Blog
                    </Box>
                  </Flex>
                </ListItem>
              </List>
            </Box>
          </DrawerContent>
        </Drawer>
      </Box>
    </Box>
  );
};
