export enum Language {
  ENGLISH_US = "en-US",
  SPANISH = "es",
}

export type LanguageOrNull = Language | null;
export type LanguageOrString = Language | string;
export type LanguageOrStringOrNull = Language | string | null;
export type LanguageOrNullOrUndefined = Language | null | undefined;
export type LanguageOrStringOrNullOrUndefined =
  | Language
  | string
  | null
  | undefined;

export function getLanguageOrNull(
  language: LanguageOrStringOrNullOrUndefined
): LanguageOrNull {
  let returnValue: LanguageOrNull = null;

  if (language === Language.ENGLISH_US) {
    returnValue = language;
  } else if (language === Language.SPANISH) {
    returnValue = Language.SPANISH;
  }

  return returnValue;
}
