// eslint-disable-next-line import/no-anonymous-default-export
export default (value: string) => {
  if (value === "yes") {
    return true;
  } else if (value === "no") {
    return false;
  }

  return value;
};
