import { loader } from "graphql.macro";

export const ADD_CO_BORROWER = loader("./addCoBorrower.graphql");
export const CREATE_ONE_BETA_USER = loader("./createOneBetaUser.graphql");
export const CREATE_ONE_EMPLOYER_REFERRAL = loader(
  "./createOneEmployerReferral.graphql"
);
export const CREATE_ONE_LEAD = loader("./createOneLead.graphql");
export const CREATE_ONE_LOAN = loader("./createOneLoan.graphql");
export const CREATE_ONE_QUOTE_USER = loader("./createOneQuoteUser.graphql");
export const CREATE_ONE_QUIZ = loader("./createOneQuiz.graphql");
export const CREATE_ONE_PREAPPROVAL = loader("./createOnePreapproval.graphql");
export const CREATE_ONE_SERIES = loader("./createOneSeries.graphql");
export const CREATE_ONE_VIDEO = loader("./createOneVideo.graphql");
export const CREATE_ONE_QUIZ_RESULT = loader("./createOneQuizResult.graphql");
export const CREATE_ONE_AGENT = loader("./createOneAgent.graphql");
export const CREATE_ONE_EMPLOYER = loader("./createOneEmployer.graphql");
export const CREATE_ONE_SAVER = loader("./createOneSaver.graphql");
export const CREATE_ONE_USER = loader("./createOneUser.graphql");
export const DELETE_ONE_AGENT = loader("./deleteOneAgent.graphql");
export const DELETE_ONE_AGENT_REFERRAL = loader(
  "./deleteOneAgentReferral.graphql"
);
export const DELETE_ONE_EMPLOYER = loader("./deleteOneEmployer.graphql");
export const DELETE_ONE_EMPLOYER_REFERRAL = loader(
  "./deleteOneEmployerReferral.graphql"
);
export const DELETE_ONE_SAVER = loader("./deleteOneSaver.graphql");
export const GET_AGENT_DASHBOARD_STATS = loader(
  "./getAgentDashboardStats.graphql"
);
export const GET_AGENT_PENDING_ACTIONS = loader(
  "./getAgentPendingActions.graphql"
);
export const GET_ALL_AGENTS = loader("./getAllAgents.graphql");
export const GET_ALL_GEOGRAPHY_TEXT = loader("./getAllGeographyText.graphql");
export const GET_ALL_SAVERS = loader("./getAllSavers.graphql");
export const GET_ALL_SAVER_CREDITS_FOR_SAVER = loader(
  "./getAllSaverCreditsForSaver.graphql"
);
export const GET_ALL_SERIES = loader("./getAllSeries.graphql");
export const GET_ALL_SERIES_NAMES_AND_SLUGS = loader(
  "./getAllSeriesNamesAndSlugs.graphql"
);
export const GET_ALL_USERS = loader("./getAllUsers.graphql");
export const GET_ALL_VIDEO_CATEGORIES = loader(
  "./getAllVideoCategories.graphql"
);
export const GET_EMPLOYER_DASHBOARD_STATS = loader(
  "./getEmployerDashboardStats.graphql"
);
export const GET_KARMA_LIST = loader("./getKarmaList.graphql");
export const GET_LEAD_STATS_FOR_AGENT = loader(
  "./getLeadStatsForAgent.graphql"
);
export const GET_LOANS_STATS_FOR_AGENT = loader(
  "./getLoanStatsForAgent.graphql"
);
export const GET_ONE_AGENT = loader("./getOneAgent.graphql");
export const GET_ONE_CHARITY = loader("./getOneCharity.graphql");
export const GET_ONE_EMPLOYER = loader("./getOneEmployer.graphql");
export const GET_ONE_SAVER = loader("./getOneSaver.graphql");
export const GET_ONE_QUIZ = loader("./getOneQuiz.graphql");
export const GET_ONE_SAVER_FOR_CURRENT_USER = loader(
  "./getOneSaverForCurrentUser.graphql"
);
export const GET_ONE_USER = loader("./getOneUser.graphql");
export const GET_ONE_VIDEO = loader("./getOneVideo.graphql");
export const GET_PREAPPROVAL_LETTER = loader("./getPreapprovalLetter.graphql");
export const GET_RATE_FOR_SAVER = loader("./getRateForSaver.graphql");
export const GET_RATE_PUBLIC = loader("./getRatePublic.graphql");
export const GET_RESULT_OF_QUIZ_FOR_USER = loader(
  "./getResultOfQuizForUser.graphql"
);
export const GET_SAVER_BY_REFERRER_CODE = loader(
  "./getSaverByReferrerCode.graphql"
);
export const GET_UPLOAD_FILE_URL = loader("./getUploadFileUrl.graphql");
export const GET_VIDEO_TITLES_FOR_SERIES = loader(
  "./getVideoTitlesForSeries.graphql"
);
export const INCREMENT_VIDEO_VIEWS = loader("./incrementVideoViews.graphql");
export const INVITE_AGENT_FROM_AGENT = loader("./inviteAgentFromAgent.graphql");
export const INVITE_CLIENT_FROM_AGENT = loader(
  "./inviteClientFromAgent.graphql"
);
export const INVITE_AGENT_FROM_SAVER = loader("./inviteAgentFromSaver.graphql");
export const INVITE_EMPLOYEE_FROM_EMPLOYER = loader(
  "./inviteEmployeeFromEmployer.graphql"
);
export const LINK_AGENT_TO_SAVER = loader("./linkAgentToSaver.graphql");
export const LINK_EMPLOYER_TO_SAVER = loader("./linkEmployerToSaver.graphql");
export const ONBOARD_ONE_EMPLOYER = loader("./onboardOneEmployer.graphql");
export const REMOVE_CO_BORROWER = loader("./removeCoBorrower.graphql");
export const SEARCH_AGENTS = loader("./searchAgents.graphql");
export const SEARCH_CHARITIES = loader("./searchCharities.graphql");
export const SEARCH_EMPLOYERS = loader("./searchEmployers.graphql");
export const SEARCH_LEADS_FOR_AGENT = loader("./searchLeadsForAgent.graphql");
export const SEARCH_LOANS_FOR_AGENT = loader("./searchLoansForAgent.graphql");
export const SEND_CREDITS_TO_CHARITY = loader("./sendCreditsToCharity.graphql");
export const SEND_KARMA_CREDITS = loader("./sendKarmaCredits.graphql");
export const SEND_LETTER_OF_EXPLANATION_SIGNATURE_REQUEST = loader(
  "./sendLetterOfExplanationSignatureRequest.graphql"
);
export const SEND_PURCHASE_CONTRACT = loader("./sendPurchaseContract.graphql");
export const SEND_PURCHASE_CONTRACT_ADDENDUM_REQUEST = loader(
  "./sendPurchaseContractAddendumRequest.graphql"
);
export const UNLINK_SAVER_FROM_AGENT = loader("./unlinkSaverFromAgent.graphql");
export const UNLINK_SAVER_FROM_EMPLOYER = loader(
  "./unlinkSaverFromEmployer.graphql"
);
export const UPDATE_ONE_AGENT = loader("./updateOneAgent.graphql");
export const UPDATE_ONE_EMPLOYER = loader("./updateOneEmployer.graphql");
export const UPDATE_ONE_SAVER = loader("./updateOneSaver.graphql");
export const UPDATE_ONE_SAVER_CREDIT = loader("./updateOneSaverCredit.graphql");
export const UPDATE_ONE_USER = loader("./updateOneUser.graphql");
export const UPDATE_RATE_LOCK = loader("./updateRateLock.graphql");
export const UPDATE_SAVER_PERSONAL_FINANCE = loader(
  "./updateSaverPersonalFinance.graphql"
);
export const VERIFY_SAVER_CREDIT = loader("./verifySaverCredit.graphql");
