import React from "react";
import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  HStack,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import {
  getDefaultFormValues,
  buildValidationSchemaObj,
  callToast,
} from "../utility";
import { getDealSignupModel as model } from "./utility/models";
import { CREATE_ONE_QUOTE_USER as mutation } from "../graphql/savers/constants";
import InputField from "../components/form/InputField";
import {
  createOneQuoteUser,
  createOneQuoteUserVariables,
} from "../types/createOneQuoteUser";
import StreamlineIcon from "../components/StreamlineIcon";

type FormProps = {
  closeModal: () => void;
  emailData: any;
  dealType: "rate" | "closing" | "card";
};

const GetDealSignupForm: React.FC<FormProps> = ({ emailData, dealType }) => {
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const defaultValues = getDefaultFormValues(model);
  const [mutationCall, { loading: isSubmittingToServer }] = useMutation<
    createOneQuoteUser,
    createOneQuoteUserVariables
  >(mutation);

  const methods = useForm({
    defaultValues,
    mode: "onSubmit",
    resolver: yupResolver(buildValidationSchemaObj(model)),
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = methods;

  const onSubmit = async (input) => {
    // const input = buildInputObj({ data, dirtyFields, model });
    //add entity ID to obj
    if (input) {
      try {
        await mutationCall({
          variables: {
            input: {
              ...emailData,
              ...input,
              dealType,
            },
          },
        });

        setIsSuccessfullySubmitted(true);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const dealBtnColorScheme = (dealType: string) => {
    if (dealType === "rate") {
      return "teal";
    } else if (dealType === "closing") {
      return "purple";
    } else if (dealType === "card") {
      return "yellow";
    }
  };

  return (
    <>
      <Box p="0rem 0.5rem">
        <Box mb="1rem">
          {(() => {
            if (dealType === "rate") {
              return (
                <>
                  {isSuccessfullySubmitted ? (
                    <Box textAlign="center">
                      <StreamlineIcon
                        name="check-circle-orig-icon"
                        boxSize="5rem"
                        color="green.400"
                      />
                    </Box>
                  ) : (
                    <Box textAlign="center" mb="2rem">
                      <StreamlineIcon name="house" boxSize="7rem" />
                      <Text fontWeight="400" fontSize="md">
                        You're on your way to saving thousands on your mortgage.
                      </Text>
                    </Box>
                  )}
                </>
              );
            } else if (dealType === "closing") {
              return (
                <>
                  <Box textAlign="center" mb="2rem">
                    <StreamlineIcon name="hand-money" boxSize="7rem" />
                    <Text fontWeight="400" fontSize="md">
                      You're on your way to saving thousands off your closing.
                    </Text>
                  </Box>
                </>
              );
            } else {
              return (
                <>
                  <Box textAlign="center" mb="2rem">
                    <StreamlineIcon name="house-builder" boxSize="7rem" />
                    <Text fontWeight="400" fontSize="md">
                      You're on your way to saving thousands on your upgrade.
                    </Text>
                  </Box>
                </>
              );
            }
          })()}
        </Box>
      </Box>

      {isSuccessfullySubmitted ? (
        <Box textAlign="center">
          <Text fontWeight="500" fontSize="lg" mt="2rem" mb="2rem">
            Great! Check your email for your mortgage quote and application
            link.
          </Text>
        </Box>
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Stack direction={{ base: "column", lg: "row" }}>
                <InputField
                  autoComplete="given-name"
                  autoFocus
                  label={model.firstName.label}
                  name={model.firstName.name}
                />
                <InputField
                  autoComplete="family-name"
                  label={model.lastName.label}
                  name={model.lastName.name}
                />
              </Stack>
              <InputField
                autoComplete="email"
                label={model.email.label}
                name={model.email.name}
              />
            </Box>

            <Box textAlign="right" mt="2rem">
              <ButtonGroup spacing="6">
                <Button
                  colorScheme={dealBtnColorScheme(dealType)}
                  type="submit"
                  disabled={isSubmitting || isSubmittingToServer}
                  isLoading={isSubmitting || isSubmittingToServer}
                >
                  Get Quote
                </Button>
              </ButtonGroup>
            </Box>
          </form>
        </FormProvider>
      )}
    </>
  );
};

export default GetDealSignupForm;
