// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  data,
  dirtyFields,
  model,
  includeNonFalsyDefault = false,
}): any => {
  let isOneFieldDirty = false;
  const inputObj = {};

  if (includeNonFalsyDefault) {
    const modelKeys = Object.keys(model);
    modelKeys.forEach((modelKey) => {
      if (model[modelKey].defaultValue) {
        inputObj[modelKey] = model[modelKey].defaultValue;
      }
    });
  }

  const dirtyFieldKeys = Object.keys(dirtyFields);

  dirtyFieldKeys.forEach((key) => {
    if (dirtyFields[key]) {
      if (!isOneFieldDirty) {
        isOneFieldDirty = true;
      }

      //sometimes the input object needs to have a nested object key, for example, if you want fields like firstName, lastName to be updated they can go in user
      if (model[key]?.nestedObjectKey) {
        const { nestedObjectKey } = model[key];
        if (!inputObj[nestedObjectKey]) {
          inputObj[nestedObjectKey] = {};
        }

        inputObj[nestedObjectKey][key] = data[key];
      } else {
        inputObj[key] = data[key];
      }
    }
  });

  if (isOneFieldDirty) {
    return inputObj;
  }
};
