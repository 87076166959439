import React from "react";
import { Icon, IconProps, ComponentWithAs } from "@chakra-ui/react";

interface StreamlineIconProps extends IconProps {
  name: string;
}

const StreamlineIcon: React.FC<StreamlineIconProps> = ({
  name,
  ...rest
}): JSX.Element | null => {
  const ImportedIconRef = React.useRef<ComponentWithAs<"svg", IconProps>>();
  const [loading, setLoading] = React.useState(false);

  React.useEffect((): any => {
    setLoading(true);
    const importIcon = async (): Promise<void> => {
      try {
        ImportedIconRef.current = (
          await import(
            `!!@svgr/webpack?-svgo,+titleProp,+ref!../streamline-icons/${name}.svg`
          )
        ).default;
      } catch (err) {
        // Your own error handling logic, throwing error for the sake of
        // simplicity
        throw err;
      } finally {
        setLoading(false);
      }
    };

    importIcon();

    return () => {
      setLoading(false);
    };
  }, [name]);

  if (!loading && ImportedIconRef.current) {
    const { current: ImportedIcon } = ImportedIconRef;

    return <Icon as={ImportedIcon} {...rest} title={name} />;
  }

  return null;
};

export default StreamlineIcon;
