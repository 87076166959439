import numeral from "numeral";

// eslint-disable-next-line import/no-anonymous-default-export
export default (value, originalValue) => {
  if (originalValue === "") return undefined;

  if (originalValue) {
    return numeral(originalValue).value();
  }
  return value;
};
