import { User } from "@auth0/auth0-react";

// eslint-disable-next-line import/no-anonymous-default-export
export default (user: User | undefined): boolean => {
  if (!user) return false;

  const location = "https://saferate.com";

  const key = `${location}/roles`;

  if (!key) return false;

  return user[key].includes("admin");
};
