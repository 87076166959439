import { createBrowserHistory } from "history";
import ReactGA from "react-ga";

const history = createBrowserHistory();

history.listen(() => {
  ReactGA.pageview(window.location.pathname);
});

export default history;
