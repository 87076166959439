import {
  ApolloLink,
  Observable,
  HttpLink,
  createHttpLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import { callToast } from "../utility";

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, response }) => {
    console.log("graphQLErrors", graphQLErrors);

    if (graphQLErrors) {
      if (
        graphQLErrors[0] &&
        graphQLErrors[0].message &&
        graphQLErrors[0].message.includes("No Data Found")
      ) {
        return;
      }
      callToast({
        title: "Error",
        description:
          "Ut oh! Something went wrong. Reach out to us at team@saferate.com for support.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-right",
      });
    }
    // if (graphQLErrors) {
    //   let description = "";
    //   graphQLErrors.forEach((error) => {
    //     description += `${error.message}\n`;
    //   });

    //   callToast({
    //     title: "Error",
    //     description,
    //     status: "error",
    //     duration: 5000,
    //     isClosable: true,
    //     position: "bottom-right",
    //   });
    //   graphQLErrors.forEach(({ message }) => {
    //     console.error(message);
    //   });
    // }

    // if (networkError) {
    //   console.error(`[Network error]: ${networkError}`);
    // }
  }
);

const isDev = process.env.NODE_ENV === "development";

const getLinks = (accessToken: string, idToken: string): any => {
  const saversUri = isDev
    ? `http://${process.env.REACT_APP_SERVER_HOST}/graphql`
    : `https://${process.env.REACT_APP_SERVER_HOST}/graphql`;

  const httpLinkSavers = new HttpLink({
    uri: saversUri,
    credentials: "include",
  });

  const requestLink = new ApolloLink(
    (operation, forward) =>
      new Observable((observer) => {
        let handle: any;
        Promise.resolve(operation)
          .then((operation) => {
            if (accessToken && idToken) {
              operation.setContext({
                headers: {
                  authorization: `bearer ${accessToken}`,
                  idToken,
                },
              });
            }
          })
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            });
          })
          .catch(observer.error.bind(observer));

        return (): void => {
          if (handle) handle.unsubscribe();
        };
      })
  );

  //remove __typename from all mutation variables
  const omitTypename = (key, value) => {
    return key === "__typename" ? undefined : value;
  };

  const omitTypenameLink = new ApolloLink((operation, forward) => {
    if (operation.variables) {
      operation.variables = JSON.parse(
        JSON.stringify(operation.variables),
        omitTypename
      );
    }
    return forward(operation);
  });

  const saversLink = ApolloLink.from([
    requestLink,
    omitTypenameLink,
    errorLink,
    httpLinkSavers,
  ]);

  if (!accessToken) {
    return { saversLink, servicingLink: null };
  }

  const servicingUrl = process.env.REACT_APP_SERVICING_GRAPHQL_URL;
  const httpLinkServicing = createHttpLink({ uri: servicingUrl });

  const authLink = createAuthLink({
    url: servicingUrl,
    region: "us-east-1",
    auth: {
      type: "OPENID_CONNECT",
      jwtToken: async () => accessToken,
    },
  });

  const servicingLink = ApolloLink.from([
    authLink,
    errorLink,
    createSubscriptionHandshakeLink(servicingUrl, httpLinkServicing),
  ]);

  return { saversLink, servicingLink };
};

export default getLinks;
