import React from "react";
import { Icon } from "@chakra-ui/react";

type SafeRateLogoProps = {
  boxSize: string;
};

export const SafeRateLogo = (props: SafeRateLogoProps) => {
  return (
    <Icon viewBox="0 0 316 222" {...props}>
      <path
        d="M132.79 28.82c5.12-3 10.31-5.94 15.33-9.12a4.69 4.69 0 015.8 0c5 3.18 10.21 6.08 15.33 9.09 1.62 1.12 3.19 2.32 4.87 3.35Q205.05 51.13 236 70c2.34 1.43 4.05 2.68 4 6-.09 36.61 0 73.23.09 109.84H61.84c0-32.64.72-65.29-.19-97.9-.29-11.73 3.25-18.17 13.55-23.86 19.68-10.88 38.45-23.41 57.59-35.26zM113 89c.93 6.39 1.81 12.79 2.79 19.17 3.06 19.87 13.3 34.86 30.55 45.23 3.45 2.07 6.18 2 9.57-.08 12.52-7.54 21.67-17.84 26.94-31.51 4.1-10.64 4.27-22 6.39-33.08.33-1.72-.75-2.78-2.09-3.62C175.75 78 164.42 70.83 153 63.76c-1.14-.7-2.41-1.34-4.08-.26-11.36 7.36-22.82 14.56-34.24 21.81A3.29 3.29 0 00113 89z"
        fill="#3a4398"
      />
      <path
        fill="#c0deef"
        d="M132.79 28.82C113.65 40.67 94.88 53.2 75.2 64.08 64.9 69.77 61.36 76.21 61.69 88c.91 32.61.23 65.26.19 97.9-5.64 0-11.28 0-16.92.14-2.79.08-4.21-.53-4.2-3.78q.15-57.64.05-115.28c0-2.4 1.13-3.64 2.88-4.73 22.53-14.13 45.09-28.2 67.54-42.46 2.58-1.63 4.43-1.32 6.7.08 4.92 3.04 9.91 5.97 14.86 8.95zM240.16 185.9c-.06-36.61-.18-73.23-.09-109.84 0-3.34-1.7-4.59-4-6q-31-18.87-61.91-37.87c-1.68-1-3.25-2.23-4.87-3.35 5-3 9.91-6 14.9-8.94 2-1.17 3.8-2.18 6.35-.55Q223 40 255.61 60.36c3.31 2.07 4.68 4.36 4.67 8.4-.15 37.16-.06 74.32 0 111.47v5.67z"
      />
      <path
        fill="#c0deef"
        d="M61.84 185.87H240.2v10.43c0 6.53 0 6.54-6.34 6.54h-172z"
      />
      <path
        stroke="#3a4398"
        strokeMiterlimit="10"
        fill="#3a4398"
        d="M98.89 57h102v108h-102z"
      />
      <path
        fill="#fff"
        d="M189.82 125.32L150 165.18l-39.9-39.86C76.54 91.76 116.4 51.9 150 85.46c33.66-33.69 73.52 6.17 39.82 39.86z"
      />
    </Icon>
  );
};

export const SafeRateLogoWithText = (props: SafeRateLogoProps) => {
  return (
    <Icon
      {...props}
      viewBox="0 0 1750 350"
      style={{ width: "12rem", height: "5rem" }}
    >
      {/* <g id="Layer_1" display="none"></g> */}
      <g id="Layer_2">
        <g>
          <g>
            <defs>
              <rect
                id="SVGID_1_"
                x="-17.73"
                y="-62.02"
                width="563.54"
                height="573.52"
              />
            </defs>
            <clipPath id="SVGID_00000021114252366331873470000002291204013400451220_">
              <use xlinkHref="#SVGID_1_" overflow="visible" />
            </clipPath>
            <g clipPath="url(#SVGID_00000021114252366331873470000002291204013400451220_)">
              <path
                fill="#BFDEF0"
                d="M152.77,232.55c123,181.06,30.48,123.88,294.82,132.57c-2.51,10.5,7.7,40.36-8.22,39.76
					c-70.97-0.14-141.94-0.14-212.91-0.01c-5.43,0.01-8.87-2.43-11.46-6.57c-25.38-40.54-50.61-81.18-76.14-121.62
					C128.47,260.32,148.54,247.52,152.77,232.55z"
              />
              <path
                fill="#BFDEF0"
                d="M411.19,232.29c-123,181.06-30.48,123.88-294.82,132.57c2.51,10.5-7.7,40.36,8.22,39.76
					c70.97-0.14,141.94-0.14,212.91-0.01c5.43,0.01,8.87-2.43,11.46-6.57c25.38-40.54,50.61-81.18,76.14-121.62
					C435.49,260.06,415.41,247.26,411.19,232.29z"
              />
              <path
                fill="#BFDEF0"
                d="M311.75,71.51c181.06,123,123.88,30.48,132.57,294.82c10.5-2.51,40.36,7.7,39.76-8.22
					c-0.14-70.97-0.14-141.94-0.01-212.91c0.01-5.43-2.43-8.87-6.57-11.46c-40.54-25.38-81.18-50.61-121.62-76.14
					C339.52,47.21,326.72,67.29,311.75,71.51z"
              />
              <g>
                <path
                  fill="#394499"
                  d="M248.25,72.24c36.55-22.04,30.49-22.35,67-0.05c184.68,126.47,122.13,23.74,133.03,284.77
						c0.05,8.33-2.17,10.24-10.33,10.22c-107.44-0.28-214.88-0.18-322.32-0.17c0,0,0.05,0.05,0.05,0.05
						C124.39,102.6,67.14,195.31,248.25,72.24z"
                />
                <path
                  fill="#BFDEF0"
                  d="M248.24,72.24c-181.06,123-123.88,30.48-132.57,294.82c-10.5-2.51-40.36,7.7-39.76-8.22
						c0.14-70.97,0.14-141.94,0.01-212.91c-0.01-5.43,2.43-8.87,6.57-11.46c40.54-25.38,81.18-50.61,121.62-76.14
						C220.48,47.94,233.28,68.01,248.24,72.24z"
                />
              </g>
            </g>
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            d="M286.49,191.92c39.2-103.1,192.06,0,0,132.55
			C94.43,191.92,247.29,88.83,286.49,191.92"
          />
        </g>
      </g>
      <g id="Layer_3">
        <g>
          <g id="Group-3_00000054974395639462430470000014348047605706795163_">
            <g
              id="Group-2_00000109730449230943961960000015381708557444865422_"
              transform="translate(0.000000, 172.000000)"
            >
              <path
                id="SafeRate_00000142156246692024275680000016610673799835549323_"
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#232691"
                d="
					M677.09,100.92c0-5.19-1.73-9.52-4.76-12.99c-3.46-3.46-7.36-6.06-12.55-8.23c-5.19-2.16-10.82-3.9-17.32-5.19
					c-6.49-1.3-12.55-3.46-19.05-6.06c-6.49-2.6-12.12-5.19-17.32-8.66c-5.19-3.03-9.52-7.79-12.99-13.85
					c-3.46-6.06-4.76-13.42-4.76-22.08c0-12.99,4.76-23.81,14.72-32.47s23.38-12.99,40.26-12.99c6.06,0,12.12,0.87,18.18,2.16
					c6.06,1.3,10.39,3.46,14.29,5.19s6.93,4.33,9.96,6.06c3.03,2.16,4.76,3.9,6.06,5.19l2.16,3.03l-16.88,16.88
					c-0.43-0.43-0.87-0.87-1.3-1.73c-0.43-0.87-1.73-1.73-3.9-3.46s-4.33-3.03-6.49-4.33c-2.6-1.3-5.63-2.6-9.52-3.46
					c-3.9-0.87-7.79-1.73-12.12-1.73c-8.66,0-15.58,2.16-20.78,6.49s-7.79,9.52-7.79,15.15c0,5.19,1.73,9.52,4.76,12.99
					c3.46,3.46,7.36,6.49,12.55,8.23c5.19,2.16,10.82,3.9,17.32,5.19c6.49,1.3,12.55,3.46,19.05,6.06
					c6.49,2.16,12.12,5.19,17.32,8.23c5.19,3.46,9.52,7.79,12.55,14.29c3.46,6.06,4.76,13.42,4.76,21.64
					c0,14.72-5.19,26.84-16.02,36.36c-10.82,9.52-25.11,14.29-44.16,14.29c-7.79,0-15.15-0.87-21.64-2.6
					c-6.93-1.73-12.55-3.9-16.88-6.49s-8.23-4.76-11.69-7.36c-3.46-2.6-5.63-4.76-7.36-6.49l-2.16-3.03l16.88-16.88
					c0.43,0.43,0.87,1.3,1.73,2.16s2.6,2.6,5.19,4.76c2.6,2.16,5.63,4.33,8.66,6.06s6.49,3.03,11.69,4.33
					c5.19,1.3,10.39,2.16,16.02,2.16c10.39,0,18.61-2.6,24.68-7.79C674.06,114.77,677.09,108.71,677.09,100.92z M823.85,149.4H799.6
					v-12.12c-0.43,0.43-0.87,0.87-1.73,1.73c-0.87,0.87-2.16,1.73-4.33,3.46s-4.76,3.03-7.36,4.33c-2.6,1.3-5.63,2.6-9.52,3.46
					c-3.9,0.87-7.79,1.73-11.69,1.73c-11.69,0-21.64-3.46-29-10.39c-7.79-6.93-11.69-14.72-11.69-23.38
					c0-9.52,3.03-17.32,9.09-23.81c6.06-6.49,14.29-10.39,24.68-12.12l41.99-7.36c0-6.06-2.16-11.26-6.93-15.58
					s-10.39-6.06-17.32-6.06c-6.06,0-11.69,1.3-16.45,3.9s-8.23,5.19-10.39,7.79l-3.03,3.9L730.34,53.3c0.43-0.43,1.3-1.3,1.73-2.6
					c0.87-0.87,2.6-2.6,5.63-5.19c3.03-2.6,6.06-4.76,9.09-6.49s7.36-3.9,12.55-5.19c5.19-1.73,10.82-2.16,16.45-2.16
					c13.85,0,25.54,4.33,34.63,12.55s13.42,18.61,13.42,30.74C823.85,74.94,823.85,149.4,823.85,149.4z M768.44,129.92
					c9.96,0,17.32-3.03,22.94-8.66s8.23-14.29,8.23-25.11v-2.16l-37.23,6.06c-4.33,0.87-7.79,2.6-10.39,5.19
					c-2.6,2.6-3.9,6.06-3.9,10.39c0,3.9,1.73,7.36,5.63,9.96S762.38,129.92,768.44,129.92z M914.75,33.82v21.64h-26.41v93.94h-23.81
					V55.46h-16.88V33.82h16.88V14.34c0-24.24,11.26-35.93,33.33-35.93c3.03,0,5.63,0,8.66,0.43c2.6,0.43,4.76,0.87,6.06,1.3
					l2.16,0.43V2.22c-3.9-1.3-7.79-2.16-12.12-2.16c-4.33,0-7.79,1.3-10.39,3.9c-2.6,2.6-3.9,6.06-3.9,10.39v19.05L914.75,33.82
					L914.75,33.82z M1045.49,100.05h-90.04c1.3,8.23,5.19,15.15,12.12,21.21c6.93,6.06,14.72,9.09,24.24,9.09
					c6.93,0,12.99-1.73,18.61-4.76c5.63-3.03,9.96-6.06,12.55-9.09l3.46-4.33l15.58,15.58c-0.43,0.43-1.3,1.3-2.16,2.6
					s-3.03,3.03-6.06,5.63c-3.03,2.6-6.49,5.19-10.39,7.36c-3.9,2.16-8.23,3.9-13.85,5.63c-6.06,1.73-11.69,2.6-18.18,2.6
					c-16.45,0-30.3-6.06-42.42-18.18s-17.75-26.41-17.75-43.29c0-16.02,5.63-30.3,17.32-41.56c11.69-11.69,25.54-17.32,41.56-17.32
					s29.44,5.19,40.26,16.02s16.02,24.24,16.02,40.26L1045.49,100.05z M990.51,52c-8.66,0-16.02,2.6-21.64,7.79
					c-5.63,5.19-9.96,11.69-11.69,19.91h66.67c-1.3-8.23-5.19-14.72-10.82-19.91C1007.39,54.6,999.17,52,990.51,52z M1081.42,149.4
					V-19.43h70.99c15.58,0,28.57,4.76,38.53,14.72s15.15,22.08,15.15,36.8c0,6.93-1.3,13.42-3.46,19.48
					c-2.16,6.06-5.19,10.82-8.22,14.29c-3.46,3.46-6.49,6.49-9.96,9.09c-3.46,2.6-6.06,4.33-8.23,5.19l-3.46,1.73l38.53,67.1h-29
					l-35.93-64.93h-38.53v64.93L1081.42,149.4L1081.42,149.4z M1171.9,52.43c5.19-5.19,7.79-12.12,7.79-19.91s-2.6-14.29-7.79-19.91
					c-5.19-5.63-11.69-7.79-19.48-7.79h-44.59v55.41h44.59C1160.21,60.22,1166.7,57.63,1171.9,52.43z M1333.37,149.4h-24.24v-12.12
					c-0.43,0.43-0.87,0.87-1.73,1.73c-0.87,0.87-2.16,1.73-4.33,3.46s-4.76,3.03-7.36,4.33c-2.6,1.3-5.63,2.6-9.52,3.46
					c-3.9,0.87-7.79,1.73-11.69,1.73c-11.69,0-21.64-3.46-29-10.39c-7.36-6.93-11.69-14.72-11.69-23.38
					c0-9.52,3.03-17.32,9.09-23.81c6.06-6.49,14.29-10.39,24.68-12.12l41.99-7.36c0-6.06-2.16-11.26-6.93-15.58
					c-4.33-4.33-10.39-6.06-17.32-6.06c-6.06,0-11.69,1.3-16.45,3.9c-4.76,2.6-8.23,5.19-10.39,7.79l-3.03,3.9l-15.58-15.58
					c0.43-0.43,1.3-1.3,1.73-2.6c0.43-1.3,2.6-2.6,5.63-5.19c3.03-2.6,6.06-4.76,9.09-6.49c3.03-1.73,7.36-3.9,12.55-5.19
					c5.19-1.73,10.82-2.16,16.45-2.16c13.85,0,25.54,4.33,34.63,12.55c9.09,8.23,13.42,18.61,13.42,30.74V149.4L1333.37,149.4z
					 M1278.39,129.92c9.96,0,17.32-3.03,22.94-8.66s8.22-14.29,8.22-25.11v-2.16l-37.23,6.06c-4.33,0.87-7.79,2.6-10.39,5.19
					s-3.9,6.06-3.9,10.39c0,3.9,1.73,7.36,5.63,9.96S1271.89,129.92,1278.39,129.92z M1381.42,19.1V-4.71h21.64v38.53h24.24v21.64
					h-24.24v59.74c0,4.33,1.3,7.79,3.9,10.39c2.6,2.6,6.06,3.9,10.39,3.9c1.73,0,3.9,0,5.63-0.43c2.16-0.43,3.46-0.87,4.76-1.3
					l1.73-0.43v21.64c-4.76,1.73-10.39,2.16-16.88,2.16c-22.51,0-33.77-12.12-33.33-35.93V55.46h-21.64V33.82h12.12
					C1377.52,33.82,1381.42,29.06,1381.42,19.1z M1564.96,100.05h-90.04c1.3,8.23,5.19,15.15,12.12,21.21
					c6.93,6.06,14.72,9.09,24.24,9.09c6.93,0,12.99-1.73,18.61-4.76c5.63-3.03,9.96-6.06,12.55-9.09l3.46-4.33l15.58,15.58
					c-0.43,0.43-1.3,1.3-2.16,2.6c-0.87,1.3-3.03,3.03-6.06,5.63c-3.03,2.6-6.49,5.19-10.39,7.36c-3.46,2.16-8.22,3.9-13.85,5.63
					s-11.69,2.6-17.75,2.6c-16.45,0-30.3-6.06-42.42-18.18c-11.69-12.12-17.75-26.41-17.75-43.29c0-16.02,5.63-30.3,17.32-41.56
					c11.69-11.69,25.54-17.32,41.56-17.32c16.02,0,29.44,5.19,40.26,16.02c10.82,10.82,16.02,24.24,16.02,40.26L1564.96,100.05z
					 M1509.55,52c-8.66,0-16.02,2.6-21.64,7.79c-6.06,5.19-9.96,11.69-11.69,19.91h66.67c-1.3-8.23-5.19-14.72-10.82-19.91
					C1526.44,54.6,1518.64,52,1509.55,52z"
              />
            </g>
          </g>
        </g>
      </g>
    </Icon>
  );
};
