import { Box } from "@chakra-ui/react";
import React from "react";

const ComponentError = () => {
  return (
    <Box textAlign="center">
      <Box fontSize="2xl" mt="1rem" fontWeight="bold">
        <Box>Ut oh! There was a problem fetching the data</Box>
      </Box>
    </Box>
  );
};

export default ComponentError;
