import React from "react";
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  InputGroup,
  Input,
  FormLabel,
  InputLeftElement,
  InputRightElement,
  Button,
  IconButton,
} from "@chakra-ui/react";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { useFormContext, Controller } from "react-hook-form";
import { SmallCloseIcon } from "@chakra-ui/icons";

const defaultMaskOptions = {
  prefix: "",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ",",
  allowDecimal: true,
  decimalSymbol: ".",
  decimalLimit: 1, // how many digits allowed after the decimal
  allowNegative: false,
  allowLeadingZeroes: false,
  minValue: 0,
  maxValue: 100,
  integerLimit: 3,
};

export default function PercentInputField(props) {
  const {
    decimalLimit,
    integerLimit,
    ghostLabel,
    helperText,
    label,
    name,
    style,
    ...rest
  } = props;
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  if (decimalLimit) {
    defaultMaskOptions.decimalLimit = decimalLimit;
  }

  if (integerLimit) {
    defaultMaskOptions.integerLimit = integerLimit;
  }

  const percentMask = createNumberMask(defaultMaskOptions);

  return (
    <FormControl isInvalid={errors[name]} style={style ? style : {}}>
      <FormLabel style={ghostLabel ? { opacity: 0 } : {}}>
        {(() => {
          if (label) {
            return label;
          }

          // if (ghostLabel) {
          //   return "";
          // }

          return "";
        })()}
      </FormLabel>
      <InputGroup>
        <InputLeftElement pointerEvents="none" color="gray.400" children="%" />
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <MaskedInput
              {...field}
              mask={percentMask}
              type="text"
              {...rest}
              render={(ref, props) => (
                <Input
                  ref={ref}
                  {...props}
                  paddingInlineEnd={"var(--chakra-space-7)"}
                  paddingInlineStart={"var(--chakra-space-7)"}
                  bgColor="white"
                />
              )}
            />
          )}
        />
        <InputRightElement width="3.0rem">
          <IconButton
            aria-label="Clear"
            icon={<SmallCloseIcon />}
            onClick={() => {
              setValue(name, "", { shouldDirty: true });
            }}
            size={"xs"}
          />
        </InputRightElement>
      </InputGroup>
      <FormErrorMessage>
        {errors[name] && errors[name].message}
      </FormErrorMessage>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}
