import _ from "lodash";

// eslint-disable-next-line import/no-anonymous-default-export
export default (obj: any, arr: string[]) => {
  if (!_.isObject(obj) || arr.length < 1) {
    return false;
  }

  let hasAValue = false;

  for (let i = 0; i < arr.length; i++) {
    const key = arr[i];

    if (obj[key]) {
      if (_.isArray(obj[key])) {
        if (obj[key].length) {
          hasAValue = true;
        }
      } else {
        hasAValue = true;
      }

      break;
    }
  }

  return hasAValue;
};
