import { setImmer } from "./utility";
import {
  getNowUTCISO,
  getNowUTCDate,
  DateFormat,
} from "./utility/datetime/datetime";
import { APP_DEFAULTS } from "./utility/app/appDefaults";
import create from "zustand";
import produce from "immer";
import { Language, LanguageOrString } from "./utility/languages/language";
import { StringOrNull } from "./utility/scalars/scalar";
import { getOneUser_getOneUser } from "./types/getOneUser";

export type GeneralStoreState = {
  activeLearnNavIndex: number;
  activeSettingsNavIndex: number;
  borrowerId: StringOrNull;
  currentUser: getOneUser_getOneUser | null;
  entityId: StringOrNull;
  hasUnsavedData: boolean;
  initialLoad: boolean;
  interestRatePrecision: number;
  language: LanguageOrString;
  serverTime: string;
  serverDate: string;

  setStoreObj: (setObj: any) => void;
  setImmerObj: (obj: any) => void;
};

const useGeneralStore = create<GeneralStoreState>((set) => ({
  // Key Value
  activeLearnNavIndex: 0,
  activeSettingsNavIndex: 0,
  borrowerId: null, // relevant if the servicer if the servicer is browsing as a borrower
  currentUser: null, // holds currentUser data not provided by auth0
  entityId: null, // relevant if the servicer if the servicer is browsing as a borrower
  hasUnsavedData: false,
  initialLoad: true,
  interestRatePrecision: APP_DEFAULTS.INTEREST_RATE_PRECISION,
  language: Language.ENGLISH_US,
  serverTime: getNowUTCISO(),
  serverDate: getNowUTCDate(DateFormat.YYYY_MM_DD),

  // Functions
  setImmerObj: (obj) => set(produce(setImmer(obj))),
  setStoreObj: (setObj) => set(setObj),
}));

const generalStore: GeneralStoreState = useGeneralStore.getState();

export { generalStore, useGeneralStore };
