import React from "react";
import PercentInputField from "../components/form/PercentInputField";

const CurrentRate = (props) => {
  return (
    <React.Fragment>
      <PercentInputField
        name={props.model[props.formGroup].currentRate.name}
        autoFocus={props.autoFocus !== undefined ? props.autoFocus : true}
      />
    </React.Fragment>
  );
};

export default CurrentRate;
