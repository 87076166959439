import {
  checkIfObjectHasAnyOfArrayOfKeys,
  checkIfObjectHasAllOfArrayOfKeys,
} from ".";
import { getOneUser_getOneUser_agent } from "../types/getOneUser";

// eslint-disable-next-line import/no-anonymous-default-export
export default (
  data: getOneUser_getOneUser_agent | null | undefined
): number => {
  if (!data) {
    return 0;
  }

  let percentComplete = 10; //Start with 10 because everyone onboards

  //Contact/Social Media
  if (
    checkIfObjectHasAnyOfArrayOfKeys(data, [
      "contactEmail",
      "facebookProfile",
      "instagramProfile",
      "linkedInProfile",
      "mobilePhone",
      "officePhone",
      "twitterProfile",
      "youTubeProfile",
    ])
  ) {
    percentComplete += 10;
  }

  //Uploading Video
  if (checkIfObjectHasAnyOfArrayOfKeys(data, ["introVideoUrl"])) {
    percentComplete += 25;
  }

  //Adding Awards
  if (checkIfObjectHasAnyOfArrayOfKeys(data, ["awards"])) {
    percentComplete += 10;
  }

  //Adding Designations
  if (checkIfObjectHasAnyOfArrayOfKeys(data, ["designations"])) {
    percentComplete += 10;
  }

  //Adding States Licensed
  if (checkIfObjectHasAnyOfArrayOfKeys(data, ["statesLicensed"])) {
    percentComplete += 10;
  }

  if (
    checkIfObjectHasAllOfArrayOfKeys(data, [
      "about",
      "areasOfInterest",
      "focusGeographies",
      "languages",
    ])
  ) {
    percentComplete += 25;
  }

  console.log("percentComplete", percentComplete);

  return percentComplete;
};
