import { CalendarIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { getOneUser_getOneUser_saver_chat } from "../types/getOneUser";
import ChatDetails from "./ChatDetails";

const ChatDetailsModal = ({
  upcomingChat,
}: {
  upcomingChat: getOneUser_getOneUser_saver_chat;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef();
  const finalRef = React.useRef();
  return (
    <Box>
      <Button
        onClick={onOpen}
        colorScheme="purple"
        variant="outline"
        leftIcon={<CalendarIcon />}
      >
        <Text fontWeight="600" as="span">
          You have a chat on{" "}
        </Text>
        &nbsp;
        <Text fontWeight="600">
          {moment(upcomingChat.startTime).format("dddd, MMMM D [at] h:mma")}
        </Text>
      </Button>

      <Modal
        initialFocusRef={initialRef as any}
        finalFocusRef={finalRef as any}
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Your chat details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ChatDetails chat={upcomingChat} onClose={onClose} />
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ChatDetailsModal;
