import React, { useEffect, useRef } from "react";
import { Box, HStack } from "@chakra-ui/react";
import StreamlineIcon from "../../components/StreamlineIcon";
import DollarInputField from "./DollarInputField";
import PercentInputField from "./PercentInputField";
import numeral from "numeral";
import { useFormContext, useWatch } from "react-hook-form";

export default function DollarPercentField(props) {
  const {
    dollarName,
    iconSize,
    percentName,
    totalName,
    totalValuePassedDown,
    label,
    inputSpacing,
    percentStyle,
    ...rest
  } = props;
  const { setValue } = useFormContext();
  const dollarRef = useRef(false);
  const percentRef = useRef(false);
  const dollarValue = useWatch({ name: dollarName });
  const percentValue = useWatch({ name: percentName });
  const totalWatch = useWatch({ name: totalName });
  const totalValue = totalValuePassedDown
    ? totalValuePassedDown
    : totalWatch
    ? totalWatch
    : null;

  useEffect(() => {
    if (!totalValue) {
      return;
    }

    if (percentValue === "") {
      setValue(dollarName, "");
    }
    if (dollarRef.current) {
      dollarRef.current = false;
      return;
    }

    if (percentValue) {
      const newValue =
        numeral(totalValue).value()! * (numeral(percentValue).value()! * 0.01);

      setValue(dollarName, numeral(newValue).format("0,0"));
      percentRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [percentValue]);

  useEffect(() => {
    if (!totalValue) {
      return;
    }

    if (dollarValue === "") {
      setValue(percentName, "");
      return;
    }
    if (percentRef.current) {
      percentRef.current = false;
      return;
    }

    if (dollarValue && !percentRef.current) {
      const newValue =
        (numeral(dollarValue).value()! / numeral(totalValue).value()!) * 100;

      setValue(percentName, numeral(newValue).format("0.0"));
      dollarRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dollarValue]);

  return (
    <HStack spacing={inputSpacing ? inputSpacing : "24px"}>
      <DollarInputField
        max={numeral(totalValue).value()!}
        name={dollarName}
        label={label}
        {...rest}
      />
      <StreamlineIcon
        name="data-transfer"
        boxSize={iconSize ? iconSize : "2rem"}
        style={{ marginTop: label ? 20 : 0 }}
      />
      <PercentInputField
        ghostLabel={label}
        name={percentName}
        style={percentStyle ? percentStyle : undefined}
        {...rest}
      />
    </HStack>
  );
}
