import React from "react";
import DollarPercentField from "../components/form/DollarPercentField";
import { useGeneralStore } from "../generalStore";

const DownPayment = (props) => {
  const currentUser = useGeneralStore((state) => state.currentUser);

  return (
    <React.Fragment>
      <DollarPercentField
        dollarName={props.model[props.formGroup].downPayment.name}
        percentName={props.model[props.formGroup].downPaymentPercent.name}
        state={props.state}
        totalName={props.model.purchasePrice.purchasePrice.name}
        totalValuePassedDown={currentUser?.saver?.purchasePrice}
      />
    </React.Fragment>
  );
};

export default DownPayment;
