import { loader } from "graphql.macro";

export const ADD_BORROWER_BANK_ACCOUNTS_VIA_PLAID = loader(
  "./addBorrowerBankAccountsViaPlaid.graphql"
);
export const DELETE_BORROWER_BANK_ACCOUNT = loader(
  "./deleteBorrowerBankAccount.graphql"
);

export const DELETE_BORROWER_PAYMENT = loader(
  "./deleteBorrowerPayment.graphql"
);

export const GET_BORROWER_ACCOUNT_SUMMARY = loader(
  "./getBorrowerAccountSummary.graphql"
);
export const GET_AUTO_PAYMENTS = loader("./getAutoPayments.graphql");
export const GET_BORROWER_BANK_ACCOUNTS = loader(
  "./getBorrowerBankAccounts.graphql"
);

export const GET_BORROWER_FILE_SIGNED_URL = loader(
  "./getBorrowerFileSignedURL.graphql"
);
export const GET_BORROWER_FILES = loader("./getBorrowerFiles.graphql");
export const GET_BORROWER_PAYMENTS = loader("./getBorrowerPayments.graphql");
export const GET_BORROWER_STATEMENTS = loader(
  "./getBorrowerStatements.graphql"
);
export const GET_PAYOFF_AMOUNT = loader("./getPayoffAmount.graphql");

export const GET_PLAID_LINK_TOKEN = loader("./getPlaidLinkToken.graphql");

export const GET_SERVER_TIME = loader("./getServerTime.graphql");
export const SET_BORROWER_PRIMARY_BANK_ACCOUNT = loader(
  "./setBorrowerPrimaryBankAccount.graphql"
);
export const UPDATE_BORROWER_AUTO_PAYMENT = loader(
  "./updateBorrowerAutoPayment.graphql"
);
export const UPDATE_BORROWER_PAYMENT = loader(
  "./updateBorrowerPayment.graphql"
);
