const setImmer = ({ data, keys }) => (state) => {
  if (keys) {
    let storeObj = state;
    const splitKeys = keys.split(".");

    for (let i = 0; i < splitKeys.length; i++) {
      const key = splitKeys[i];
      if (i === splitKeys.length - 1) {
        storeObj[key] = data;
      } else {
        if (!storeObj[key]) {
          storeObj[key] = {};
        }
        storeObj = storeObj[key];
      }
    }
  }
};

export default setImmer;
