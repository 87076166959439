import numeral from "numeral";

// eslint-disable-next-line import/no-anonymous-default-export
export default (v: string | null | undefined): number | null => {
  if (v === null || v === undefined) {
    return null;
  }

  const returnValue = numeral(v).value()! * 0.01;

  return returnValue;
};
