import * as Yup from "yup";
import {
  booleanToYesNoString,
  floatToDollarCentsString,
  floatToInterestRateString,
  transformStringToNumber,
  transformYesNoToBoolean,
} from "../../utility";
import { defaultRequiredErrorMessage } from "../../utility/constants";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  annualIncome: {
    annualIncome: {
      defaultValue: "",
      name: "annualIncome",
      serverTransform: floatToDollarCentsString,
      validation: Yup.number()
        .transform(transformStringToNumber)
        .min(0, "Minimum acceptable home value is $0")
        /* .max(999999, "Maximum acceptable income is $999,999") */
        .required(defaultRequiredErrorMessage),
    },
  },
  creditCurrentCategory: {
    creditCurrentCategory: {
      defaultValue: "exceptional",
      name: "creditCurrentCategory",
      validation: Yup.string().trim().required(defaultRequiredErrorMessage),
    },
  },
  downPayment: {
    downPayment: {
      defaultValue: "",
      name: "downPayment",
      serverTransform: floatToDollarCentsString,
      validation: Yup.number()
        .transform(transformStringToNumber)
        .required(defaultRequiredErrorMessage),
    },
    downPaymentPercent: {
      defaultValue: "",
      name: "downPaymentPercent",
      serverTransform: floatToInterestRateString,
      validation: Yup.number()
        .transform(transformStringToNumber)
        .min(5.0, "Minimum allowable down payment for our loan programs is 5%")
        .max(100, "Maximum allowable down payment is 100%")
        .required(defaultRequiredErrorMessage),
    },
  },
  estimatedLoanDate: {
    estimatedLoanDateMonth: {
      defaultValue: "",
      name: "estimatedLoanDateMonth",
      placeholder: "Month",
      validation: Yup.string().trim().required(defaultRequiredErrorMessage),
    },
    estimatedLoanDateYear: {
      defaultValue: "",
      name: "estimatedLoanDateYear",
      placeholder: "Year",
      validation: Yup.string().trim().required(defaultRequiredErrorMessage),
    },
  },
  financialHelp: {
    financialBestRate: {
      defaultValue: false,
      label: "Get the best rate for my mortgage loan",
      name: "financialBestRate",
    },
    financialDownPayment: {
      defaultValue: false,
      label: "Save for a down payment",
      name: "financialDownPayment",
    },
    financialImproveCredit: {
      defaultValue: false,
      label: "Improve my credit score",
      name: "financialImproveCredit",
    },
    financialReduceDebt: {
      defaultValue: false,
      label: "Reduce my monthly debt",
      name: "financialReduceDebt",
    },
    financialResolveBankrupt: {
      defaultValue: false,
      label: "Resolve a bankruptcy or negative credit event",
      name: "financialResolveBankrupt",
    },
    financialTalk: {
      defaultValue: false,
      label: "Talk to someone about my mortgage options",
      name: "financialTalk",
    },
  },
  firstTimeBuyer: {
    firstTimeBuyer: {
      defaultValue: true,
      name: "firstTimeBuyer",
      serverTransform: booleanToYesNoString,
      validation: Yup.boolean()
        .transform(transformYesNoToBoolean)
        .required(defaultRequiredErrorMessage),
    },
  },
  occupancyType: {
    occupancyType: {
      defaultValue: "residence",
      label: "Property use",
      name: "occupancyType",
      validation: Yup.string().trim().required(defaultRequiredErrorMessage),
    },
  },
  propertyType: {
    propertyType: {
      defaultValue: "singleFamilyResidence",
      name: "propertyType",
      validation: Yup.string().trim().required(defaultRequiredErrorMessage),
    },
  },
  purchaseGeography: {
    purchaseGeography: {
      defaultValue: "",
      name: "purchaseGeography",
      placeholder: "Enter a location",
      validation: Yup.array()
        .of(
          Yup.object().shape({
            bbox: Yup.array().of(Yup.number()),
            center: Yup.array().of(Yup.number()),
            context: Yup.array().of(
              Yup.object().shape({
                text: Yup.string(),
                value: Yup.string(),
              })
            ),
            label: Yup.string(),
            placeType: Yup.string(),
            text: Yup.string(),
            value: Yup.string(),
          })
        )
        .required(),
    },
  },
  purchasePrice: {
    purchasePrice: {
      defaultValue: "",
      name: "purchasePrice",
      serverTransform: floatToDollarCentsString,
      validation: Yup.number()
        .transform(transformStringToNumber)
        .min(10000, "Minimum allowable home value is $10,000")
        .max(3000000, "Maximum allowable home value is $3,000,000")
        .required(defaultRequiredErrorMessage),
    },
  },
};
